import { Component, OnInit } from '@angular/core';
import { GlobalService } from 'src/app/helpers/global.service';
import { DefaultService } from 'src/app/service/default.service';

@Component({
	selector: 'app-footer',
	templateUrl: './footer.component.html',
	styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
	pages = null;
	constructor(
		public gs: GlobalService,
		public defaultService: DefaultService,
	) { }

	ngOnInit() {
		this.defaultService.item("GET", null, {moduleId: 'page'}).subscribe(data => {
			this.pages = data.data;
		});
	}

}

import { Action } from '@ngrx/store';
import { Post } from 'src/app/models/Post';

export enum ExploreTimelineTypes {
    FAILURE = '[EXPLORE_TIMELINE_FAILURE] failed',
    LIST_PARAMS = '[EXPLORE_TIMELINE_LIST_PARAMS] Explore List Params',
    LIST = '[EXPLORE_TIMELINE_LIST] Explore List',
    LIST_MORE = '[EXPLORE_TIMELINE_LIST_MORE] Explore List More',
    ADD = '[EXPLORE_TIMELINE_ADD] Explore Add',
    UPDATE = '[EXPLORE_TIMELINE_UPDATE] Explore Update',
    DELETE = '[EXPLORE_TIMELINE_UPDATE] Explore Detele',
}

export class ListParams implements Action {
    readonly type = ExploreTimelineTypes.LIST_PARAMS;
    constructor(public options: { params?: any, key?: string}) { }
} 

export class List implements Action {
    readonly type = ExploreTimelineTypes.LIST;
    constructor(public list: any) { }
}

export class More implements Action {
    readonly type = ExploreTimelineTypes.LIST_MORE;
    constructor(public more: any) { }
}

export class Add implements Action {
    readonly type = ExploreTimelineTypes.ADD;
    constructor(public item: Post) { }
}

export class Update implements Action {
    readonly type = ExploreTimelineTypes.UPDATE;
    constructor(public item: Post) { }
}

export class Delete implements Action {
    readonly type = ExploreTimelineTypes.DELETE;
    constructor(public item: Post) { }
}

export class failure implements Action {
    readonly type = ExploreTimelineTypes.FAILURE;
    constructor(public error: any) { }
}


export type ExploteTimelineAction = ListParams | List | More | Add | Update | Delete | failure;
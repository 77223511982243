import { Component, OnInit, Inject } from '@angular/core';
import { GlobalService } from 'src/app/helpers/global.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Photo } from 'src/app/models/Photo';
import { TimelineService } from 'src/app/modules/timeline/services/timeline.service';
import { ItemService } from 'src/app/service/item.service';

@Component({
	selector: 'app-image-popup',
	templateUrl: './image-popup.component.html',
	styleUrls: ['./image-popup.component.scss']
})
export class ImagePopupComponent implements OnInit {
	module = 'Photo';
	photoView: Photo = null;
	comments = [];
	nextIndex:number = null;
	prevIndex:number = null;
	page:number = 1;
	public isPopup:boolean = true;
	//photoGroup: Photo[] = null;
	constructor(
		private timelineService: TimelineService,
		private itemService: ItemService,
		public gs: GlobalService,
		private dialogRef: MatDialogRef<ImagePopupComponent>,
		@Inject(MAT_DIALOG_DATA) public data
	) {

	}

	ngOnInit() {
		//console.log(this.data);
		this.photoLoad(this.data.id);

		this.itemService.followCount$.subscribe(data => {
			if(data === 'UpdatePopupCommentCount') {
				const item = Object.assign({}, this.photoView, { commentCount: Number(this.photoView.commentCount === null ? 0 : this.photoView.commentCount) + 1 });
				this.photoView = item;
			}
		})
	}

	photoLoad(id) {
		this.photoView = null;
		this.itemService.media("GET", null, { photo_id: id }).subscribe((photo:any) => {
			this.photoView = photo.data;
			//console.log(this.photoView);
			this.setIndex(photo.data.id);
			this.itemService.commentTriger(this.photoView.id );
		}, (error: Response) => {
			console.log(error);
		});
	}


	setIndex(id) {
		let list = this.data.imgGroup;
		//console.log('list', list);
		let index = parseInt( list.findIndex(f => f.id === id) );
		let nextIndex = ( list.length > (index + 1)  ) ? (index + 1) : null;
		let prevIndex = (  0 === index ) ? null : ( index - 1 );
		this.nextIndex = ( ( nextIndex !== null ) && list[nextIndex].id) ? list[nextIndex].id : null;
		this.prevIndex = ( ( prevIndex !== null ) && list[prevIndex].id ) ? list[prevIndex].id : null;
	}

	swing(id) {
		this.photoLoad(id);
	}

	close() {
		this.dialogRef.close();
	}

	likePhoto(reaction) {
		const data = {
			"module": "Photo",
			"item_id": this.photoView.id,
			"reaction_id": reaction.id
		}
		this.timelineService.postReaction(data).subscribe(data => {
			if(this.photoView.isLike === true) {
				this.photoView = Object.assign({}, this.photoView, { isLike: false, currentReaction: data.reaction.title });
				this.photoView = Object.assign({}, this.photoView, { reactionCount: data.reactionCount });
			} else {
				this.photoView = Object.assign({}, this.photoView, { isLike: true, currentReaction: data.reaction.title });
				this.photoView = Object.assign({}, this.photoView, { reactionCount: data.reactionCount });
			}
		}, (error: Response) => {
			this.gs.handleErrors(error);
		})
	}

}

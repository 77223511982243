import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { DatePipe, AsyncPipe } from '@angular/common';
import { RouterModule } from '@angular/router';
// Main Module 
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatNativeDateModule } from '@angular/material/core';
import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MaterialModule } from './material-module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxCaptchaModule } from 'ngx-captcha';
import { AngularFireModule } from '@angular/fire';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { ServiceWorkerModule } from '@angular/service-worker';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NgScrollbarModule } from 'ngx-scrollbar';

// Custom Module
import { LaddaModule } from 'angular2-ladda';
import { AuthModule } from './auth/auth.module';
import { ViewModule } from './view/view.module';
import { environment } from 'src/environments/environment';

// Common Services
import { GlobalService } from './helpers/global.service';
import { httpInterceptorProviders } from './helpers/index';

// Routing
import { AppRoutingModule } from './app-routing.module';

// Components 
import { AppComponent } from './app.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { HomeComponent } from './components/home/home.component';
import { NotFoundComponent } from './components/not-found/not-found.component'; 
import { ContactComponent } from './components/contact/contact.component';
import { AboutComponent } from './components/about/about.component';
import { ContentPageComponent } from './components/content-page/content-page.component';
import { NotificationComponent } from './components/header/notification/notification.component';
import { FriendRequestComponent } from './components/header/friend-request/friend-request.component';
import { TruncateTextPipe } from './helpers/truncatetext-pipe';
import { FaqsComponent } from './components/faqs/faqs.component';
import { AskFaqComponent } from './components/faqs/ask-faq/ask-faq.component';
import { ComingSoonComponent } from './components/coming-soon/coming-soon.component';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { reducers, metaReducers } from './reducers';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { AuthEffects } from './store/effects/auth.effects';
import { ItemEffects } from './store/effects/item.effects';
import { DefaultEffects } from './store/effects/default.effects';
import { UserEffects } from './store/effects/user.effects';
import { NotificationsEffects } from './store/effects/notifications.effects';
import { TimelineEffects } from './store/effects/timeline.effects';
import { PageEffects } from './store/effects/page.effects';
import { GroupEffects } from './store/effects/group.effects';


@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    NotFoundComponent,
    ContactComponent,
    AboutComponent,
    HeaderComponent,
    FooterComponent,
    ContentPageComponent,
    NotificationComponent,
    FriendRequestComponent,
    TruncateTextPipe,
    FaqsComponent,
    AskFaqComponent,
    ComingSoonComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    MatNativeDateModule,
    MaterialModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    CarouselModule,
    RouterModule,
    AppRoutingModule,
    AngularFireDatabaseModule,
    AngularFireAuthModule,
    AngularFireMessagingModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    LaddaModule.forRoot({
      style: 'zoom-in',
      spinnerSize: 30
    }),
    InfiniteScrollModule,
    NgScrollbarModule,
    NgxCaptchaModule,
    AuthModule,
    ViewModule,
    //WidgetsModule,
    //ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production, scope: './', registrationStrategy: 'registerImmediately' }),
    StoreModule.forRoot(reducers, { metaReducers }), !environment.production ? StoreDevtoolsModule.instrument() : [],
    EffectsModule.forRoot([AuthEffects, ItemEffects, DefaultEffects, UserEffects, NotificationsEffects, TimelineEffects, PageEffects, GroupEffects]),
  ],
  providers: [DatePipe, AsyncPipe, GlobalService, httpInterceptorProviders],
  entryComponents: [AskFaqComponent],
  bootstrap: [AppComponent]
})
export class AppModule { 
  constructor() {
  }
}  

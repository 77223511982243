import { Action } from '@ngrx/store';
import { Post } from 'src/app/models/Post';

export enum UserTimelineTypes {
    FAILURE = '[USER_FAILURE] failed',
    LIST_PARAMS = '[USER_LIST_PARAMS] USER List Params',
    LIST = '[USER_LIST] USER List',
    LIST_MORE = '[USER_LIST_MORE] USER List More',
    ADD = '[USER_ADD] USER Add',
    UPDATE = '[USER_UPDATE] USER Update',
    VIEW = '[USER_VIEW] USER View',
    DELETE = '[USER_UPDATE] USER Detele',
}

export class ListParams implements Action {
    readonly type = UserTimelineTypes.LIST_PARAMS;
    constructor(public options: { params?: any, key?: string}) { }
} 

export class List implements Action {
    readonly type = UserTimelineTypes.LIST;
    constructor(public list: any) { }
}

export class More implements Action {
    readonly type = UserTimelineTypes.LIST_MORE;
    constructor(public more: any) { }
}

export class Add implements Action {
    readonly type = UserTimelineTypes.ADD;
    constructor(public item: Post) { }
}

export class Update implements Action {
    readonly type = UserTimelineTypes.UPDATE;
    constructor(public item: Post) { }
}

export class View implements Action {
    readonly type = UserTimelineTypes.VIEW;
    constructor(public item: Post) { }
}

export class Delete implements Action {
    readonly type = UserTimelineTypes.DELETE;
    constructor(public item: Post) { }
}

export class failure implements Action {
    readonly type = UserTimelineTypes.FAILURE;
    constructor(public error: any) { }
}


export type UserTimelineAction = ListParams | List | More | Add | Update | View | Delete | failure;
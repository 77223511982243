import { Action, createReducer, on } from '@ngrx/store';
import { User } from 'src/app/models/User';
import { UserAction } from '../action/user.actions';
import { UserActionTypes } from '../action/user.actions';

export const featureKey = 'user';

export interface State {
	user: User,
	suggestions: User[],
	loading: boolean,
	error: any
}

export const initialState: State = {
	user: null,
	suggestions: null,
	loading: false,
	error: ''
};

const removeDuplicates = (originalArray, prop) => {
	let newArray = [];
	let lookupObject  = {};
	for(var i in originalArray) {
	   lookupObject[originalArray[i][prop]] = originalArray[i];
	}
	for(i in lookupObject) {
		newArray.push(lookupObject[i]);
	}
	return newArray.reverse();
}

export function reducer(state = initialState, action: UserAction): State {

	switch (action.type) {
		case UserActionTypes.PROFILE_SUGGESTIONS_PARAMS:
			return {
				...state,
				loading: true,
				error: ''
			}
		case UserActionTypes.PROFILE_SUGGESTIONS:
			return {
				...state,
				suggestions: action.suggestions,
				loading: false
			}
		case UserActionTypes.PROFILE_SUGGESTION_MORE:
				return {
					...state,
					suggestions: removeDuplicates(state.suggestions.concat(action.more), 'id'),
					loading: false
				}
		case UserActionTypes.PROFILE_SUGGESTION_UPDATE:
			const suggestionI = state.suggestions.findIndex(f => f.id === action.item.id);
			return {
				...state,
				suggestions: [...state.suggestions.slice(0, suggestionI),
				action.item,
				...state.suggestions.slice(suggestionI + 1)
				],
			}
		case UserActionTypes.PROFILE_FAILURE:
			return {
				...state,
				loading: false,
				error: action.error
			}
		default:
			return state;
	}
};
import { Component, OnInit, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/auth/services/auth.service';
import { Router, NavigationExtras, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { User } from 'src/app/models/User';
import { GlobalService } from 'src/app/helpers/global.service';
import { debounceTime } from 'rxjs/operators';
import { AngularFireDatabase } from '@angular/fire/database';
import { Store } from '@ngrx/store';
import { AuthSelector } from 'src/app/store/selector';
import { AuthActions } from 'src/app/store/action';

@Component({
	selector: 'app-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
	@ViewChild("name", {static: false}) nameField: ElementRef;
	form: FormGroup;
	submitted = false;
	errors = [];
	msgCount: number = 0;
	userObservable: Observable<User> = this.store.select(AuthSelector.User);
	user: User = null;
	isLoggedIn$: Observable<boolean>;
	searchValue = false;
	statusClass: boolean = false;
	constructor(
		private route: ActivatedRoute,
		private authService: AuthService,
		private router: Router,
		private fb: FormBuilder,
		public gs: GlobalService,
		private firestore: AngularFireDatabase,
		private renderer: Renderer2,
		private store: Store<{}>,
	) {

	}

	get f() { return this.form.controls; }


	ngOnInit() {

		this.isLoggedIn$ = this.authService.authenticationState;

		this.userObservable.subscribe(data => {
			this.user = data;
			this.msgCount = this.user?.userProfile?.messages;
		});

		this.form = this.fb.group({
			search: new FormControl('', [Validators.required]),
		});

		this.isLoggedIn$.pipe(debounceTime(500)).subscribe((data: any) => {
			if(data === true) {
				var msg = this;
				var userRef = this.firestore.database.ref('users/' + this.user.id);
				userRef.on('value', function(snapshot) {
					//msg.msgCounter();
				});
			}
		});

		this.route.queryParams.subscribe(params => {
			this.form.patchValue({
				search: params["search"]
			});
			if(!params["search"]) {
				this.statusClass = false;
			}
		});
		
	}

	mainSearch(form) {
		if (!form.valid) {
			this.nameField.nativeElement.focus();
			this.renderer.addClass(this.nameField.nativeElement, 'focus'); 
			this.statusClass = true;
			return;
		} else {
			this.renderer.removeClass(this.nameField.nativeElement, 'focus');
			this.statusClass = false;
		}
		let navigationExtras: NavigationExtras = {
			queryParams: {
				"search": this.form.value.search
			}
		};
		this.router.navigate(['/search'], navigationExtras);
	}

	logout() {
		this.authService.logout();
		this.router.navigate(['/']);
	}

	searchShow() {
		this.statusClass = true;
	}

	msgAlert() {
		alert('Under Construction. Thanks for your Patience.');
	}

	updateCount() {
		let newItem = this.user;
        let user = Object.assign({}, newItem, { userProfile: { messages: 0, notifications: newItem.userProfile.notifications } });
		this.store.dispatch(new AuthActions.loginSuccess({ user: user }));
	}

}

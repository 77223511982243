import { Post } from 'src/app/models/Post';
import { CommunityTimelineTypes } from '../action/community-timeline.actions';
import { EventTimelineTypes } from '../action/event-timeline.actions';
import { ExploreTimelineTypes } from '../action/explore-timeline.actions';
import { FeedTimelineTypes } from '../action/feed-timeline.actions'; 
import { GroupTimelineTypes } from '../action/group-timeline.actions';
import { HiddenPostTimelineTypes } from '../action/hiddenpost-timeline.actions';
import { HideAllPostTimelineTypes } from '../action/hideallpost-timeline.actions';
import { HidedMyPostTimelineTypes } from '../action/hidedmypost-timeline.actions';
import { PageTimelineTypes } from '../action/page-timeline.actions';
import { SchedulePostTimelineTypes } from '../action/schedule-timeline.actions';
import { UserTimelineTypes } from '../action/user-timeline.actions';

export const featureKey = 'timeline';

export interface State {
    feedlist: Post[],
    explore: Post[],
    user_post: Post[],
    group_post: Post[],
    page_post: Post[],
    event_post: Post[],
    community_post: Post[],
    hidden_post: Post[],
    hidedmy_post: Post[],
    hideall_post: Post[],
    schedule_post: Post[],
    view: Post,
	loading: boolean,
	error: any
}

export const initialState: State = {
    feedlist: null,
    explore: null,
    user_post: null,
    group_post: null,
    page_post: null,
    event_post: null,
    community_post: null,
    hidden_post: null,
    hidedmy_post: null,
    hideall_post: null,
    schedule_post: null,
    view: null,
	loading: false,
	error: ''
};

export function reducer(state = initialState, action: any ): State {
    //console.log('action.feedlist', action);
	switch (action.type) {
        case FeedTimelineTypes.FEED_LIST_PARAMS:
        case ExploreTimelineTypes.LIST_PARAMS:
            return {
                ...state,
                loading: true,
                error: ''
            }
        // News Feed Start ***** ????????? ****
        case FeedTimelineTypes.FEED_LIST:
            
            return {
                ...state,
                feedlist: action.feedlist,
                loading: false,
            }
        case FeedTimelineTypes.FEED_LIST_MORE:
            return {
                ...state,
                feedlist: [...state.feedlist?.concat(action.feedmore)]
            }
		case FeedTimelineTypes.ADD:
            let feedlistAdd = (state.feedlist === null) ? null : [action.item, ...state.feedlist];
			return {
				...state,
				feedlist: feedlistAdd,
			}
        case FeedTimelineTypes.UPDATE:
            const index = state.feedlist.findIndex(f => f.id === action.item.id);
            const object = action.item;
            return {
                ...state,
                feedlist: [...state.feedlist.slice(0, index),
                object,
                ...state.feedlist.slice(index + 1)
                ],
            }
        case FeedTimelineTypes.DELETE:
            return {
                ...state,
                feedlist: [...state.feedlist.filter( val => val.id !== action.item.id )]
            }

        // Explore Start ***** ????????? ****
        case ExploreTimelineTypes.LIST:
            return {
                ...state,
                explore: action.list,
                loading: false,
            }
        case ExploreTimelineTypes.LIST_MORE:
            return {
                ...state,
                explore: [...state.explore.concat(action.more)]
            }
        case ExploreTimelineTypes.ADD:
            let exploreAdd = (state.explore === null) ? null : [action.item, ...state.explore];
            return {
                ...state,
                explore: exploreAdd,
            }
        case ExploreTimelineTypes.UPDATE:
            const exploteI = state.explore.findIndex(f => f.id === action.item.id);
            const exploreO = action.item;
            return {
                ...state,
                explore: [...state.explore.slice(0, exploteI),
                exploreO,
                ...state.explore.slice(exploteI + 1)
                ],
            }
        case ExploreTimelineTypes.DELETE:
            return {
                ...state,
                explore: [...state.explore.filter( val => val.id !== action.item.id )]
            }

        // User Start ***** ????????? ****
        case UserTimelineTypes.LIST:
            return {
                ...state,
                user_post: action.list,
                loading: false,
            }
        case UserTimelineTypes.LIST_MORE:
            return {
                ...state,
                user_post: [...state.user_post.concat(action.more)]
            }
        case UserTimelineTypes.ADD:
            let user_postAdd = (state.user_post === null) ? null : [action.item, ...state.user_post];
            return {
                ...state,
                user_post: user_postAdd,
            }
        case UserTimelineTypes.UPDATE:
            const user_postI = state.user_post.findIndex(f => f.id === action.item.id);
            const user_postO = action.item;
            return {
                ...state,
                user_post: [...state.user_post.slice(0, user_postI),
                user_postO,
                ...state.user_post.slice(user_postI + 1)
                ],
            }
        case UserTimelineTypes.DELETE:
            return {
                ...state,
                user_post: [...state.user_post.filter( val => val.id !== action.item.id )]
            }

        // Group Start ***** ????????? ****
        case GroupTimelineTypes.LIST:
            return {
                ...state,
                group_post: action.list,
                loading: false,
            }
        case GroupTimelineTypes.LIST_MORE:
            return {
                ...state,
                group_post: [...state.group_post.concat(action.more)]
            }
        case GroupTimelineTypes.ADD:
            let group_postAdd = (state.group_post === null) ? null : [action.item, ...state.group_post];
            return {
                ...state,
                group_post: group_postAdd,
            }
        case GroupTimelineTypes.UPDATE:
            const group_postI = state.group_post.findIndex(f => f.id === action.item.id);
            const group_postO = action.item;
            return {
                ...state,
                group_post: [...state.group_post.slice(0, group_postI),
                group_postO,
                ...state.group_post.slice(group_postI + 1)
                ],
            }
        case GroupTimelineTypes.DELETE:
            return {
                ...state,
                group_post: [...state.group_post.filter( val => val.id !== action.item.id )]
            }
        case GroupTimelineTypes.DESTROY:
            return {
                ...state,
                group_post: null
            }

        // Page Start ***** ????????? ****
        case PageTimelineTypes.LIST:
            return {
                ...state,
                page_post: action.list,
                loading: false,
            }
        case PageTimelineTypes.LIST_MORE:
            return {
                ...state,
                page_post: [...state.page_post.concat(action.more)]
            }
        case PageTimelineTypes.ADD:
            let page_postAdd = (state.page_post === null) ? null : [action.item, ...state.page_post];
            return {
                ...state,
                page_post: page_postAdd,
            }
        case PageTimelineTypes.UPDATE:
            const page_postI = state.page_post.findIndex(f => f.id === action.item.id);
            const page_postO = action.item;
            return {
                ...state,
                page_post: [...state.page_post.slice(0, page_postI),
                page_postO,
                ...state.page_post.slice(page_postI + 1)
                ],
            }
        case PageTimelineTypes.DELETE:
            return {
                ...state,
                page_post: [...state.page_post.filter( val => val.id !== action.item.id )]
            }

        // Event Start ***** ????????? ****
        case EventTimelineTypes.LIST:
            return {
                ...state,
                event_post: action.list,
                loading: false,
            }
        case EventTimelineTypes.LIST_MORE:
            return {
                ...state,
                event_post: [...state.event_post.concat(action.more)]
            }
        case EventTimelineTypes.ADD:
            let event_postAdd = (state.event_post === null) ? null : [action.item, ...state.event_post];
            return {
                ...state,
                event_post: event_postAdd,
            }
        case EventTimelineTypes.UPDATE:
            const event_postI = state.event_post.findIndex(f => f.id === action.item.id);
            const event_postO = action.item;
            return {
                ...state,
                event_post: [...state.event_post.slice(0, event_postI),
                event_postO,
                ...state.event_post.slice(event_postI + 1)
                ],
            }
        case EventTimelineTypes.DELETE:
            return {
                ...state,
                event_post: [...state.event_post.filter( val => val.id !== action.item.id )]
            }

        // Community Start ***** ????????? ****
        case CommunityTimelineTypes.LIST:
            return {
                ...state,
                community_post: action.list,
                loading: false,
            }
        case CommunityTimelineTypes.LIST_MORE:
            return {
                ...state,
                community_post: [...state.community_post.concat(action.more)]
            }
        case CommunityTimelineTypes.ADD:
            let community_postAdd = (state.community_post === null) ? null : [action.item, ...state.community_post];
            return {
                ...state,
                community_post: community_postAdd,
            }
        case CommunityTimelineTypes.UPDATE:
            const community_postI = state.community_post.findIndex(f => f.id === action.item.id);
            const community_postO = action.item;
            return {
                ...state,
                community_post: [...state.community_post.slice(0, community_postI),
                community_postO,
                ...state.community_post.slice(community_postI + 1)
                ],
            }
        case CommunityTimelineTypes.DELETE:
            return {
                ...state,
                community_post: [...state.community_post.filter( val => val.id !== action.item.id )]
            }

        // HiddenPost Start ***** ????????? ****
        case HiddenPostTimelineTypes.LIST:
            return {
                ...state,
                hidden_post: action.list,
                loading: false,
            }
        case HiddenPostTimelineTypes.LIST_MORE:
            return {
                ...state,
                hidden_post: [...state.hidden_post.concat(action.more)]
            }
        case HiddenPostTimelineTypes.ADD:
            let hidden_postAdd = (state.hidden_post === null) ? null : [action.item, ...state.hidden_post];
            return {
                ...state,
                hidden_post: hidden_postAdd,
            }
        case HiddenPostTimelineTypes.UPDATE:
            const hidden_postI = state.hidden_post.findIndex(f => f.id === action.item.id);
            const hidden_postO = action.item;
            return {
                ...state,
                hidden_post: [...state.hidden_post.slice(0, hidden_postI),
                hidden_postO,
                ...state.hidden_post.slice(hidden_postI + 1)
                ],
            }
        case HiddenPostTimelineTypes.DELETE:
            return {
                ...state,
                hidden_post: [...state.hidden_post.filter( val => val.id !== action.item.id )]
            }

        // HidedMyPost Start ***** ????????? ****
        case HidedMyPostTimelineTypes.LIST:
            return {
                ...state,
                hidedmy_post: action.list,
                loading: false,
            }
        case HidedMyPostTimelineTypes.LIST_MORE:
            return {
                ...state,
                hidedmy_post: [...state.hidedmy_post.concat(action.more)]
            }
        case HidedMyPostTimelineTypes.ADD:
            let hidedmy_postAdd = (state.hidedmy_post === null) ? null : [action.item, ...state.hidedmy_post];
            return {
                ...state,
                hidedmy_post: hidedmy_postAdd,
            }
        case HidedMyPostTimelineTypes.UPDATE:
            const hidedmy_postI = state.hidedmy_post.findIndex(f => f.id === action.item.id);
            const hidedmy_postO = action.item;
            return {
                ...state,
                hidedmy_post: [...state.hidedmy_post.slice(0, hidedmy_postI),
                hidedmy_postO,
                ...state.hidedmy_post.slice(hidedmy_postI + 1)
                ],
            }
        case HidedMyPostTimelineTypes.DELETE:
            return {
                ...state,
                hidedmy_post: [...state.hidedmy_post.filter( val => val.id !== action.item.id )]
            }

        // HideAllPost Start ***** ????????? ****
        case HideAllPostTimelineTypes.LIST:
            return {
                ...state,
                hideall_post: action.list,
                loading: false,
            }
        case HideAllPostTimelineTypes.LIST_MORE:
            return {
                ...state,
                hideall_post: [...state.hideall_post.concat(action.more)]
            }
        case HideAllPostTimelineTypes.ADD:
            let hideall_postAdd = (state.hideall_post === null) ? null : [action.item, ...state.hideall_post];
            return {
                ...state,
                hideall_post: hideall_postAdd,
            }
        case HideAllPostTimelineTypes.UPDATE:
            const hideall_postI = state.hideall_post.findIndex(f => f.id === action.item.id);
            const hideall_postO = action.item;
            return {
                ...state,
                hideall_post: [...state.hideall_post.slice(0, hideall_postI),
                hideall_postO,
                ...state.hideall_post.slice(hideall_postI + 1)
                ],
            }
        case HideAllPostTimelineTypes.DELETE:
            return {
                ...state,
                hideall_post: [...state.hideall_post.filter( val => val.id !== action.item.id )]
            }

        // SchedulePost Start ***** ????????? ****
        case SchedulePostTimelineTypes.LIST:
            return {
                ...state,
                schedule_post: action.list,
                loading: false,
            }
        case SchedulePostTimelineTypes.LIST_MORE:
            return {
                ...state,
                schedule_post: [...state.schedule_post.concat(action.more)]
            }
        case SchedulePostTimelineTypes.ADD:
            let schedule_postAdd = (state.schedule_post === null) ? null : [action.item, ...state.schedule_post];
            return {
                ...state,
                schedule_post: schedule_postAdd,
            }
        case SchedulePostTimelineTypes.UPDATE:
            const schedule_postI = state.schedule_post.findIndex(f => f.id === action.item.id);
            const schedule_postO = action.item;
            return {
                ...state,
                schedule_post: [...state.schedule_post.slice(0, schedule_postI),
                schedule_postO,
                ...state.schedule_post.slice(schedule_postI + 1)
                ],
            }
        case SchedulePostTimelineTypes.DELETE:
            return {
                ...state,
                schedule_post: [...state.schedule_post.filter( val => val.id !== action.item.id )]
            }
        
        case UserTimelineTypes.VIEW:
            return {
                ...state,
                view: action.item
            }

		case FeedTimelineTypes.FEED_FAILURE:
			return {
				...state,
				loading: false,
				error: ''
			}
		default:
			return state;
	}
};
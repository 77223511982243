import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { GlobalService } from 'src/app/helpers/global.service';
import { DefaultService } from 'src/app/service/default.service';

@Component({
	selector: 'app-send-message',
	templateUrl: './send-message.component.html',
	styleUrls: ['./send-message.component.scss']
})
export class SendMessageComponent implements OnInit {

	form: FormGroup;
	submitted = false;

	constructor(
		public gs: GlobalService,
		private defaultSercive: DefaultService,
		private dialogRef: MatDialogRef<SendMessageComponent>,
		@Inject(MAT_DIALOG_DATA) public data,
		private router: Router
	) { }

	ngOnInit() {
		this.form = this.gs.formBuilder.group({
			msg: new FormControl('', [Validators.required]),
		});
	}

	submit(form) {
		this.submitted = true;
		if (!form.valid) {
			this.submitted = false;
			return false;
		}
		const formData = {
			text: form.value.msg,
			recipients: [this.data.id]
		}
		this.defaultSercive.message("POST", { UserMessageForm: formData }).subscribe((data: any) => {
			this.router.navigate([`messanger`], { queryParams: { message_id: data.data.message_id} });
			this.closeDialog();
		}, (error: Response) => {
			this.gs.handleErrors(error);
			this.submitted = false;
		});
		
	}

	closeDialog() {
		this.dialogRef.close()
	}

}

<!-- <iframe width="100%" height="100%" frameBorder="0" [src]="urlSafe"></iframe>
{{ postVideos | json }} -->

<div id="video_container">
    <video id="video-player" class="video-js vjs-default-skin vjs-big-play-centered" controls preload="auto">
        <p class="vjs-no-js">
            To view this video please enable JavaScript, and consider upgrading
            to a web browser that <a href="http://videojs.com/html5-video-support/" target="_blank">supports
                HTML5 video</a>
        </p>
    </video>
</div>


<!-- WebRTC Player -->
<video id="remoteVideo" controls playsinline></video>
<div id="networkWarning">Your connection isn't fast enough to play this stream!</div>
<img id="play_button" src="https://media2.chatdit.com:5443/WebRTCApp/images/play.png" (click)="playWebRTCVideo()"
    style="position: absolute; top: 30px; left: 30px; display: none;" />
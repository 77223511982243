import { NotificationsAction, NotificationsActionTypes } from '../action/notifications.actions';
import { Notifications } from 'src/app/models/Notifications';
import { Pagination } from 'src/app/models/Pagination';

export const featureKey = 'notifications';

export interface State {
	notifications: Notifications[],
	pagination: Pagination,
	loading: boolean,
	loadingMore: boolean,
	error: any
}

export const initialState: State = {
	notifications: null,
	pagination: null,
	loading: false,
	loadingMore: false,
	error: ''
};


export function reducer(state = initialState, action: NotificationsAction): State {
	switch (action.type) {
		case NotificationsActionTypes.LIST_INITIALIZED:
			return {
				...state,
				loading: true,
				loadingMore: true,
				error: '',
			}
		case NotificationsActionTypes.LIST:
			return {
				...state,
				loading: false,
				loadingMore: false,
				notifications: action.payload.items,
				pagination: action.payload.pagination
			}
		case NotificationsActionTypes.MORE:
			return {
				...state,
				loading: false,
				loadingMore: false,
				notifications: [...state.notifications, action.list],
			}
		case NotificationsActionTypes.ADD:
			return {
				...state,
				notifications: [...state.notifications, action.item],
			}
		case NotificationsActionTypes.UPDATE:
			const index = state.notifications.findIndex(f => f.message_id === action.item.message_id);
			const object = state.notifications;
            object[index].status = 'Read';
            object[index].seen = true;
			return {
				...state,
				notifications: [...state.notifications.slice(0, index),
				object[index],
				...state.notifications.slice(index + 1)
				],
			}
		case NotificationsActionTypes.DELETE:
			return {
				...state,
				notifications: [...state.notifications.filter( val => val.message_id !== action.item.message_id )]
			}
		case NotificationsActionTypes.NOTIFICATIONS_FAILURE:
			return {
				...state,
				loading: false,
				error: action.error,
			}
		default:
			return state;
	}
}
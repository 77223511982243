import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { User } from 'src/app/models/User';
import { AuthSelector } from 'src/app/store/selector';

@Component({
	selector: 'app-user-profile',
	templateUrl: './user-profile.component.html',
	styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements OnInit {

    userObservable: Observable<User> = this.store.select(AuthSelector.User);
	user: User = null;

	constructor(
		private store: Store<{}>
	) { }

	ngOnInit() {
		this.userObservable.subscribe(data => this.user = data);
	}

}

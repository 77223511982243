import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { AuthService } from 'src/app/auth/services/auth.service';
import { Router } from '@angular/router';
import { GlobalService } from 'src/app/helpers/global.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-login-popup',
  templateUrl: './login-popup.component.html',
  styleUrls: ['./login-popup.component.scss']
})
export class LoginPopupComponent implements OnInit {

  hide = false;
  form: FormGroup;
  submitted = false;
  errors:any = [];

  isCollapsed = true; 
  
  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private router: Router,
    public gs: GlobalService,
    private dialogRef: MatDialogRef<LoginPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data
  ) { }

  get f() { return this.form.controls; }

  ngOnInit() {
    this.creteLoginForm();
  }

  onLogin(form) {
    this.submitted = true;
    if ( !form.valid ) { 
      this.submitted = false;
      return false; 
    }
    this.authService.login(form.value)
      .subscribe((auth: any) => {
        this.submitted = false;
        this.gs.alert('Login Successful');
        //window.location.reload;
        this.router.navigate(['/timeline']);
      }, (error: Response) => {
        this.submitted = false;
        this.errors = error;
        this.gs.handleErrors(error);
      })
  }

  creteLoginForm() {
    this.form = this.fb.group({
      username: new FormControl('', [Validators.required]),
      password: new FormControl('', Validators.required)
    })
  } 

  closeDialog() {
    this.dialogRef.close();
  }

}

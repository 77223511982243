import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { GlobalService } from 'src/app/helpers/global.service';
import { UserService } from 'src/app/service/user.service';
import { AuthActions, UserAction } from '../action';

@Injectable()
export class UserEffects {
    constructor(
        private actions$: Actions,
        private userService: UserService,
        private gs: GlobalService
    ) { }

    profile$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UserAction.UserActionTypes.PROFILE),
            mergeMap((options: UserAction.Profile) =>
                this.userService.profile(options.options.method, options.options.params, options.options.params2).pipe(
                    map((item: any) => new AuthActions.loginSuccess({ user: item.data })),
                    catchError((error) => {
                        this.gs.handleErrors(error);
                        return [new UserAction.failure(error)]
                    })
                )
            )
        )
    );

    suggestion$ = createEffect(() => this.actions$.pipe(
        ofType(UserAction.UserActionTypes.PROFILE_SUGGESTIONS_PARAMS),
        mergeMap((options: UserAction.SuggestionParams) =>
            this.userService.friends("GET", null, options.options.params).pipe(map((item: any) => {
                let key = options.options.key;
                switch (key) {
                    case 'more':
                        return new UserAction.SuggestionMore(item ? item.items : []);
                        break;
                    default:
                        return new UserAction.Suggestions(item ? item.items : []);
                        break;
                }
            }),
                catchError((error) => {
                    this.gs.handleErrors(error);
                    return [new UserAction.failure(error)]
                })
            )
        )
    ));

    PeopleYouMayKnow$ = createEffect(() => this.actions$.pipe(
        ofType(UserAction.UserActionTypes.PEOPLE_YOU_MAY_KNOW_PARAMS),
        mergeMap((options: UserAction.PeopleYouMayKnowParams) =>
            this.userService.friends("GET", null, options.options.params).pipe(map((item: any) => {
                let key = options.options.key;
                switch (key) {
                    case 'remove':
                        return new UserAction.PeopleYouMayKnow(item ? item : []);
                        break;
                    default:
                        return new UserAction.PeopleYouMayKnow(item ? item : []);
                        break;
                }
            }),
            catchError((error) => {
                this.gs.handleErrors(error);
                return [new UserAction.failure(error)]
            })))
    ));

}
<main class="all-content user-layout">
	<div class="container">
		<div class="row">
			<div class="col-12">
				<app-user-banner></app-user-banner>
			</div>
		</div>
		<div class="row">
			<div class="col-lg-3 col-12">
				<app-user-profile-absolute></app-user-profile-absolute>
				<app-user-setting *ngIf="userSetting" class="desktop-menu"></app-user-setting>
				<app-friend-suggestion *ngIf="uFrndSugge"></app-friend-suggestion>
			</div>

			<div class="col-lg-9 col-12">
				<app-timeline-menu></app-timeline-menu>
				<app-user-setting *ngIf="userSetting" class="mobile-menu"></app-user-setting>
				<!-- <div *ngIf="showLoadingIndicator">Loading......</div> -->
				<router-outlet></router-outlet>
			</div>
		</div>
	</div>
</main>


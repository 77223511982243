import { Component, OnInit, Input } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { GlobalService } from 'src/app/helpers/global.service';
import { ICmsItem } from 'src/app/models';
import { DefaultService } from 'src/app/service/default.service';

@Component({
	selector: 'app-feature-news',
	templateUrl: './feature-news.component.html',
	styleUrls: ['./feature-news.component.scss']
})
export class FeatureNewsComponent implements OnInit {

	requestLoading = false;
	@Input() public widgetClass: any = null;
	items: ICmsItem[] | any = null;

	constructor(
		private gs: GlobalService,
		private defaultService: DefaultService
	) { }

	ngOnInit() {
		const params = {
			moduleId: 'news',
			news_featured: 1
		}
		this.defaultService.item("GET", null, params).subscribe((response: any) => {
			const data = this.gs.apiResponce(response);
			this.items = data.data ? data.data : [];
			this.requestLoading = true;
		}, (error: Response) => {
			this.gs.handleErrors(error);
		});
	}

	featureOptions: OwlOptions = {
		loop: true,
		touchDrag: false,
		pullDrag: false,
		dots: false,
		nav: true,
		margin: 20,
		navSpeed: 700,
		autoplay: true,
		navText: ['<i class="fas fa-chevron-left"></i>', '<i class="fas fa-chevron-right"></i>'],
		responsive: {
			0: {
				items: 1,
				mouseDrag: true,
			},
			480: {
				items: 2,
				mouseDrag: true,
			},
			778: {
				items: 3,
				mouseDrag: false,
			},
			992: {
				items: 4,
				mouseDrag: false,
			}
		}
	}

}

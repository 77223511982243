import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { CommonService } from 'src/app/service/common.service';
import { GlobalService } from 'src/app/helpers/global.service';
import { Title } from '@angular/platform-browser';
import { AuthActions } from 'src/app/store/action';
import { Store } from '@ngrx/store';

@Component({
	selector: 'app-email-verify',
	templateUrl: './email-verify.component.html',
	styleUrls: ['./email-verify.component.scss']
})
export class EmailVerifyComponent implements OnInit {

	submitted = false;
	title = 'Email Verification';
	hide = false;

	error = {};

	token = null;

	reset: FormGroup;

	errors = [];


	constructor(
		private siteService: CommonService,
		public gs: GlobalService,
		private titleService: Title,
		private route: ActivatedRoute,
		private store: Store<{}>
	) { }

	ngOnInit() {
		this.titleService.setTitle(this.gs.mainTitle + ' | ' + this.title);

		this.route.queryParams.subscribe(params => {
			this.siteService.emailVerification(params.token).subscribe((data: any) => {
				if (data.token) {
					localStorage.setItem('token', data.token);
					this.store.dispatch(new AuthActions.loginSuccess({ user: data.data }));
					window.location.href = '/user/setting';
					this.gs.alert('Success!.');
				} else {
					this.gs.alert(data.message, 'bg-danger');
				}
			}, (error: Response) => {
				this.gs.handleErrors(error);
			});
		});
	}
}

import { Component, OnInit } from '@angular/core';
import { Event, ActivatedRoute, Router, NavigationStart, NavigationEnd, NavigationCancel, NavigationError } from '@angular/router';

@Component({
	selector: 'app-user-layout',
	templateUrl: './user-layout.component.html',
	styleUrls: ['./user-layout.component.scss']
})
export class UserLayoutComponent implements OnInit {
	userSetting = false;
	uFrndSugge = false;
	uFrndUrl = ['/user/friends'];
	urlList = ['/user/setting', '/user/setting/privacy', '/user/setting/newsfeed-tagging', '/user/setting/notification', '/user/setting/block-setting', '/user/setting/theme', '/user/setting/change-password', '/user/setting/deactivate-account'];
	showLoadingIndicator = true;

	constructor(
		public router: Router,
		public route: ActivatedRoute,
	) {

		this.uFrndSugge = this.uFrndUrl.includes(this.router.url);
		this.userSetting = this.urlList.includes(this.router.url);

		this.router.events.subscribe((routerEvent: Event) => {
			if (routerEvent instanceof NavigationStart) {
				this.showLoadingIndicator = true;
			}
			if (routerEvent instanceof NavigationEnd || routerEvent instanceof NavigationCancel || routerEvent instanceof NavigationError) {
				this.showLoadingIndicator = false;
			}
		})
	}

	ngOnInit() {
		
	}


}

import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserService } from 'src/app/modules/user/services/user.service';
import { GlobalService } from 'src/app/helpers/global.service';
import { debounceTime } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
	selector: 'app-privacy-specific',
	templateUrl: './privacy-specific.component.html',
	styleUrls: ['./privacy-specific.component.scss']
})
export class PrivacySpecificComponent implements OnInit {
	indeterminate = false;
	labelPosition = 'before';
	searchText = '';

	urlSlug = '';
	loading = false;
	per_page = 7;
	page: number = 1;
	requestLoading = false;
	friends = [];
	modelChanged: Subject<string> = new Subject<string>();
	selectFriend = [];
	constructor(
		private userService: UserService,
		public gs: GlobalService,
		private dialogRef: MatDialogRef<PrivacySpecificComponent>, @Inject(MAT_DIALOG_DATA) public data
	) { }

	ngOnInit() {
		this.loadFriend(this.page, null);

		this.selectFriend = this.data.friend;

		this.modelChanged.pipe(debounceTime(500)).subscribe((data: any) => {
			this.page = 1;
			this.userService.setPage(this.page, this.per_page).friendList(this.urlSlug, data).subscribe((data: any) => {
				this.friends = [];
				data.forEach(frnd => {
					let customObject = {
						id: frnd.id,
						name: frnd.name,
						avatar: frnd.avatar,
						checked: (this.selectFriend.filter(x => x === frnd.id).length === 1) ? true : false,
						mutual_friends: frnd.mutual_friends_count,
					}
					this.friends.push(customObject);
				})
			}, (error: Response) => {
				this.gs.handleErrors(error);
			});
		});
			
	}

	submit() {
		this.dialogRef.close(this.selectFriend);
	}

	closeDialog() {
		this.dialogRef.close()
	}

	clearSearch() {
		this.searchText = '';
		this.page = 1;
		this.loadFriend(this.page, '');
	}

	loadFriend(page, search) {
		this.loading = true;
		this.userService.setPage(page, this.per_page).friendList(this.urlSlug, search).subscribe((data: any) => {
			data.forEach(frnd => {
				let customObject = {
					id: frnd.id,
					name: frnd.name,
					avatar: frnd.avatar,
					checked: (this.selectFriend.filter(x => x === frnd.id).length === 1) ? true : false,
					mutual_friends: frnd.mutual_friends_count,
				}
				const index = this.friends.findIndex(f => f.id == frnd.id);
				if (index !== -1) {
					this.friends[index] = customObject;
				} else {
					this.friends.push(customObject);
				}
			});
			this.requestLoading = true;
			this.loading = false;
		}, (error: Response) => {
			this.gs.handleErrors(error);
		});
	}

	onScroll(event) {
		//console.log('onDown', event);
		this.page = this.page += 1;
		this.loadFriend(this.page, this.searchText);
	}

	onUp(event) {
		//console.log('onUp', event);
	}

	dataChanged(event: string) {
		this.modelChanged.next(event);
	}

	onChange(event, item) {
		if(event.checked === true) {
			this.selectFriend.push(item.id);
		} else {
			this.selectFriend.splice(this.selectFriend.indexOf(item), 1);
		}
	}

}

import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Emoji } from '@ctrl/ngx-emoji-mart/ngx-emoji';
import { GlobalService } from 'src/app/helpers/global.service';
import { ItemService } from 'src/app/service/item.service';
import * as io from 'socket.io-client';
import { environment } from 'src/environments/environment';

@Component({
	selector: 'app-broadcast-message',
	templateUrl: './broadcast-message.component.html',
	styleUrls: ['./broadcast-message.component.scss']
})
export class BroadcastMessageComponent implements OnInit, OnDestroy {
	@ViewChild('posttext', { static: false }) input: ElementRef<HTMLInputElement>;
	@Input() post_id: any = null;
	show: boolean = false;
	opened: boolean = false;
	form: FormGroup;
	submitted = false;
	loading = false;

	chatList: any[] = [];
	chatLoading: boolean = false;
	chatRequestLoading: boolean = false;
	chatPage = 1;

	private socket: any;

	get f() { return this.form.controls; }

	constructor(
		public gs: GlobalService,
		private itemService: ItemService,
		private fb: FormBuilder,
	) {
		this.socket = io(environment.SOCKET_URL);
	}

	ngOnInit(): void {
		let currentTime = Math.floor(Date.now() / 1000);
		this.form = this.fb.group({
			message: new FormControl('', [Validators.required]),
			video_time: new FormControl(currentTime, [Validators.required]),
		});
		this.chatRequestLoading = true;
		this.onLoadChat(true);
		this.socket.on('message', response => {
			let data = JSON.parse(response);
			//console.log('datadatadata', data);
			if((data.type === 'broadcast') && (parseInt(data.post_id) === parseInt(this.post_id))) {
				this.chatPage = 1;
				this.onLoadChat(true);
			}
		});
	}

	onLoadChat(removeForEach = false) {
		this.itemService.broadcastMessage("GET", null, { post_id: this.post_id, page: this.chatPage}).subscribe(response => {
			this.chatLoading = false;
			this.chatRequestLoading = false;
			let data = this.gs.apiResponce(response);
			if(removeForEach) {
				this.chatList = data.data ? data.data.items : [];
			} else {
				data.items && data.items.forEach(el => {
					let index = this.chatList.findIndex(val => val.id === el.id);
					if (index !== -1) {
						this.chatList[index] = el;
					} else {
						this.chatList.push(el);
					}
				});
			}
		}, (error: Response) => {
			this.chatLoading = false;
			this.chatRequestLoading = false;
			this.gs.handleErrors(error);
		})
	}

	addComment(form: any) {
		if (this.form.valid) {
			this.submitted = true;
		}
		this.itemService.broadcastMessage("POST", { PostMessages: form.value }, { post_id: this.post_id }).subscribe(data => {
			this.submitted = false;
			this.gs.alert('Sent');
			this.form.reset();
		}, (error: Response) => {
			this.gs.handleErrors(error);
			this.submitted = false;
		})
	}

	onScroll(event: any) {

	}

	scrolledUp(event: any) {
		this.chatLoading = true;
		this.chatPage = this.chatPage + 1;
		this.onLoadChat();
	}

	toggle() {
		this.show = !this.show;
	}

	addEmojiPost(selected: Emoji) {
		const emoji: string = (selected.emoji as any).native;
		const input = this.input.nativeElement;
		input.focus();
		if (document.execCommand) {
			var event = new Event('input');
			document.execCommand('insertText', false, emoji);
			return;
		}
		const [start, end] = [input.selectionStart, input.selectionEnd];
		input.setRangeText(emoji, start, end, 'end');
	}

	ngOnDestroy() {
		this.socket.disconnect();
	}

}

import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { GlobalService } from 'src/app/helpers/global.service';
import { Post } from 'src/app/models';
import { ItemService } from 'src/app/service/item.service';
import { SharePostComponent } from 'src/app/widgets/components/share-post/share-post.component';
import { environment } from 'src/environments/environment';

@Component({
	selector: 'app-public-video-box',
	templateUrl: './public-video-box.component.html',
	styleUrls: ['./public-video-box.component.css']
})
export class PublicVideoBoxComponent implements OnInit {
	@Input() item: Post = null;
	@Input() image: boolean = false;
    liveStremUrl = environment.restStreamApi;
	@ViewChild('video', { static: false }) video: ElementRef<HTMLInputElement>;

    shareDialogRef: MatDialogRef<SharePostComponent>;

	constructor(
		public gs: GlobalService,
        private dialog: MatDialog,
		private itemService: ItemService
	) { }

	ngOnInit(): void {
		//console.log('dfgdf')
	}

	streamVideo(item) {
		//const input = this.video.nativeElement;
		//console.log('this.video.nativeElement.src', input);
		//this.video.nativeElement.src = `${this.liveStremUrl}/${item?.postVideos?.stream_type}/${item?.postVideos?.url}`;
		//return `${this.liveStremUrl}/${item?.postVideos?.stream_type}/${item?.postVideos?.url}`;
		return item?.postVideos?.url;
	}

	sharePost() {
		this.shareDialogRef = this.dialog.open(SharePostComponent, {
            width: '550px',
            disableClose: true,
            panelClass: 'custom-dialog',
            data: this.item
        });
	}

	likePost() {
		const params = {
            "item_id": this.item.id,
            "module": 'Post',
            "reaction_id": 1
        }
		this.itemService.reaction("POST", params).subscribe(data => {
            const item = Object.assign({}, this.item, { reactionCount: data.data.reactionsCount, currentReaction: data.data.reaction.title });
            this.item = item;
            this.gs.alert('You have successfully like post in timeline.');
        }, (error: Response) => {
            this.gs.handleErrors(error);
        });
	}

}

import { Component, OnInit } from '@angular/core';
import { GlobalService } from 'src/app/helpers/global.service';
import { DefaultService } from 'src/app/service/default.service';

@Component({
	selector: 'app-dashboard-layout',
	templateUrl: './dashboard-layout.component.html',
	styleUrls: ['./dashboard-layout.component.scss']
})
export class DashboardLayoutComponent implements OnInit {

	constructor(
		public gs: GlobalService,
		public defaultService: DefaultService,
	) { }

	ngOnInit() {
		
	}

}

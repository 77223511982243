import { Component, Input, OnInit } from '@angular/core';
import { GlobalService } from 'src/app/helpers/global.service';
import { postVideos } from 'src/app/models';

@Component({
	selector: 'app-live-video-play',
	templateUrl: './live-video-play.component.html',
	styleUrls: ['./live-video-play.component.css']
})
export class LiveVideoPlayComponent implements OnInit {
	@Input() postVideos: postVideos | null = null;

	constructor(public gs: GlobalService) { }

	ngOnInit() {
		/*if (streamId != "undefined") {

			if (streamId.startsWith(streamsFolder)) {
				var lastIndexOfDot = streamId.lastIndexOf(".")
				var streamPath = streamId.substring(streamsFolder.length + 1, lastIndexOfDot);
				var extension = streamId.substring(lastIndexOfDot + 1);
				initializePlayer(streamPath, extension, token);
			}
			else {
				if (playOrder[0] == "webrtc") {
					initializeWebRTCPlayer(streamId, token, subscriberId, subscriberCode, webrtcNoStreamCallback);
				}
				else if (playOrder[0] == "hls") {
					tryToPlay(streamId, token, hlsExtension, subscriberId, subscriberCode, hlsNoStreamCallback);
				}
				else if (playOrder[0] == "vod") {
					tryToVODPlay(streamId, token, subscriberId, subscriberCode, vodNoStreamCallback, playType);
				}
				else if (playOrder[0] == "dash") {
					tryToPlay(streamId, token, dashExtension, subscriberId, subscriberCode, dashNoStreamCallback);
				}
				else {
					alert("Unsupported play order requested. Supported formats are webrtc and hls. Use something like playOrder=webrtc,hls");
				}
			}
		}
		else {
			alert("No stream specified. Please add ?id={STREAM_ID}  to the url");
		}*/
	}

	playWebRTCVideo() {

	}

}

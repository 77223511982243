<div *ngIf="announcementHide" class="w-100">
    <div class="announcement">
        <button (click)="hideButton()" class="btn btn-link"><i class="fas fa-times"></i></button>

        <!-- <pre>{{ announcement | async | json }}</pre> -->

        <ngb-carousel *ngIf="announcement | async">
            <ng-container *ngFor="let items of announcement | async; index as i">
                <ng-template ngbSlide>
                    <div class="card new-post mb-3">
                        <div class="card-body">
                            <div class="image">
                                <ng-container *ngIf="gs.checkMimetypeVideo(items.announcement_image && items.announcement_image.mimetype)">
                                    <i class="fas fa-video"></i>
                                    <video name="media" class="w-100" style="height: 300px;" (click)="openVideo(items, 'video')">
                                        <source src="{{items.announcement_image.videoUrl}}" type="{{items.announcement_image.mimetype}}">
                                    </video>
                                </ng-container>
                                <ng-container *ngIf="gs.checkMimetypeImage(items.announcement_image && items.announcement_image.mimetype)">
                                    <img src="{{(items.announcement_image.path)}}" alt="{{items.announcement_image.title}}" class="img-fluid w-100">
                                </ng-container>
                            </div>
                            <div class="text" *ngIf="gs.checkIframe(items.content)" [innerHtml]="items.content | safewidget: 'html'"></div>
                            <div class="text iframe" *ngIf="!gs.checkIframe(items.content)" (click)="openVideo(items, 'iframe')">
                                <i class="fab fa-youtube-square"></i>
                                <div [innerHtml]="items.content | safewidget: 'html'"></div>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </ng-container>
          </ngb-carousel>

        <!-- <div id="carouselExampleInterval" class="carousel slide carousel-fade" data-ride="carousel">
            <div class="carousel-inner">
                <div ngbSlide class=" {{(i === 0) ? 'active' : ''}}" *ngFor="let items of announcement | async; index as i" [attr.data-interval]="(items.slide_duration) ? items.slide_duration * 1000 : 4000">
                    <div class="card new-post mb-3">
                        <div class="card-body">
                            <div class="image">
                                <ng-container *ngIf="gs.checkMimetypeVideo(items.announcement_image && items.announcement_image.mimetype)">
                                    <i class="fas fa-video"></i>
                                    <video name="media" class="w-100" style="height: 300px;" (click)="openVideo(items, 'video')">
                                        <source src="{{items.announcement_image.videoUrl}}" type="{{items.announcement_image.mimetype}}">
                                    </video>
                                </ng-container>
                                <ng-container *ngIf="gs.checkMimetypeImage(items.announcement_image && items.announcement_image.mimetype)">
                                    <img src="{{(items.announcement_image.path)}}" alt="{{items.announcement_image.title}}" class="img-fluid w-100">
                                </ng-container>
                            </div>
                            <div class="text" *ngIf="gs.checkIframe(items.content)" [innerHtml]="items.content | safewidget: 'html'"></div>
                            <div class="text iframe" *ngIf="!gs.checkIframe(items.content)" (click)="openVideo(items, 'iframe')">
                                <i class="fab fa-youtube-square"></i>
                                <div [innerHtml]="items.content | safewidget: 'html'"></div>
                            </div>
                        </div>
                    </div>
                </div>
              
            </div>
            <a class="carousel-control-prev text-dark" href="#carouselExampleInterval" role="button" data-slide="prev">
                <i class="fas fa-chevron-left"></i>
            </a>
            <a class="carousel-control-next text-dark" href="#carouselExampleInterval" role="button" data-slide="next">
                <i class="fas fa-chevron-right"></i>
            </a>
        </div> -->

    </div>
</div>

<div class="header bg-primary text-white">
    <h3 mat-dialog-title class="d-flex align-items-center">
        <span class="col pl-0">Post Analytics</span>
        <button mat-button type="button" (click)="closeDialog()"><i class="fas fa-times"></i></button>
    </h3>
</div>
<mat-dialog-content>
    <!-- {{ data.content }} -->
    <div class="card-deck mb-3 text-center">
        <div class="card mb-4 shadow-sm">
          <div class="card-header">
            <h4 class="my-0 font-weight-normal d-inline-block">Post Views</h4>
          </div>
          

          <div class="card-body">
            <h2 class="card-title pricing-card-title">{{ data.views_count }}</h2>
            
          </div>
        </div>
        <div class="card mb-4 shadow-sm">
          <div class="card-header">
            <h4 class="my-0 font-weight-normal">Post Comments</h4>
          </div>
          <div class="card-body">
            <h2 class="card-title pricing-card-title">{{ data.commentCount }}</h2>
            
          </div>
        </div>
        
      </div>
      <div class="card-deck mb-3 text-center">
        <div class="card mb-4 shadow-sm">
          <div class="card-header">
            <h4 class="my-0 font-weight-normal d-inline-block">Post Reactions</h4>
          </div>
          <div class="card-body">
           <h2 class="card-title pricing-card-title">{{ data.reactionCount }} 
        </h2>
          </div>
        </div>
        <div class="card mb-4 shadow-sm">
          <div class="card-header">
            <h4 class="my-0 font-weight-normal">ReChat Count</h4>
          </div>
          <div class="card-body">
            <h2 class="card-title pricing-card-title">{{ data.shareCount }} 
         </h2>
           </div>
        </div>
        
      </div>
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-raised-button color="primary" class="px-5 py-1 text-uppercase" (click)="closeDialog()">Close</button>
</mat-dialog-actions>
<form novalidate (ngSubmit)="onLogin(form)" [formGroup]="form" autocomplete="off" class="d-flex login flex-wrap align-items-start">
  <div class="form-group">
    <label>Email Address</label>
    <input type="text" class="form-control" formControlName="username" placeholder="Email Address" required>
    <div class="invalid-feedback d-block" *ngIf="form.get('username').hasError('required') && (submitted || form.get('username').touched)">Invalid username and password.</div> 
    <div class="invalid-feedback d-block" *ngIf="gs.errors.length">
      <p *ngFor="let error of gs.errors;" class="mb-0">{{ error }}</p>
    </div>
  </div>
  <div class="form-group">
    <label>Password</label>
    <input type="password" class="form-control" formControlName="password" placeholder="Password" required>
    <div class="invalid-feedback d-block"><a [routerLink]="'/forgot-password'" class="forgot">Forgot Your Password?</a></div>
  </div>
  <div class="form-group pr-0 w-auto login-button"> 
    <label>&nbsp;</label>
    <button type="submit" class="btn btn-black" [ladda]="submitted">Sign In</button>
  </div>
</form>
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, catchError } from 'rxjs/operators';
import { GlobalService } from '../helpers/global.service';
import { environment } from '../../environments/environment';
import { Observable, Subject } from 'rxjs';
import { UserProfile } from './../models/UserProfile';

@Injectable({
	providedIn: 'root'
})

export class UserService {
	apiRoot: string = environment.apiUrl;

	private _userSetting = new Subject<string>();
	userSetting$ = this._userSetting.asObservable();

	private _view = new Subject<string>();
	slug$ = this._view.asObservable();

	private _is_birthday = new Subject<boolean>();
	isBirthday$ = this._is_birthday.asObservable();
	
	constructor(
		private http: HttpClient,
		private gs: GlobalService,
	) { }

	userSettings(data: string) {
        this._userSetting.next(data);
	}

	friendSlug(title: string) {
		this._view.next(title);
	}

	isBirthdat(is_birthday: boolean) {
		this._is_birthday.next(is_birthday);
	}

	search(method = "GET", params = null, params2 = null) {
		return this.http.request(method, `${this.apiRoot}/user/search`, { body: params, params: params2 })
			.pipe(map((response: any) => {
					return response;
				}), catchError(this.gs.handleHttpError)
			);
	}

	index(method = "GET", params = null, params2 = null) {
		return this.http.request(method, `${this.apiRoot}/user/index`, { body: params, params: params2 })
			.pipe(map((response: any) => {
					return response;
				}), catchError(this.gs.handleHttpError)
			);
	}

	profile(method = "GET", params = null, params2 = null): Observable<UserProfile> {
		return this.http.request<UserProfile>(method, `${this.apiRoot}/user/profile`, { body: params, params: params2 })
			.pipe(map((response: any) => {
					return response.data;
				}), catchError(this.gs.handleHttpError)
			);
	}

	abusedWords(method = "GET", params = null, params2 = null) {
		return this.http.request(method, `${this.apiRoot}/user/update-abused-words`, { body: params, params: params2 })
			.pipe(map((response: any) => {
					return response;
				}), catchError(this.gs.handleHttpError)
			);
	}

	workplace(method = "GET", params = null, params2 = null) {
		return this.http.request(method, `${this.apiRoot}/user/workplace`, { body: params, params: params2 })
			.pipe(map((response: any) => {
					return response;
				}), catchError(this.gs.handleHttpError)
			);
	}

	college(method = "GET", params = null, params2 = null) {
		return this.http.request(method, `${this.apiRoot}/user/college`, { body: params, params: params2 })
			.pipe(map((response: any) => {
					return response;
				}), catchError(this.gs.handleHttpError)
			);
	}

	school(method = "GET", params = null, params2 = null) {
		return this.http.request(method, `${this.apiRoot}/user/school`, { body: params, params: params2 })
			.pipe(map((response: any) => {
					return response;
				}), catchError(this.gs.handleHttpError)
			);
	}

	website(method = "GET", params = null, params2 = null) {
		return this.http.request(method, `${this.apiRoot}/user/website`, { body: params, params: params2 })
			.pipe(map((response: any) => {
					return response;
				}), catchError(this.gs.handleHttpError)
			);
	}

	email(method = "GET", params = null, params2 = null) {
		return this.http.request(method, `${this.apiRoot}/user/email`, { body: params, params: params2 })
			.pipe(map((response: any) => {
					return response;
				}), catchError(this.gs.handleHttpError)
			);
	}

	mobile(method = "GET", params = null, params2 = null) {
		return this.http.request(method, `${this.apiRoot}/user/mobile`, { body: params, params: params2 })
			.pipe(map((response: any) => {
					return response;
				}), catchError(this.gs.handleHttpError)
			);
	}

	socialLinks(method = "GET", params = null, params2 = null) {
		return this.http.request(method, `${this.apiRoot}/user/social-links`, { body: params, params: params2 })
			.pipe(map((response: any) => {
					return response;
				}), catchError(this.gs.handleHttpError)
			);
	}

	familyMembers(method = "GET", params = null, params2 = null) {
		return this.http.request(method, `${this.apiRoot}/user/family-members`, { body: params, params: params2 })
			.pipe(map((response: any) => {
					return response;
				}), catchError(this.gs.handleHttpError)
			);
	}

	inspiration(method = "GET", params = null, params2 = null) {
		return this.http.request(method, `${this.apiRoot}/user/inspiration`, { body: params, params: params2 })
			.pipe(map((response: any) => {
					return response;
				}), catchError(this.gs.handleHttpError)
			);
	}

	settings(method = "GET", params = null, params2 = null) {
		return this.http.request(method, `${this.apiRoot}/default/settings`, { body: params, params: params2 }).pipe(
			map((response: any) => response.data),
			catchError(this.gs.handleHttpError)
		);
	}

	changeEmail(method = "GET", params = null, params2 = null) {
		return this.http.request(method, `${this.apiRoot}/user/change-email`, { body: params, params: params2 }).pipe(
			map((response: any) => response.data),
			catchError(this.gs.handleHttpError)
		);
	}

	changePassword(method = "GET", params = null, params2 = null) {
		return this.http.request(method, `${this.apiRoot}/user/change-password`, { body: params, params: params2 }).pipe(
			map((response: any) => response.data),
			catchError(this.gs.handleHttpError)
		);
	}

	edit(method = "GET", params = null, params2 = null) {
		return this.http.request(method, `${this.apiRoot}/user/edit`, { body: params, params: params2 }).pipe(
			map((response: any) => response.data),
			catchError(this.gs.handleHttpError)
		);
	}

	newsletter(params = null) {
		return this.http.request("POST", `${this.apiRoot}/user/newsletter`, { body: params, params: null }).pipe(
			map((response: any) => response.data),
			catchError(this.gs.handleHttpError)
		);
	}

	uploadAvatar(method = "GET", params = null, params2 = null) {
		return this.http.request(method, `${this.apiRoot}/user/upload-avatar`, { body: params, params: params2 }).pipe(
			map((response: any) => response.data),
			catchError(this.gs.handleHttpError)
		);
	}

	uploadBanner(method = "GET", params = null, params2 = null) {
		return this.http.request(method, `${this.apiRoot}/user/upload-banner`, { body: params, params: params2 }).pipe(
			map((response: any) => response.data),
			catchError(this.gs.handleHttpError)
		);
	}

	updateRelationshipStatus(method = "GET", params = null, params2 = null) {
		return this.http.request(method, `${this.apiRoot}/user/update-relationship-status`, { body: params, params: params2 }).pipe(
			map((response: any) => response.data),
			catchError(this.gs.handleHttpError)
		);
	}

	inviteFriend(method = "GET", params = null, params2 = null) {
		return this.http.request(method, `${this.apiRoot}/user/invite-friend`, { body: params, params: params2 }).pipe(
			map((response: any) => response.data),
			catchError(this.gs.handleHttpError)
		);
	}

	blockUsers(method = "GET", params = null, params2 = null) {
		return this.http.request(method, `${this.apiRoot}/user/block-users`, { body: params, params: params2 }).pipe(
			map((response: any) => response.data),
			catchError(this.gs.handleHttpError)
		);
	}

	block(method = "GET", params = null, params2 = null) {
		return this.http.request(method, `${this.apiRoot}/user/block`, { body: params, params: params2 }).pipe(
			map((response: any) => response.data),
			catchError(this.gs.handleHttpError)
		);
	}

	unblock(method = "GET", params = null, params2 = null) {
		return this.http.request(method, `${this.apiRoot}/user/unblock`, { body: params, params: params2 }).pipe(
			map((response: any) => response.data),
			catchError(this.gs.handleHttpError)
		);
	}

	deactivateAccount(method = "GET", params = null, params2 = null) {
		return this.http.request(method, `${this.apiRoot}/user/deactivate-account`, { body: params, params: params2 }).pipe(
			map((response: any) => response),
			catchError(this.gs.handleHttpError)
		);
	}

	friends(method = "GET", params = null, params2 = null) {
		return this.http.request(method, `${this.apiRoot}/user/friends`, { body: params, params: params2 }).pipe(
			map((response: any) => response.data),
			catchError(this.gs.handleHttpError)
		);
	}

	follow(method = "GET", params = null, params2 = null) {
		return this.http.request(method, `${this.apiRoot}/user/follow`, { body: params, params: params2 })
			.pipe(map((response: any) => {
					return response;
				}), catchError(this.gs.handleHttpError)
			);
	}

	unfollow(method = "GET", params = null, params2 = null) {
		return this.http.request(method, `${this.apiRoot}/user/unfollow`, { body: params, params: params2 })
			.pipe(map((response: any) => {
					return response;
				}), catchError(this.gs.handleHttpError)
			);
	}

}
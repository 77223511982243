<mat-dialog-content>
	<div class="priview w-100 float-left d-flex">
		<div class="view border-right">
			<iframe width="854" height="415" [src]="data | safe: 'resourceUrl'" frameborder="0" allowfullscreen></iframe>
		</div>
		<div class="caption">
			<div class="header d-flex align-items-center border-bottom">
				<h5 class="mb-0 col p-0">Embed Video</h5>
				<button mat-button type="button" class="px-0" (click)="closeDialog()"><i class="fas fa-times"></i></button>
			</div>
			<div class="links">
				<pre>{{viewLink}}</pre>
			</div>
		</div>
	</div>
</mat-dialog-content>
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GlobalService } from 'src/app/helpers/global.service';
import { debounceTime } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { FormArray, ValidatorFn } from '@angular/forms';
import { UserService } from 'src/app/service/user.service';

@Component({
	selector: 'app-privacy-expect',
	templateUrl: './privacy-expect.component.html',
	styleUrls: ['./privacy-expect.component.scss']
})
export class PrivacyExpectComponent implements OnInit {

	indeterminate = false;
	labelPosition = 'before';
	searchText = '';

	urlSlug = '';
	loading = false;
	per_page = 7;
	page: number = 1;
	requestLoading = false;
	friends = [];
	modelChanged: Subject<string> = new Subject<string>();
	selectFriend = [];
	constructor(
		private userService: UserService,
		public gs: GlobalService,
		private dialogRef: MatDialogRef<PrivacyExpectComponent>, @Inject(MAT_DIALOG_DATA) public data
	) { }

	ngOnInit() {
		this.loadFriend(this.page);
		
		this.selectFriend = this.data.friend;

		this.modelChanged.pipe(debounceTime(500)).subscribe((data: any) => {
			this.page = 1;
			this.userService.friends("GET", null, { q: data, page: this.page }).subscribe((data: any) => {
				this.friends = [];
				data && data.forEach(frnd => {
					let customObject = {
						id: frnd.id,
						name: frnd.name,
						avatar: frnd.avatar,
						checked: (this.selectFriend.filter(x => x === frnd.id).length === 1) ? true : false,
						mutual_friends: frnd.mutual_friends_count,
					}
					this.friends.push(customObject);
				})
			}, (error: Response) => {
				this.gs.handleErrors(error);
			});
		});
			
	}

	submit() {
		this.dialogRef.close(this.selectFriend);
	}

	closeDialog() {
		this.selectFriend = [];
		this.dialogRef.close();
	}

	clearSearch() {
		this.searchText = '';
		this.page = 1;
		this.loadFriend(this.page);
	}

	loadFriend(page) {
		this.loading = true;
		// .setPage(page, this.per_page)
		const params = { page: page };
		this.userService.friends("GET", null, params).subscribe((data: any) => {
			data && data.forEach(frnd => {
				let customObject = {
					id: frnd.id,
					name: frnd.name,
					avatar: frnd.avatar,
					checked: (this.selectFriend.filter(x => x === frnd.id).length === 1) ? true : false,
					mutual_friends: frnd.mutual_friends_count,
				}
				const index = this.friends.findIndex(f => f.id == frnd.id);
				if (index !== -1) {
					this.friends[index] = customObject;
				} else {
					this.friends.push(customObject);
				}
			});
			this.requestLoading = true;
			this.loading = false;
		}, (error: Response) => {
			this.gs.handleErrors(error);
		});
	}

	onScroll(event) {
		this.page = this.page += 1;
		this.loadFriend(this.page);
	}

	onUp(event) {

	}

	dataChanged(event: string) {
		this.modelChanged.next(event);
	}

	onChange(event, item) {
		if(event.checked === true) {
			this.selectFriend.push(item.id);
		} else {
			this.selectFriend.splice(this.selectFriend.indexOf(item), 1);
		}
	}

	minSelectedCheckboxes(min = 1) {
		const validator: ValidatorFn = (formArray: FormArray) => {
		  const totalSelected = formArray.controls
			.map(control => control.value)
			.reduce((prev, next) => next ? prev + next : prev, 0);
	  
		  return totalSelected >= min ? null : { required: true };
		};
	  
		return validator;
	  }
	

}


<div class="header bg-primary text-white py-2">
	<h3 mat-dialog-title class="d-flex align-items-center">
		<span class="col pl-0">Specific Friends</span>
		<button mat-button type="button" (click)="closeDialog()"><i class="fas fa-times"></i></button>
	</h3>
</div>
<mat-dialog-content class="pt-3 px-2 border-bottom">
	<div class="friends-privacy">
		<div class="input-group mb-3">
			<div class="input-group-prepend">
				<span class="input-group-text"><i class="fas fa-search"></i></span>
			</div>
			<input type="text" class="form-control" [(ngModel)]="searchText" (ngModelChange)="dataChanged($event)" placeholder="Search for a friend or list...">
			<div class="input-group-append" *ngIf="searchText.length != 0">
				<button type="button" class="close" (click)="clearSearch()"><i class="fas fa-times"></i></button>
			</div>
		</div>
		<div class="w-100 pb-4 float-left all-list" *ngIf="requestLoading">
			<div class="scroll-inner" >
				<ng-scrollbar *ngIf="friends" class="privacy-scroll" thumbClass="white-scrollbars" appearance="compact" [sensorDisabled]="true">
					<div scrollViewport infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50" [scrollWindow]="false" (scrolled)="onScroll($event)" (scrolledUp)="onUp($event)">
						<ng-container *ngFor="let friend of friends">
							<mat-checkbox class="example-margin" [labelPosition]="labelPosition" [checked]="friend.checked" (change)="onChange($event, friend)">
								<div class="d-flex flex-wrap align-items-center profile">
									<div class="image">
										<img src="{{ friend.avatar }}" alt="" width="65" height="65" class="border img-fluid">
									</div>
									<div class="caption">
										<h5>{{ friend.name }}</h5>
										<p class="mb-0"><small><i class="fas fa-user-friends"></i> {{ friend.mutual_friends }} mutual friend</small></p>
									</div>
								</div>
							</mat-checkbox>
						</ng-container>
						<ng-container *ngIf="friends.length === 0">
							No friend found
						</ng-container>
					</div>
				</ng-scrollbar>
			</div>
		</div>

		<div class="w-100 float-left loadings text-center" *ngIf="loading">
			<div class="spinner-border text-primary">
				<span class="sr-only">Loading...</span>
			</div>
		</div>
		
	</div>
</mat-dialog-content>
<mat-dialog-actions class="text-right justify-content-end px-2">
	<button mat-raised-button color="info" type="button" (click)="closeDialog()" class="px-5 py-1 text-uppercase">Cancel</button>
	<button mat-raised-button color="primary" type="button" (click)="submit()" class="px-5 py-1 text-uppercase">Save Changes</button>
</mat-dialog-actions>
import { Injectable } from '@angular/core';
import { map, catchError } from 'rxjs/operators';
import { JwtHelperService } from '@auth0/angular-jwt';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { throwError, BehaviorSubject } from 'rxjs';
import { User } from '../../models/User';
import { environment } from 'src/environments/environment';
import { UserProfile } from 'src/app/models/UserProfile';
import { IAuth } from 'src/app/models/Auth';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Store } from '@ngrx/store';
import { AuthActions } from 'src/app/store/action';

@Injectable({
	providedIn: 'root'
})
export class AuthService {
	apiRoot: string = environment.apiUrl;
	isLoggedIn = false;
	private loggedIn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(this.isAuthenticated());
	private loggedUser: BehaviorSubject<User> = new BehaviorSubject<User>(this.getUserDetails());
	private loggedProfile: BehaviorSubject<UserProfile> = new BehaviorSubject<UserProfile>(this.getProfileDetails());
	authenticationState = this.loggedIn.asObservable();
	loggedUserState = this.loggedUser.asObservable();
	loggedUserProfile = this.loggedProfile.asObservable();
	public data: User;
	public user: User;
	token = null;

	//private currentUserSubject: BehaviorSubject<User>;
	//public currentUser: Observable<User>;

	constructor(
		private http: HttpClient,
		private jwtHelper: JwtHelperService,
		private snackBar: MatSnackBar,
		private store: Store<{}>
	) {
		//this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('user')));
		//this.currentUser = this.currentUserSubject.asObservable();
		//console.log(this.currentUser)
		//console.log('Login check', this.authenticationState);
	}

	login(credentials) {
		return this.http.post(this.apiRoot + '/authentication/login', credentials)
			.pipe(
				map((response: IAuth) => {
					if(response.code) {
						this.data = response.data;
						this.user = response.data;
						this.token = response.token;
						this.isLoggedIn = true;
						this.loggedIn.next(true);
						this.loggedUser.next(Object.assign({}, this.user));
						localStorage.setItem('token', this.token);
						this.store.dispatch(new AuthActions.loginSuccess({ user: this.user }));
						return this.data;
					} else {
						this.snackBar.open('Error.', null, { duration: 2000, verticalPosition: 'bottom', horizontalPosition: 'end', panelClass: 'bg-danger' });
					}
				}),
				catchError(this.handleErrors)
			);
	}

	register(data: User) {
		return this.http.post(this.apiRoot + '/authentication/register', data)
			.pipe(
				map((response: any) => {
					return response;
				}),
				catchError(this.handleErrors)
			);
	}

	handleErrors(error: HttpErrorResponse) {
		return throwError(error || "Server Error");
	}

	logout() {
		localStorage.removeItem('token');
		localStorage.removeItem('user');
		localStorage.removeItem('userProfile');
		localStorage.removeItem('userSettings');
		this.loggedIn.next(false);
		this.isLoggedIn = false;
		window.location.href = '/';
	}

	getAuthorizationToken() {
		return localStorage.getItem('token');
	}

	isAuthenticated() {
		return !this.jwtHelper.isTokenExpired(this.getAuthorizationToken());
	}

	expirationTokenDate() {
		return this.jwtHelper.getTokenExpirationDate(this.getAuthorizationToken());
	}

	decode() {
		return this.jwtHelper.decodeToken(this.getAuthorizationToken());
	}

	getUserDetails(): User {
		const user = JSON.parse(localStorage.getItem('user'));
		return user;
	}

	getProfileDetails(): UserProfile {
		const profile = JSON.parse(localStorage.getItem('user'));
		return profile;
	}
}

<!-- <ul class="list">
    <ng-container *ngIf="comments">
        <li *ngFor="let comment of comments; let last = last">
            <app-comment [comment]="comment" [owner]="item.owner" [item]="item" [module]="module" [shareModule]="item.module" [postId]="item?.id" [itemId]="itemId" (replyDelete)="replyDelete($event)" (commentDelete)="commentDelete($event)" (hideAllCommentOnPost)="hideAllCommentOnPost($event)" [myTimeline]="myTimeline" [timeline]="timeline"></app-comment>
            <hr [ngClass]="last ? 'd-none' : ''">
        </li>
        <li *ngIf="(comments.length === 0) && (loading === false)" class="text-center pt-2">
            Comments not found 
        </li>
    </ng-container>
</ul> -->

<ng-container *ngIf="comments">
    <!-- <app-post-comments [comments]="comments" [item]="item" [module]="module" [myTimeline]="myTimeline" [timeline]="timeline" (replyDelete)="replyDelete($event)" (commentDelete)="commentDelete($event)" (hideAllCommentOnPost)="hideAllCommentOnPost($event)" (loadComments)="replyDelete($event)"></app-post-comments> -->
    <app-post-comments [comments]="comments" [item]="item" [module]="module" [myTimeline]="myTimeline" [timeline]="timeline" (loads)="newLoadComment($event)"></app-post-comments>
    <div *ngIf="(comments.length === 0) && (loading === false)" class="text-center pt-2">
        Comments not found 
    </div>
</ng-container>

<div class="d-flex align-items-center justify-content-center" *ngIf="loading">
    <mat-spinner color="primary" class="spinner-size"></mat-spinner>
</div>

<div *ngIf="( pageCount > page )">
    <div class="view-more px-3 py-2">
        <a (click)="pagination()" class="btn btn-link pl-0">View More Comments</a>
    </div>
</div>
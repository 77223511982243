import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { GlobalService } from 'src/app/helpers/global.service';
import { Page } from 'src/app/modules/pages/models/Page';
import { ItemService } from 'src/app/service/item.service';
import { PageAction } from 'src/app/store/action';
import { PageSelector } from 'src/app/store/selector';

@Component({
	selector: 'app-page-you-like',
	templateUrl: './page-you-like.component.html',
	styleUrls: ['./page-you-like.component.scss']
})
export class PageYouLikeComponent implements OnInit {
	loading: Observable<boolean> = this.store.select(PageSelector.loading);
	pages: Observable<Page[]> = this.store.select(PageSelector.mayLikes);
	constructor(
		private itemService: ItemService,
		public gs: GlobalService,
		private store: Store<{}>,
	) { }

	ngOnInit() {
		this.pages.subscribe(data => {
			(data === null) && this.store.dispatch(new PageAction.MayLikeParams({params: {key: 'page-not-follows'}}));
		});
	}

	pageLike(item) {
		//console.log(this.pages);
		var data = {
			item_id: item.id,
			key: 'page-follows'
		}
		this.itemService.page("POST", null, data).subscribe((data: any) => {
			if (item.pageLike === 'Yes') {
				this.gs.alert('Page Liked successfully.');
				this.itemService.requestMembersCount('updateMainList');
				//this.pages[index].pageLike = 'No';
			} else {
				this.gs.alert('Page Liked successfully.');
				this.itemService.requestMembersCount('updateMainList');
				//this.pages[index].pageLike = 'Yes';
			}
		});
	}
}
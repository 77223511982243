import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Dimensions, ImageCroppedEvent, ImageTransform } from 'ngx-image-cropper';
import { CommonService } from 'src/app/service/common.service';
import { GlobalService } from 'src/app/helpers/global.service';
import { Observable } from 'rxjs';
import { User } from 'src/app/models/User';
import { AuthSelector } from 'src/app/store/selector';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-image-cropper',
  templateUrl: './image-cropper.component.html',
  styleUrls: ['./image-cropper.component.scss']
})
export class ImageCropperComponent implements OnInit {
    
	userObservable: Observable<User> = this.store.select(AuthSelector.User);
	user: User = null;
	imageChangedEvent: any = '';
    croppedImage: any = '';
    canvasRotation = 0;
    rotation = 0;
    scale = 1;
    showCropper = false;
    containWithinAspectRatio = false;
    transform: ImageTransform = {};
    loading: boolean = false;

  	constructor(
		private commonService: CommonService,
		public gs: GlobalService,
		public dialogRef: MatDialogRef<ImageCropperComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any,
		private store: Store<{}>
	  ) { }

	ngOnInit() {
        //console.log('this.imageChangedEvent', this.data);
        this.userObservable.subscribe(data => this.user = data);
        this.imageChangedEvent = this.data.cropper;
        //console.log('this.data.cropper', this.data.cropper)
	}

	fileChangeEvent(event: any): void {
		//console.log('event', event.target.files)
        this.imageChangedEvent = event;
    }

    imageCropped(event: ImageCroppedEvent) {
        this.croppedImage = event.base64;
        //console.log('event', event);
    }

    imageLoaded() {
        this.showCropper = true;
        //console.log('Image loaded'); 
    }

    cropperReady(sourceImageDimensions: Dimensions) {
        //console.log('Cropper ready', sourceImageDimensions);
    }

    loadImageFailed() {
        //console.log('Load failed');
    }

    rotateLeft() {
        this.canvasRotation--;
        this.flipAfterRotate();
    }

    rotateRight() {
        this.canvasRotation++;
        this.flipAfterRotate();
    }

    private flipAfterRotate() {
        const flippedH = this.transform.flipH;
        const flippedV = this.transform.flipV;
        this.transform = {
            ...this.transform,
            flipH: flippedV,
            flipV: flippedH
        };
    }


    flipHorizontal() {
        this.transform = {
            ...this.transform,
            flipH: !this.transform.flipH
        };
    }

    flipVertical() {
        this.transform = {
            ...this.transform,
            flipV: !this.transform.flipV
        };
    }

    resetImage() {
        this.scale = 1;
        this.rotation = 0;
        this.canvasRotation = 0;
        this.transform = {};
    }

    zoomOut() {
        this.scale -= .1;
        this.transform = {
            ...this.transform,
            scale: this.scale
        };
    }

    zoomIn() {
        this.scale += .1;
        this.transform = {
            ...this.transform,
            scale: this.scale
        };
    }

    toggleContainWithinAspectRatio() {
        this.containWithinAspectRatio = !this.containWithinAspectRatio;
    }

    updateRotation() {
        this.transform = {
            ...this.transform,
            rotate: this.rotation
        };
	}
	
	close() {
		this.dialogRef.close();
	}

	onUpload() {
        this.loading = true;
        const file = this.gs.base64toFile(this.croppedImage, `${this.data.imageName}.png`);
        //console.log('file1233', file);
        //let demo = FileList(file)
        /*fetch(this.croppedImage)
			.then(res => res.blob())
			.then(blob => {

				const file = new File([blob], "File name",{ type: "image/png" });
				console.log('file', file)
				console.warn('blob', blob)
		})*/
		//return false;
		this.commonService.cropperUpload(file).subscribe(event => {
            //console.log('event', event);
            this.loading = false;
            this.dialogRef.close(event.files[0]);
        }, (error: Response) => {
            this.gs.handleErrors(error);
            this.loading = false;
        });
    }

}

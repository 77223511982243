import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { GlobalService } from 'src/app/helpers/global.service';
import { ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { DefaultService } from 'src/app/service/default.service';

@Component({
	selector: 'app-content-page',
	templateUrl: './content-page.component.html',
	styleUrls: ['./content-page.component.scss'],
	//encapsulation: ViewEncapsulation.None,
})
export class ContentPageComponent implements OnInit {
	requestLoading = false;
	page = null;
	constructor(
		private route: ActivatedRoute,
		public gs: GlobalService,
		public defaultService: DefaultService,
		private titleService: Title
	) { }

	ngOnInit() {
		this.route.params.subscribe(params => {
			const params2 = {
				moduleId: 'page',
				slug: params.slug
			}
			this.defaultService.item("GET", null, params2).subscribe(data => {
				this.page = data.data;
				this.titleService.setTitle(this.gs.mainTitle + ' | ' + this.page.title);
				this.requestLoading = true;
			});
		});
	}

}

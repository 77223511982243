<form novalidate (ngSubmit)="onRegister(form)" [formGroup]="form" autocomplete="off">
	<div *ngIf="gs.errors.length" class="alert alert-danger" role="alert">
		<h4 class="alert-heading">Error Summary:</h4>
		<p *ngFor="let error of gs.errors;" class="mb-0">{{ error }}</p>
	</div>
	<div class="row">
		<div class="col-md-6 col-12">
			<mat-form-field appearance="outline">
				<mat-label>Email Address</mat-label>
				<input matInput placeholder="Email" formControlName="email">
				<mat-error *ngIf="f.email.errors && f.email.touched">
					<div *ngIf="f.email.errors.required">Email address is required</div>
					<div *ngIf="f.email.errors.email">Email address not valid</div>
				</mat-error>
			</mat-form-field>
		</div>
		<div class="col-md-6 col-12">
			<mat-form-field appearance="outline">
				<mat-label>User Name</mat-label>
				<input matInput placeholder="User Name" formControlName="username">
				<mat-error *ngIf="f.username.errors && f.username.touched">
					<div *ngIf="f.username.errors.required">User name is required</div>
					<div *ngIf="f.username.errors.minlength">User name must be at least 6 characters</div>
					<div *ngIf="f.username.errors.pattern">User name not accept special characters</div>
				</mat-error>
			</mat-form-field>
		</div>
	</div>
	<div class="row">
		<div class="col-md-6 col-12">
			<mat-form-field appearance="outline">
				<mat-label>Password</mat-label>
				<input matInput placeholder="Password" formControlName="password" [type]="hide ? 'text' : 'password'">
				<mat-error *ngIf="f.password.errors && f.password.touched">
					<div *ngIf="f.password.errors.required">Password is required</div>
					<div *ngIf="f.password.errors.minlength">Password must be at least 6 characters</div>
				</mat-error>
			</mat-form-field>
		</div>
		<div class="col-md-6 col-12">
			<mat-form-field appearance="outline">
				<mat-label>Confirm Password</mat-label>
				<input matInput placeholder="Password" formControlName="confirm_password"
					[type]="hide ? 'text' : 'password'">
				<mat-error *ngIf="f.confirm_password.errors && f.confirm_password.touched">
					<div *ngIf="f.confirm_password.errors.required">Confirm Password is required</div>
					<div *ngIf="f.confirm_password.errors.minlength">Confirm Password must be at least 6 characters
					</div>
					<div *ngIf="f.confirm_password.errors.mustMatch">Confirm Password must match</div>
				</mat-error>
			</mat-form-field>
		</div>

		<div class="col-md-6 col-12">
			<mat-form-field appearance="outline">
				<mat-label>First Name</mat-label>
				<input matInput placeholder="First Name" formControlName="first_name">
				<mat-error
					*ngIf="form.get('first_name').hasError('required') && (submitted || form.get('first_name').touched)">
					First Name is required</mat-error>
			</mat-form-field>
		</div>
		<div class="col-md-6 col-12">
			<mat-form-field appearance="outline">
				<mat-label>Last Name</mat-label>
				<input matInput placeholder="Last Name" formControlName="last_name">
				<mat-error
					*ngIf="form.get('last_name').hasError('required') && (submitted || form.get('last_name').touched)">
					Last Name is required</mat-error>
			</mat-form-field>
		</div>
		<div class="col-md-6 col-12">
			<mat-form-field appearance="outline">
				<mat-label>Country</mat-label>
				<mat-select placeholder="Country" formControlName="country_code" *ngIf="filteredCountry">
					<mat-option *ngFor="let country of filteredCountry" [value]="country.iso2">
						<span>{{ country.name }}</span>
					</mat-option>
				</mat-select>
				<mat-error
					*ngIf="form.get('country_code').hasError('required') && (submitted || form.get('country_code').touched)">
					Country is required</mat-error>
			</mat-form-field>
		</div>
		<div class="col-md-6 col-12">
			<mat-form-field appearance="outline">
				<mat-label>Mobile Number</mat-label>
				<span matPrefix style="margin-top: -21px; float: left;">{{phone_code}} &nbsp;</span>
				<input matInput placeholder="Mobile Number" type="number" formControlName="phone_number">
				<mat-error *ngIf="f.phone_number.errors && f.phone_number.touched">
					<div *ngIf="f.phone_number.errors.required">Mobile Number is required</div>
					<div *ngIf="f.phone_number.errors.pattern">Mobile Number not valid</div>
				</mat-error>
			</mat-form-field>
		</div>
		<div class="col-md-6 col-12">
			<mat-form-field appearance="outline">
				<mat-label>Gender</mat-label>
				<mat-select placeholder="Gander" formControlName="gender" required>
					<mat-option value="Male">Male</mat-option>
					<mat-option value="Female">Female</mat-option>
				</mat-select>
			</mat-form-field>
		</div>
		<div class="col-12">
			<div class="form-group mb-3">
				<mat-checkbox class="example-margin" formControlName="terms">I accept the <a target="_blank"
						href="/content/terms-of-service">Terms and Conditions</a></mat-checkbox>
				<mat-error *ngIf="form.get('terms').hasError('required') && (submitted || form.get('terms').touched)">
					Terms and conditions is required</mat-error>
			</div>

			<div class="form-group">
				<button mat-flat-button color="primary" class="px-5 py-2 text-uppercase" [ladda]="submitted">Sign
					Up</button>
			</div>
		</div>
	</div>
</form>
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { SendMessageComponent } from '../send-message/send-message.component';
import { TimelineService } from 'src/app/modules/timeline/services/timeline.service';
import { CommonService } from 'src/app/service/common.service';
import { GlobalService } from 'src/app/helpers/global.service';
import { User } from 'src/app/models/User';

@Component({
	selector: 'app-like-user-list',
	templateUrl: './like-user-list.component.html',
	styleUrls: ['./like-user-list.component.scss']
})
export class LikeUserListComponent implements OnInit {
	messageDialogRef: MatDialogRef<SendMessageComponent>;
	currentUser: User = null;
	allLikes = [];
	likeList = [];
	loveList = [];
	wowList = [];
	sadList = [];
	angryList = [];
	prayList = [];
	laughList = [];
	flagList = [];
	selectReactions = [];
	allCount:any;
	per_page = 20;
	all_page = 0;
	like_page = 0;
	love_page = 0;
	wow_page = 0;
	sad_page = 0;
	angry_page = 0;
	pray_page = 0;
	laugh_page = 0;
	flag_page = 0;
	requestLoading = false;
	loading = false;
	constructor(
		private dialog: MatDialog,
		private commonService: CommonService,
		private timelineService: TimelineService,
		private gs: GlobalService,
		private dialogRef: MatDialogRef<LikeUserListComponent>,
		@Inject(MAT_DIALOG_DATA) public data
	) { }

	ngOnInit() {
		this.currentUser = this.gs.user;
		const params = {
            'module': (this.data.module) ? this.data.module : 'Post',
			'item_id': this.data.post.id,
			'page-size': this.per_page,
			'page': this.all_page
		}
		this.timelineService.userLikeList(params).subscribe((data: any) => {
			data.result.forEach(element => this.allLikes.push(element));
			this.selectReactions = data.reactions;
			this.allCount = data.totalReactions;
			this.requestLoading = true;
			this.loading = false;
			data.reactions.forEach(element => {
				this.singleList(element.reaction_id, 0);
			});
		});

		this.commonService.userData$.subscribe(data => {
			if(data === 'closeMessageDialog') {
				this.closeDialog();
			}
		})
	}

	sendMessage(user) {
		this.messageDialogRef = this.dialog.open(SendMessageComponent, {
			width: '500px',
			disableClose: true,
			panelClass: 'custom-dialog',
			data: user
		});
	}

	closeDialog() {
		this.dialogRef.close();
	}

	allList() {
		this.loading = true;
		const params = {
            'module': (this.data.module) ? this.data.module : 'Post',
			'item_id': this.data.post.id,
			'page-size': this.per_page,
			'page': this.all_page
		}
		this.timelineService.userLikeList(params).subscribe((data: any) => {
			data.result.forEach(element => this.allLikes.push(element));
			this.loading = false;
		})
	}

	singleList(reaction_id, page) {
		this.loading = true;
		const params = {
			'module': (this.data.module) ? this.data.module : 'Post',
			'item_id': this.data.post.id,
			'reaction_id': reaction_id,
			'page-size': this.per_page,
			'page': page
		}
		this.timelineService.userLikeList(params).subscribe((data: any) => {
			switch (reaction_id) {
				case 1:
					data.result.forEach(element => {
						this.likeList.push(element);
					});
					break;
				case 2:
					data.result.forEach(element => {
						this.loveList.push(element);
					});
					break;
				case 4:
					data.result.forEach(element => {
						this.wowList.push(element);
					});
					break;
				case 5:
					data.result.forEach(element => {
						this.sadList.push(element);
					});
					break;
				case 6:
					data.result.forEach(element => {
						this.angryList.push(element);
					});
					break;
				case 15:
					data.result.forEach(element => {
						this.prayList.push(element);
					});
					break;
				case 16:
					data.result.forEach(element => {
						this.laughList.push(element);
					});
					break;
				case 17:
					data.result.forEach(element => {
						this.flagList.push(element);
					});
					break;
				default:
					break;
			}
			/*if(reaction_id === 1) {
				data.result.forEach(element => {
					this.likeList.push(element);
					const index = this.likeList.findIndex(f => f.id == element.id);
					if (index !== -1) {
						this.likeList[index] = element;
					} else {
						this.likeList.push(element);
					}
				});
			} else if(reaction_id === 2) {
				data.result.forEach(element => {
					this.loveList.push(element);
					const index = this.loveList.findIndex(f => f.id == element.id);
					if (index !== -1) {
						this.loveList[index] = element;
					} else {
						this.loveList.push(element);
					}
				});
			} else if(reaction_id === 4) {
				data.result.forEach(element => {
					this.wowList.push(element);
					const index = this.wowList.findIndex(f => f.id == element.id);
					if (index !== -1) {
						this.wowList[index] = element;
					} else {
						this.wowList.push(element);
					}
				});
			} else if(reaction_id === 5) {
				data.result.forEach(element => {
					this.sadList.push(element);
					const index = this.sadList.findIndex(f => f.id == element.id);
					if (index !== -1) {
						this.sadList[index] = element;
					} else {
						this.sadList.push(element);
					}
				});
			} else if(reaction_id === 6) {
				data.result.forEach(element => {
					this.angryList.push(element);
					const index = this.angryList.findIndex(f => f.id == element.id);
					if (index !== -1) {
						this.angryList[index] = element;
					} else {
						this.angryList.push(element);
					}
				});
			} else if(reaction_id === 15) {
				data.result.forEach(element => {
					this.prayList.push(element);
					const index = this.prayList.findIndex(f => f.id == element.id);
					if (index !== -1) {
						this.prayList[index] = element;
					} else {
						this.prayList.push(element);
					}
				});
			}*/
			this.loading = false;
		})
	}

	onScrollAll(event) {
		this.loading = true;
		this.all_page = this.all_page + 1;
		this.allList()
	}

	onScrollLike(event, reaction_id) {
		this.loading = true;
		this.like_page = this.like_page + 1;
		this.singleList(reaction_id, this.like_page);
	}

	onScrollWow(event, reaction_id) {
		this.loading = true;
		this.wow_page = this.wow_page + 1;
		this.singleList(reaction_id, this.wow_page);
	}

	onScrollSad(event, reaction_id) {
		this.loading = true;
		this.sad_page = this.sad_page + 1;
		this.singleList(reaction_id, this.sad_page);
	}

	onScrollAngry(event, reaction_id) {
		this.loading = true;
		this.angry_page = this.angry_page + 1;
		this.singleList(reaction_id, this.angry_page);
	}

	onScrollPray(event, reaction_id) {
		this.loading = true;
		this.angry_page = this.angry_page + 1;
		this.singleList(reaction_id, this.angry_page);
	}

	onScrollLaugh(event, reaction_id) {
		this.loading = true;
		this.laugh_page = this.laugh_page + 1;
		this.singleList(reaction_id, this.laugh_page);
	}

	onScrollFlag(event, reaction_id) {
		this.loading = true;
		this.flag_page = this.flag_page + 1;
		this.singleList(reaction_id, this.flag_page);
	}

	onScrollLove() {

	}

}

import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserService } from 'src/app/service/user.service';
import { LiveStreamService } from 'src/app/service/live-stream.service';
import { GlobalService } from 'src/app/helpers/global.service';
import { NavigationExtras, Router } from '@angular/router';
import { AuthActions } from 'src/app/store/action';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { User } from 'src/app/models/User';
import { AuthSelector } from 'src/app/store/selector';

@Component({
	selector: 'app-live-stream',
	templateUrl: './live-stream.component.html',
	styleUrls: ['./live-stream.component.css']
})
export class LiveStreamComponent implements OnInit {

	userObservable: Observable<User> = this.store.select(AuthSelector.User);
	user: User = null;

	constructor(
		private dialogRef: MatDialogRef<LiveStreamComponent>,
		@Inject(MAT_DIALOG_DATA) public data,
		private liveStreamService: LiveStreamService,
		private userService: UserService,
		private gs: GlobalService,
		private router: Router,
		private store: Store<{}>,
	) { }

	ngOnInit(): void {

		this.userObservable.subscribe(data => this.user = data);
		const formData = {
			name: this.user.username,
			type: 'liveStream',
			username: this.user.username,

		}
		this.liveStreamService.broadcastsCreate(formData).then(stream => {
			const streams: any = stream;
			localStorage.setItem('streamId', streams.streamId);
			const navigationExtras: NavigationExtras = {
				queryParams: {
					stream_id: streams.streamId,
					module: this.data.module,
					item_id: this.data.item_id,
					type: this.data.type
				}
			};
			if (this.data.type === 'webStream') {
				this.router.navigate(['/streaming/live-stream'], navigationExtras);
			} else {
				this.router.navigate(['/streaming/app-stream'], navigationExtras);
			}
			this.closeDialog();
		}).catch((error: Response) => {
			this.gs.handleErrors(error);
		})

		/*if (this.user.userProfile.stream_id === null || this.user.userProfile.stream_id === '') {
			const formData = {
				name: this.user.username,
				type: 'liveStream',
				username: this.user.username,
				
			}
			this.liveStreamService.broadcastsCreate(formData).then(stream => {
				const streams: any = stream;
				this.userService.profile("POST", { UserProfile: { stream_id: streams.streamId } }).subscribe((response: any) => {
					const data: any = response;
					this.gs.alert(data.message);
					this.store.dispatch(new AuthActions.loginSuccess({ user: response }));
					const navigationExtras: NavigationExtras = {
						queryParams: {
							stream_id: streams.streamId,
							module: this.data.module,
							item_id: this.data.item_id,
							type: this.data.type
						}
					};
					if(this.data.type === 'webStream'){
						this.router.navigate(['/streaming/live-stream'], navigationExtras);
					} else {
						this.router.navigate(['/streaming/app-stream'], navigationExtras);
					}
					this.closeDialog();
				}, (error: Response) => {
					this.gs.handleErrors(error);
				});
			})
		} else {
			var vm = this;
			setInterval(function () { 
				const navigationExtras: NavigationExtras = {
					queryParams: {
						stream_id: vm.user.userProfile.stream_id,
						module: vm.data.module,
						item_id: vm.data.item_id,
						type: vm.data.type
					}
				};
				if(vm.data.type === 'webStream'){
					vm.router.navigate(['/streaming/live-stream'], navigationExtras);
				} else {
					vm.router.navigate(['/streaming/app-stream'], navigationExtras);
				}
				vm.dialogRef.close();
			 }, 1500);
		}*/
	}


	closeDialog() {
		this.dialogRef.close();
	}

	public ngOnDestroy() {

	}

}

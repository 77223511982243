import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormControl, Validators, FormGroup } from '@angular/forms';
import { GlobalService } from 'src/app/helpers/global.service';
import { CommonService } from 'src/app/service/common.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DefaultService } from 'src/app/service/default.service';

@Component({
	selector: 'app-ask-faq',
	templateUrl: './ask-faq.component.html',
	styleUrls: ['./ask-faq.component.scss']
})
export class AskFaqComponent implements OnInit {
	form: FormGroup;
	submitted = false;
	
	constructor(
		private formBuilder: FormBuilder,
		public gs: GlobalService,
		public commonService: CommonService,
		public defaultService: DefaultService,
		private dialogRef: MatDialogRef<AskFaqComponent>,
		@Inject(MAT_DIALOG_DATA) public data
	) { }

	ngOnInit() {
		this.form = this.formBuilder.group({
			//faqForm: this.formBuilder.group({
				moduleId: 'faq',
				title: new FormControl('', [Validators.required]),
				content: new FormControl('', [Validators.required]),
				status: 0
			//})
		});
	}

	submit(form) {
		this.submitted = true;
		if (!form.valid) {
			this.submitted = false;
			return false;
		}
		this.defaultService.item("POST", { CmsItemForm: form.value }).subscribe((data: any) => {
			this.gs.alert('FAQ has been send to admin successfully.');
			this.dialogRef.close();
		}, (error: Response) => {
			this.submitted = false;
		});
	}


	closeDialog() {
		this.dialogRef.close()
	}
}

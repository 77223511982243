import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppRouterLoader } from './app-routing-loader';
import { HomeComponent } from './components/home/home.component';
import { AboutComponent } from './components/about/about.component';
import { ContactComponent } from './components/contact/contact.component';
import { ForgotPasswordComponent } from './auth/components/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './auth/components/reset-password/reset-password.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { AuthGuard } from './auth/guards/auth.guard';
import { ContentPageComponent } from './components/content-page/content-page.component';
import { EmailVerifyComponent } from './auth/components/email-verify/email-verify.component';
import { FaqsComponent } from './components/faqs/faqs.component';
import { ComingSoonComponent } from "./components/coming-soon/coming-soon.component";
import { NewsComponent } from './modules/general/news/news.component';
import { OtpVerificationComponent } from './auth/components/otp-verification/otp-verification.component';

// Routers
const routes: Routes = [
	{ path: '', component: NewsComponent, pathMatch: 'full' },
	{ path: 'register', component: HomeComponent },
	{ path: 'about', component: AboutComponent },
	{ path: 'contact', component: ContactComponent },
	{ path: 'faqs', component: FaqsComponent },
	{ path: 'forgot-password', component: ForgotPasswordComponent },
	{ path: 'reset-password', component: ResetPasswordComponent },
	{ path: 'email-verification', component: EmailVerifyComponent },
	{ path: 'otp-verification', component: OtpVerificationComponent },
	{ path: 'content/:slug', component: ContentPageComponent },
	{ path: 'coming-soon/:slug', component: ComingSoonComponent },
	{
		path: 'timeline',
		loadChildren: () => import('./modules/timeline/timeline.module').then(m => m.TimelineModule), canActivate: [AuthGuard], data: { preload: true, delay: true }
	},
	{
		path: 'streaming',
		loadChildren: () => import('./modules/live-stream/live-stream.module').then(m => m.LiveStreamModule), canActivate: [AuthGuard], data: { preload: true, delay: true }
	},
	{
		path: 'user',
		loadChildren: () => import('./modules/user/user.module').then(m => m.UserModule), canActivate: [AuthGuard], data: { preload: true, delay: true }
	},
	{
		path: 'pages',
		loadChildren: () => import('./modules/pages/pages.module').then(m => m.PagesModule), canActivate: [AuthGuard], data: { preload: true, delay: true }
	},
	{
		path: 'events',
		loadChildren: () => import('./modules/event/event.module').then(m => m.EventModule), canActivate: [AuthGuard], data: { preload: true, delay: true }
	},
	{
		path: 'groups',
		loadChildren: () => import('./modules/group/group.module').then(m => m.GroupModule), canActivate: [AuthGuard], data: { preload: true, delay: true }
	},
	{
		path: 'photos',
		loadChildren: () => import('./modules/albums/albums.module').then(m => m.AlbumsModule), canActivate: [AuthGuard], data: { preload: true, delay: true }
	},
	{
		path: 'videos',
		loadChildren: () => import('./modules/videos/videos.module').then(m => m.VideosModule), canActivate: [AuthGuard], data: { preload: true, delay: true }
	},
	/*{
		path: '',
		loadChildren: () => import('./modules/messanger/messanger.module').then(m => m.MessangerModule), canActivate: [AuthGuard], data: { preload: true, delay: true }
	},
	{
		path: 'messages',
		loadChildren: () => import('./modules/message/message.module').then(m => m.MessageModule), canActivate: [AuthGuard], data: { preload: true, delay: true }
	},*/
	{
		path: '',
		loadChildren: () => import('./modules/general/general.module').then(m => m.GeneralModule), data: { preload: true, delay: true }
	},
	{
		path: 'messanger',
		loadChildren: () => import('./modules/messanger/messanger.module').then(m => m.MessangerModule), canActivate: [AuthGuard], data: { preload: true, delay: true }
	},
	/*{
		path: 'blogs',
		loadChildren: () => import('./modules/blogs/blogs.module').then(m => m.BlogsModule), data: { preload: true, delay: true }
	},
	{
		path: 'messages',
		loadChildren: () => import('./modules/message/message.module').then(m => m.MessageModule), canActivate: [AuthGuard], data: { preload: true, delay: true }
	},
	{
		path: 'messanger',
		loadChildren: () => import('./modules/messanger/messanger.module').then(m => m.MessangerModule), canActivate: [AuthGuard], data: { preload: true, delay: true }
	},
	{
		path: 'public',
		loadChildren: () => import('./modules/public-post/public-post.module').then(m => m.PublicPostModule), data: { preload: true, delay: true }
	},*/
	{ path: '**', component: NotFoundComponent }

];

@NgModule({
	imports: [
		RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled', preloadingStrategy: AppRouterLoader })
	],
	exports: [RouterModule],
	providers: [AppRouterLoader]
})
export class AppRoutingModule { }


<div class="header bg-primary text-white">
	<h3 mat-dialog-title class="d-flex align-items-center">
		<span class="col pl-0">Login</span>
		<button mat-button type="button" (click)="closeDialog()"><i class="fas fa-times"></i></button>
	</h3>
</div>
<mat-dialog-content>
	<form novalidate (ngSubmit)="onLogin(form)" [formGroup]="form" autocomplete="off"
		class="d-flex login flex-wrap align-items-start">
		<mat-form-field appearance="outline">
			<mat-label>Email Address</mat-label>
			<input matInput placeholder="Email Address" formControlName="username">
			<mat-error *ngIf="form.get('username').hasError('required') && (submitted || form.get('username').touched)">
				<div>Invalid username and password.</div>
			</mat-error>
		</mat-form-field>
		<mat-form-field appearance="outline">
			<mat-label>Password</mat-label>
			<input matInput placeholder="Password" [type]="hide ? 'text' : 'password'" formControlName="password">
			<mat-error *ngIf="form.get('username').hasError('required') && (submitted || form.get('username').touched)">
				<div>Invalid username and password.</div>
			</mat-error>
		</mat-form-field>
		<div class="invalid-feedback d-block mb-3" *ngIf="gs.errors.length">
			<p *ngFor="let error of gs.errors;" class="mb-0">{{ error }}</p>
		</div>
		<div class="form-group pr-0 w-100 login-button">
			<button type="submit" mat-flat-button color="primary" class="px-5 py-2 text-uppercase" [disabled]="!form.valid" [ladda]="submitted">Sign In</button>
		</div>
	</form>
</mat-dialog-content>

<div class="blog-list w-100 float-left py-4">
	<div class="container">
		<div class="row">
			<div class="col-md-6 col-12 mx-auto">
				<div class="card page-card">
					<div class="card-header d-flex flex-wrap align-items-center">
						<h3 class="card-title col pl-0">Otp Verification</h3>
					</div>
					<div class="card-body">
						<form novalidate [formGroup]="forgot" (ngSubmit)="onForgot(forgot)">
							<div *ngIf="gs.errors.length" class="alert alert-danger" role="alert">
								<h4 class="alert-heading">Error Summary:</h4>
								<p *ngFor="let error of gs.errors;" class="mb-0">{{ error }}</p>
							</div>
							<mat-form-field appearance="outline">
							  	<mat-label>Opt</mat-label>
							  	<input matInput placeholder="Opt" formControlName="otp" required>
							  	<mat-error *ngIf="f.otp.errors && f.otp.touched">
									<div *ngIf="f.otp.errors.required">Otp is required</div>
							  	</mat-error>
								</mat-form-field>
								<div class="form-group">
							  		<button type="submit" class="btn btn-lg btn-block btn-primary" [ladda]="submitted" [disabled]="!forgot.valid">Verify</button>
								</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
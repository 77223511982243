import { Action } from '@ngrx/store';
import { GroupMember } from 'src/app/modules/group/models/GroupMember';

export enum GroupActionTypes {
    GROUP_MY_LIST_PARAMS = '[GROUP_MY_LIST_PARAMS] Group My List Params',
    GROUP_MY_LIST = '[GROUP_MY_LIST] Group My List',
    GROUP_ADD_PARAMS = '[GROUP_ADD_PARAMS] Group Add Params',
    GROUP_ADD = '[GROUP_ADD] Group Add',
    GROUP_UPDATE_PARAMS = '[GROUP_UPDATE_PARAMS] Group Update Params',
    GROUP_UPDATE = '[GROUP_UPDATE] Group Update',
    GROUP_DETELE_PARAMS = '[GROUP_DETELE_PARAMS] Group Detele Params',
    GROUP_DETELE = '[GROUP_DETELE] Group Detele',
    GROUP_VIEW_PARAMS = '[GROUP_VIEW_PARAMS] Group View Params',
    GROUP_VIEW = '[GROUP_VIEW] Group View',
    GROUP_ADD_VIEW = '[GROUP_ADD_VIEW] Group Add View',
    GROUP_JOIN_PARAMS = '[GROUP_JOIN_PARAMS] Group Join Params',
    GROUP_JOIN = '[GROUP_JOIN] Group Join',
    GROUP_MEMBERS_PARAMS = '[GROUP_MEMBERS_PARAMS] Group Members Params',
    GROUP_MEMBERS_LIST_PARAMS = '[GROUP_MEMBGROUP_MEMBERS_LIST_PARAMSERSLIST_PARAMS] Group Members LIst Params',
    GROUP_MEMBERS_LIST = '[GROUP_MEMBERS_LIST] Group Members LIst',
    GROUP_MEMBERS_MORE = '[GROUP_MEMBERS_MORE] Group Members More',
    GROUP_MEMBERS_ADD = '[GROUP_MEMBERS_ADD] Group Members Add',
    GROUP_MEMBERS_UPDATE = '[GROUP_MEMBERS_UPDATE] Group Members Update',
    GROUP_MEMBERS_REMOVE = '[GROUP_MEMBERS_REMOVE] Group Members Remove',
    GROUP_MODERATORS_LIST_PARAMS = '[GROUP_MEMBGROUP_MODERATORS_LIST_PARAMSERSLIST_PARAMS] Group Moderators LIst Params',
    GROUP_MODERATORS_LIST = '[GROUP_MODERATORS_LIST] Group Moderators LIst',
    GROUP_MODERATORS_MORE = '[GROUP_MODERATORS_MORE] Group Moderators More',
    GROUP_MODERATORS_ADD = '[GROUP_MODERATORS_ADD] Group MODERATORS Add',
    GROUP_MODERATORS_UPDATE = '[GROUP_MODERATORS_UPDATE] Group MODERATORS Update',
    GROUP_MODERATORS_REMOVE = '[GROUP_MODERATORS_REMOVE] Group MODERATORS Remove',
    GROUP_ADMIN_LIST_PARAMS = '[GROUP_ADMIN_LIST_PARAMS] Group Admin LIst Params',
    GROUP_ADMIN_LIST = '[GROUP_ADMIN_LIST] Group Admin LIst',
    GROUP_ADMIN_MORE = '[GROUP_ADMIN_MORE] Group Admin More',
    GROUP_ADMIN_ADD = '[GROUP_ADMIN_ADD] Group Admin Add',
    GROUP_ADMIN_UPDATE = '[GROUP_ADMIN_UPDATE] Group Admin Update',
    GROUP_ADMIN_REMOVE = '[GROUP_ADMIN_REMOVE] Group Admin Remove',
    GROUP_DESTROY = '[GROUP_DESTROY] Group Destroy',
    GROUP_FAILURE = '[GROUP_FAILURE] failed',
}

export class MyListParams implements Action {
    readonly type = GroupActionTypes.GROUP_MY_LIST_PARAMS;
    constructor(public options: { params?: any, key?: string}) { }
} 

export class MyList implements Action {
    readonly type = GroupActionTypes.GROUP_MY_LIST;
    constructor(public myList: any) { }
} 

export class AddParams implements Action {
    readonly type = GroupActionTypes.GROUP_ADD_PARAMS;
    constructor(public options: { params?: any, key?: string}) { }
} 

export class Add implements Action {
    readonly type = GroupActionTypes.GROUP_ADD;
    constructor(public myList: any) { }
} 

export class ViewParams implements Action {
    readonly type = GroupActionTypes.GROUP_VIEW_PARAMS;
    constructor(public options: { params?: any, key?: string}) { }
} 

export class View implements Action {
    readonly type = GroupActionTypes.GROUP_VIEW;
    constructor(public view: any) { }
} 

export class AddView implements Action {
    readonly type = GroupActionTypes.GROUP_ADD_VIEW;
    constructor(public addView: any) { }
} 

export class JoinParams implements Action {
    readonly type = GroupActionTypes.GROUP_JOIN_PARAMS;
    constructor(public options: { method: any, params?: any, params2?: any, key?: string}) { }
} 

export class Join implements Action {
    readonly type = GroupActionTypes.GROUP_JOIN;
    constructor(public view: any) { }
} 

export class MembersParams implements Action {
    readonly type = GroupActionTypes.GROUP_MY_LIST_PARAMS;
    constructor(public options: { method: string, params?: any, params2?: any, key?: string}) { }
}

export class MembersListParams implements Action {
    readonly type = GroupActionTypes.GROUP_MEMBERS_LIST_PARAMS;
    constructor(public options: { method: string, params?: any, params2?: any, key?: string}) { }
} 

export class MembersList implements Action {
    readonly type = GroupActionTypes.GROUP_MEMBERS_LIST;
    constructor(public memberList: any) { }
} 

export class MembersMore implements Action {
    readonly type = GroupActionTypes.GROUP_MEMBERS_MORE;
    constructor(public more: any) { }
} 

export class MemberAdd implements Action {
    readonly type = GroupActionTypes.GROUP_MEMBERS_ADD;
    constructor(public add: GroupMember) { }
} 

export class MemberUpdate implements Action {
    readonly type = GroupActionTypes.GROUP_MEMBERS_UPDATE;
    constructor(public update: GroupMember) { }
} 

export class MemberRemove implements Action {
    readonly type = GroupActionTypes.GROUP_MEMBERS_REMOVE;
    constructor(public remove: GroupMember) { }
} 

export class ModeratorListParams implements Action {
    readonly type = GroupActionTypes.GROUP_MODERATORS_LIST_PARAMS;
    constructor(public options: { method: string, params?: any, params2?: any, key?: string}) { }
} 

export class ModeratorsList implements Action {
    readonly type = GroupActionTypes.GROUP_MODERATORS_LIST;
    constructor(public moderatorsList: any) { }
} 

export class ModeratorMore implements Action {
    readonly type = GroupActionTypes.GROUP_MODERATORS_MORE;
    constructor(public more: any) { }
} 

export class ModeratorAdd implements Action {
    readonly type = GroupActionTypes.GROUP_MODERATORS_ADD;
    constructor(public add: GroupMember) { }
} 

export class ModeratorUpdate implements Action {
    readonly type = GroupActionTypes.GROUP_MODERATORS_UPDATE;
    constructor(public update: GroupMember) { }
} 

export class ModeratorRemove implements Action {
    readonly type = GroupActionTypes.GROUP_MODERATORS_REMOVE;
    constructor(public remove: GroupMember) { }
} 

export class AdminListParams implements Action {
    readonly type = GroupActionTypes.GROUP_ADMIN_LIST_PARAMS;
    constructor(public options: { method: string, params?: any, params2?: any, key?: string}) { }
} 

export class AdminList implements Action {
    readonly type = GroupActionTypes.GROUP_ADMIN_LIST;
    constructor(public adminList: any) { }
} 

export class AdminMore implements Action {
    readonly type = GroupActionTypes.GROUP_ADMIN_MORE;
    constructor(public more: any) { }
} 

export class AdminAdd implements Action {
    readonly type = GroupActionTypes.GROUP_ADMIN_ADD;
    constructor(public add: GroupMember) { }
} 

export class AdminUpdate implements Action {
    readonly type = GroupActionTypes.GROUP_ADMIN_UPDATE;
    constructor(public update: GroupMember) { }
} 

export class AdminRemove implements Action {
    readonly type = GroupActionTypes.GROUP_ADMIN_REMOVE;
    constructor(public remove: GroupMember) { }
} 

export class Destroy implements Action {
    readonly type = GroupActionTypes.GROUP_DESTROY;
    constructor(public view?: any) { }
} 

export class failure implements Action {
    readonly type = GroupActionTypes.GROUP_FAILURE;
    constructor(public error: any) { }
}

export type GroupAction = MyListParams | MyList | AddParams | Add | ViewParams | View | AddView | JoinParams | Join | MembersParams | MembersListParams | MembersList | MembersMore | MemberAdd | MemberUpdate | MemberRemove | ModeratorListParams | ModeratorsList | ModeratorMore | ModeratorAdd | ModeratorUpdate | ModeratorRemove | AdminListParams | AdminList | AdminMore | AdminAdd | AdminUpdate | AdminRemove | Destroy | failure;
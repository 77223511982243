import { Action } from '@ngrx/store';
import { Post } from 'src/app/models/Post';

export enum GroupTimelineTypes {
    FAILURE = '[GROUP_TIMELINE_FAILURE] failed',
    LIST_PARAMS = '[GROUP_TIMELINE_LIST_PARAMS] GROUP Timeline List Params',
    LIST = '[GROUP_TIMELINE_LIST] GROUP Timeline List',
    LIST_MORE = '[GROUP_TIMELINE_LIST_MORE] GROUP Timeline List More',
    ADD = '[GROUP_TIMELINE_ADD] GROUP Timeline Add',
    UPDATE = '[GROUP_TIMELINE_UPDATE] GROUP Timeline Update',
    DELETE = '[GROUP_TIMELINE_UPDATE] GROUP Timeline Detele',
    DESTROY = '[GROUP_TIMELINE_DESTROY] GROUP Timeline Destroy',
}

export class ListParams implements Action {
    readonly type = GroupTimelineTypes.LIST_PARAMS;
    constructor(public options: { params?: any, key?: string}) { }
} 

export class List implements Action {
    readonly type = GroupTimelineTypes.LIST;
    constructor(public list: any) { }
}

export class More implements Action {
    readonly type = GroupTimelineTypes.LIST_MORE;
    constructor(public more: any) { }
}

export class Add implements Action {
    readonly type = GroupTimelineTypes.ADD;
    constructor(public item: Post) { }
}

export class Update implements Action {
    readonly type = GroupTimelineTypes.UPDATE;
    constructor(public item: Post) { }
}

export class Delete implements Action {
    readonly type = GroupTimelineTypes.DELETE;
    constructor(public item: Post) { }
}

export class Destroy implements Action {
    readonly type = GroupTimelineTypes.DESTROY;
    constructor(public view?: any) { }
} 

export class failure implements Action {
    readonly type = GroupTimelineTypes.FAILURE;
    constructor(public error: any) { }
}


export type GroupTimelineAction = ListParams | List | More | Add | Update | Delete | Destroy | failure;
<ng-container *ngIf="requestLoading else loaded">
	<div *ngIf="items?.length == 0">News not found</div>
	<owl-carousel-o [options]="featureOptions">
		<ng-template carouselSlide *ngFor="let item of items">
			<div class="feature-box">
				<div class="caption">
					<h5>
						<ng-container *ngIf="(item && item?.external_url) else loaded2">
							<a [href]="item?.external_url" target="_blank">{{item.title | slice:0:50}}</a>
						</ng-container>
						<ng-template #loaded2>
							<a routerLink="/news/view/{{item?.slug}}">{{item.title | slice:0:50}}</a>
						</ng-template>
					</h5>
				</div>
				<img src="{{ item.live_image_url }}" alt="Image">
			</div>
		</ng-template>
	</owl-carousel-o>
</ng-container>
<ng-template #loaded>
	<div class="d-flex align-items-center justify-content-center w-100">
		<mat-spinner color="primary" class="spinner-size"></mat-spinner>
	</div>
</ng-template>

<!-- {{(friends | async)?.length}} -->
<div class="card custom-card mt-3" *ngIf="(friends | async)?.length > 0">
    <div class="card-body pb-0">
        <h3 class="card-title text-left">
            People You May Know
        </h3>
        <div class="list-group list-group-flush people-you-know">
            <ng-container *ngIf="(friends | async)?.length < 3">
                <div class="row">
                    <div class="col-sm-4" *ngFor="let friend of friends | async">
                        <div class="list-group-item">
                            <div class="image text-center">
                                <img src="{{ friend.avatar }}" alt="" class="img-fluid" width="50" height="50" />
                            </div>
                            <div class="caption">
                                <h6 class="text-truncate">
                                    <a routerLink="/user/friend/timeline/{{friend.username}}">{{ friend.name }}</a> <br>
                                    <small>Mutual Friends: {{ friend.mutual_friends_count }}</small>
                                </h6>
                                <div class="button" *ngIf="user.id !== friend.id">
                                    <a mat-stroked-button *ngIf="friend.friendState === 0" (click)="addFriend(friend, 'add')"><i class="fas fa-user-plus"></i> Add Friend</a>
                                    <a mat-stroked-button *ngIf="friend.friendState === 1" (click)="unFriend(friend)" color="primary"><i class="fas fa-user-minus"></i> Unfriend</a>
                                    <a mat-stroked-button *ngIf="friend.friendState === 3" (click)="declineFriend(friend)" color="accent"><i class="fas fa-user-friends"></i> Request Sent</a>
                                    <a mat-flat-button *ngIf="friend.friendState === 2" (click)="addFriend(friend, 'conform')" color="primary"><i class="fas fa-user-friends"></i> Confirm</a>
                                    <a mat-stroked-button *ngIf="friend.friendState === 2" (click)="declineFriend(friend)"><i class="fas fa-user-times"></i> Decline</a>
                                    <a mat-stroked-button *ngIf="(friend.friendState === 5) || (friend.friendState === 4)" [routerLink]="['/user/setting/block-setting']"><i class="fas fa-unlock"></i> Unblock</a>
                                    <!-- <a mat-stroked-button *ngIf="friend.is_follow == false" (click)="followUser(friend, 'follow')"><i class="fas fa-rss"></i> Follow</a>
                                    <a mat-flat-button *ngIf="friend.is_follow == true" color="primary" (click)="followUser(friend, 'unfollow')"><i class="fas fa-rss"></i> UnFollow</a> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>
            

            <ng-container *ngIf="(friends | async)?.length > 3">
                <owl-carousel-o [options]="advertisementtOptions" (change)="getData($event)">
                    <ng-container *ngFor="let friend of friends | async">
                        <ng-template carouselSlide [id]="friend.id">
                            <div class="list-group-item">
                                <div class="image text-center">
                                    <img src="{{ friend.avatar }}" alt="" class="img-fluid" width="50" height="50" />
                                </div>
                                <div class="caption">
                                    <h6 class="text-truncate">
                                        <a routerLink="/user/friend/timeline/{{friend.username}}">{{ friend.name }}</a> <br>
                                        <small>Mutual Friends: {{ friend.mutual_friends_count }}</small>
                                    </h6>
                                    <div class="button" *ngIf="user.id !== friend.id">
                                        <a mat-stroked-button *ngIf="friend.friendState === 0" (click)="addFriend(friend, 'add')"><i class="fas fa-user-plus"></i> Add Friend</a>
                                        <a mat-stroked-button *ngIf="friend.friendState === 1" (click)="unFriend(friend)" color="primary"><i class="fas fa-user-minus"></i> Unfriend</a>
                                        <a mat-stroked-button *ngIf="friend.friendState === 3" (click)="declineFriend(friend)" color="accent"><i class="fas fa-user-friends"></i> Request Sent</a>
                                        <a mat-flat-button *ngIf="friend.friendState === 2" (click)="addFriend(friend, 'conform')" color="primary"><i class="fas fa-user-friends"></i> Confirm</a>
                                        <a mat-stroked-button *ngIf="friend.friendState === 2" (click)="declineFriend(friend)"><i class="fas fa-user-times"></i> Decline</a>
                                        <a mat-stroked-button *ngIf="(friend.friendState === 5) || (friend.friendState === 4)" [routerLink]="['/user/setting/block-setting']"><i class="fas fa-unlock"></i> Unblock</a>
                                        <!-- <a mat-stroked-button *ngIf="friend.is_follow == false" (click)="followUser(friend, 'follow')"><i class="fas fa-rss"></i> Follow</a>
                                        <a mat-flat-button *ngIf="friend.is_follow == true" color="primary" (click)="followUser(friend, 'unfollow')"><i class="fas fa-rss"></i> UnFollow</a> -->
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                    </ng-container>
                </owl-carousel-o>
            </ng-container>

            <div class="list-group-item d-flex align-items-center" style="padding-left: 18px" *ngIf="(friends | async)?.length === 0">
                Friends not found
            </div>
        </div>
        <!-- <div *ngIf="loader | async" class="d-flex align-items-center justify-content-center">
            <mat-spinner color="primary" class="spinner-size"></mat-spinner>
        </div> -->
    </div>

</div> <!-- Trends ror you end -->

<div class="landing-content">
	<div class="container">
		<div class="row">
			<div class="col-lg-6 col-12 text-center">
				<h2>Create an Account</h2>
			</div>
		</div>
		<div class="row">
			<div class="col-lg-6 col-12">
				<app-register></app-register>
			</div>
			<div class="col-lg-6 col-12 text-center">
<!--                <iframe width="510" height="350" src="https://www.youtube.com/embed/GKcARBbvXFI?autoplay=1&loop=1&playlist=GKcARBbvXFI" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>-->

                <div [innerHTML]="youtubeVideo | safe: 'html'"></div>

			</div>
			<div class="col-12">
				<div class="text text-center">
					<p class="text-uppercase">THIS SOCIAL MEDIA SITE IS A NEUTRAL PLATFORM FOR ALL. DESIGNED WITH YOU IN MIND. IF YOU DON’T LIKE SOMETHING, BLOCK YOURSELF.</p>
					<p>Build your Business, Keep your Business. Build your Platform as long as you are following the policies.</p>
					<p>You have freedom of speech, not freedom to hate.</p>
					<p>{{gs.mainTitle}} is offering you more than just a Conversation. It’s a way to build a business.</p>
				</div>
			</div>
		</div>
	</div>
</div>

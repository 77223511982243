import { Action } from '@ngrx/store';
import { Post } from 'src/app/models/Post';

export enum HiddenPostTimelineTypes {
    FAILURE = '[HIDDENPOST_TIMELINE_FAILURE] failed',
    LIST_PARAMS = '[HIDDENPOST_TIMELINE_LIST_PARAMS] HIDDENPOST List Params',
    LIST = '[HIDDENPOST_TIMELINE_LIST] HIDDENPOST List',
    LIST_MORE = '[HIDDENPOST_TIMELINE_LIST_MORE] HIDDENPOST List More',
    ADD = '[HIDDENPOST_TIMELINE_ADD] HIDDENPOST Add',
    UPDATE = '[HIDDENPOST_TIMELINE_UPDATE] HIDDENPOST Update',
    DELETE = '[HIDDENPOST_TIMELINE_UPDATE] HIDDENPOST Detele',
}

export class ListParams implements Action {
    readonly type = HiddenPostTimelineTypes.LIST_PARAMS;
    constructor(public options: { params?: any, key?: string}) { }
} 

export class List implements Action {
    readonly type = HiddenPostTimelineTypes.LIST;
    constructor(public list: any) { }
}

export class More implements Action {
    readonly type = HiddenPostTimelineTypes.LIST_MORE;
    constructor(public more: any) { }
}

export class Add implements Action {
    readonly type = HiddenPostTimelineTypes.ADD;
    constructor(public item: Post) { }
}

export class Update implements Action {
    readonly type = HiddenPostTimelineTypes.UPDATE;
    constructor(public item: Post) { }
}

export class Delete implements Action {
    readonly type = HiddenPostTimelineTypes.DELETE;
    constructor(public item: Post) { }
}

export class failure implements Action {
    readonly type = HiddenPostTimelineTypes.FAILURE;
    constructor(public error: any) { }
}


export type HiddenPostTimelineAction = ListParams | List | More | Add | Update | Delete | failure;
<div class="card custom-card">
	<div class="card-body">
		<h3 class="card-title">
			<a [routerLink]="['/user/explore']">Explore</a>
		</h3>
		<ul class="nav flex-column explore-menu">
			<!-- <li><a [routerLink]="['/user/explore']" [routerLinkActive]="['active']"><i class="fas fa-calendar-alt"></i> Explore</a></li> -->
			<li><a [routerLink]="['/pages']" [routerLinkActive]="['active']"><i class="fas fa-plus-circle"></i> Pages</a></li>
			<li><a [routerLink]="['/groups']" [routerLinkActive]="['active']"><i class="fas fa-cubes"></i> Group</a></li>
			<li><a [routerLink]="['/events']" [routerLinkActive]="['active']"><i class="fas fa-calendar-alt"></i> Events</a></li>
			<li><a [routerLink]="['/user/save-post']" [routerLinkActive]="['active']"><i class="fas fa-bars"></i> Saved Post</a></li>
			<li><a [routerLink]="['/user/hidden-post']" [routerLinkActive]="['active']"><i class="fas fa-eye-slash"></i> Hidden Post</a></li>
			<li><a [routerLink]="['/user/hided-my-post']" [routerLinkActive]="['active']"><i class="fas fa-eye-slash"></i> Hided my post</a></li>
			<li><a [routerLink]="['/user/hide-all-post-user']" [routerLinkActive]="['active']"><i class="fas fa-eye-slash"></i> Hide all post of this user</a></li>
			<li><a [routerLink]="['/timeline/timeline-review']" [routerLinkActive]="['active']"><i class="fas fa-stream"></i> Timeline Review</a></li>
			<li><a [routerLink]="['/user/schedule-post']" [routerLinkActive]="['active']"><i class="fas fa-stream"></i> Schedule Post</a></li>
		</ul>
	</div>
</div> <!-- Explore List -->
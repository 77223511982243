<div class="message-page pt-0">
    <div class="user-info bg-primary text-white d-flex align-items-center">
        <div class="image pl-3">
            <img src="{{gs.identity.avatar}}" alt="" width="35" height="35" class="rounded-circle">
        </div>
        <div class="caption col d-flex align-items-center">
            <div class="left col pl-0">
                <h4>{{gs.identity.name}}</h4>
            </div>
        </div>
    </div>
    <div class="msg-list-box">
        <div class="right-scroll css-scroll" ngx-auto-scroll lock-y-offset="10" observe-attributes infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50" [scrollWindow]="false" (scrolled)="onScroll($event)" (scrolledUp)="scrolledUp($event)">
            <ng-container *ngIf="chatRequestLoading; else second">
                <ul class="user-list">
                    <li *ngFor="let item of gs.generateFake(3)" class="p-3">
                        <div class="d-flex align-items-center">
                            <div class="img mr-2" style="width: 80px;">
                                <ngx-skeleton-loader [theme]="{ height: '70px', width: '70px'}" appearance="circle">
                                </ngx-skeleton-loader>
                            </div>
                            <div class="caption">
                                <ngx-skeleton-loader count="3" [theme]="{ height: '10px', width: '100%'}"></ngx-skeleton-loader>
                            </div>
                        </div>
                    </li>
                </ul>
            </ng-container>
            <ng-template #second>
                <div *ngIf="chatLoading" class="text-center">
                    <div class="spinner-border text-primary"></div>
                </div>
                <ul class="msg-list">
                    <li *ngFor="let item of chatList.slice().reverse()" [ngClass]="item.user_id !== gs.user.id ? 'youmsg' : 'mymsg'">
                        <div class="pic">
                            <img src="{{item?.user?.avatar}}" alt="" width="40" height="40" class="border rounded-circle">
                            <small class="text-truncate">{{item?.user?.name}}</small>
                        </div>
                        <div class="msg-text border d-flex flex-wrap" matTooltip="{{item.created_at | date:'mediumTime' }}" [matTooltipPosition]="item.user_id !== gs.user.id ? 'right' : 'left'">
                            <div class="text"> {{item?.message}} </div>
                        </div>
                    </li>
                    <li *ngIf="chatList.length === 0" class="justify-content-center">
                        <div class="text"> Conversation not start </div>
                    </li>
                </ul>
            </ng-template>
            
        </div>
        <div class="comment-form border-top">
            <form name="form" (ngSubmit)="addComment(form)" [formGroup]="form" novalidate>
                <div class="group d-flex align-items-center">
                    <div class="text col px-2 py-1 border-left border-right">
                        <mat-form-field>
                            <textarea matInput placeholder="Type a Message" name="text" maxlength="350" #posttext formControlName="message"></textarea>
                        </mat-form-field>
                    </div>
                    <div class="botton" (clickOutside)="show = false">
                        <button mat-raised-button color="primary" class="mb-1" [disabled]="!form.valid" [ladda]="submitted">Send</button> <br>
                        <a mat-stroked-button color="primary" (click)="toggle()"><i class="far fa-smile"></i></a>
                        <ng-container *ngIf="show">
                            <emoji-mart  (emojiClick)="addEmojiPost($event)" title="Pick your emoji" [showPreview]="false" [emojiTooltip]="true" set="facebook" [style]="{ position: 'absolute', bottom: '45px', right: '4px' }"></emoji-mart>
                        </ng-container>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
<div class="card custom-card">
	<div class="card-body">
		<h3 class="card-title">Trends for you</h3>
		<ul class="nav flex-column trends-list">
			<li *ngFor="let trend of trends | async">
				<h6><a routerLink="." (click)="viewTrans(trend.tag)">#{{trend.tag}}</a></h6>
				<p>{{trend.trend_use_num}} Post{{(trend.trend_use_num > 2) ? 's' : ''}}</p>
			</li>
		</ul>
		<ng-container *ngIf="paginatio | async as pageCount">
			<div class="view-more" *ngIf="( pageCount.pageCount > page )" [ngClass]="{'d-none': hide == true}">
				<a (click)="pagination()" class="btn btn-link pl-0">Load More</a>
			</div>
		</ng-container>
		<ng-container *ngIf="trends | async as lengthCount">
			<div class="w-100" *ngIf="lengthCount.length === 0">
				Trends not found
			</div>
		</ng-container>
		<div *ngIf="loader | async" class="d-flex align-items-center justify-content-center">
			<mat-spinner color="primary" class="spinner-size"></mat-spinner>
		</div>
	</div>
</div> <!-- Trends ror you end -->
import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from '../auth/services/auth.service';


@Injectable()
export class AuthJwtInterceptor implements HttpInterceptor {

    isLoggedIn$: Observable<boolean>;

    constructor(private authService: AuthService) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        //console.log('req:::::', req.headers.has("LiveStream"));

        if (!req.headers.has("LiveStream")) {

            if (!req.headers.has("Content-Type")) {
                let reqHeaders = req.headers;
                reqHeaders.set('Content-Type', 'multipart/form-data');
                reqHeaders.set('Accept', 'application/json');
                req = req.clone({
                    headers: reqHeaders
                });
            }

            this.isLoggedIn$ = this.authService.authenticationState;

            this.isLoggedIn$.pipe().subscribe((data: any) => {
                if (data) {
                    const authToken = this.authService.getAuthorizationToken();
                    if (authToken) {
                        req = req.clone({
                            setHeaders: {
                                Authorization: `Bearer ${authToken}`,
                                'WWW-Authenticate': 'Bearer realm="api"',
                            },
                        });
                    }
                }
            });
        }

        if (req.headers.has("LiveStream")) {
            let reqHeaders = req.headers;
            reqHeaders.delete('LiveStream', 'LiveStream');
            req = req.clone({
                headers: reqHeaders
            });
        }

        return next.handle(req);
    }
}
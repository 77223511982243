import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
	selector: 'app-edit-post',
	templateUrl: './edit-post.component.html',
	styleUrls: ['./edit-post.component.scss']
})
export class EditPostComponent implements OnInit {

	isUpdate = true;
	constructor(
		private dialogRef: MatDialogRef<EditPostComponent>, @Inject(MAT_DIALOG_DATA) public data
	) { }

	ngOnInit() {
		//console.log(this.data);
	}

	closeDialog() {
		this.dialogRef.close()
	}
}

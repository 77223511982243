import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { GlobalService } from 'src/app/helpers/global.service';
import { CommonService } from 'src/app/service/common.service';
import { AuthActions } from 'src/app/store/action';

@Component({
	selector: 'app-otp-verification',
	templateUrl: './otp-verification.component.html',
	styleUrls: ['./otp-verification.component.css']
})
export class OtpVerificationComponent implements OnInit {

	title = 'Otp Verification';
	forgot: FormGroup;
	submitted = false;
	errors = [];

	constructor(
		private fb: FormBuilder,
		private router: Router,
		private commonService: CommonService,
		public gs: GlobalService,
		private titleService: Title,
		private route: ActivatedRoute,
		private store: Store<{}>
	) { }

	get f() { return this.forgot.controls; }

	ngOnInit() {
		this.titleService.setTitle(this.gs.mainTitle + ' | ' + this.title);
		this.createForgotForm();
	}

	onForgot(forgot) {
		this.submitted = true;
		if (!forgot.valid) { return false; }
		this.gs.clearErrorMessages();
		this.commonService.otpVerification(forgot.value.otp).subscribe((data: any) => {
			console.log('datadata', data);
			if (data.token) {
				localStorage.setItem('token', data.token);
				this.store.dispatch(new AuthActions.loginSuccess({ user: data.data }));
				window.location.href = '/user/setting';
				this.gs.alert('Success!.');
			} else {
				this.gs.alert(data.message, 'bg-danger');
			}
		}, (error: Response) => {
			this.gs.handleErrors(error);
			this.submitted = false;
		});
	}

	createForgotForm() {
		this.forgot = this.fb.group({
			otp: new FormControl('', [Validators.required]),
		});
	}

}

import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as pageReducer from '../reducer/page.reducer';

// Lookup the 'Joke' feature state managed by NgRx
const getState = createFeatureSelector<pageReducer.State>(
    pageReducer.featureKey
);

export const mayLikes = createSelector(
    getState,
    state => state.mayLikes
);

export const error = createSelector(
    getState,
    state => state.error
);

export const loading = createSelector(
    getState,
    state => state.loading
);
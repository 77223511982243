import { NgModule, Injector } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { createCustomElement } from '@angular/elements';
import { DashboardLayoutComponent } from './dashboard-layout/dashboard-layout.component';
import { UserLayoutComponent } from './user-layout/user-layout.component';
import { UserSettingComponent } from './components/user-setting/user-setting.component';
import { TimelineMenuComponent } from './components/timeline-menu/timeline-menu.component';
import { UserBannerComponent } from './components/user-banner/user-banner.component';
import { ExploreMenuComponent } from './components/explore-menu/explore-menu.component';
import { UserProfileComponent } from './components/user-profile/user-profile.component';
import { TrendsForYouComponent } from './components/trends-for-you/trends-for-you.component';
import { FriendSuggestionComponent } from './components/friend-suggestion/friend-suggestion.component';
import { PageYouLikeComponent } from './components/page-you-like/page-you-like.component';
import { LandingNavbarComponent } from './components/landing-navbar/landing-navbar.component';
import { UserProfileAbsoluteComponent } from './components/user-profile-absolute/user-profile-absolute.component';
import { MatNativeDateModule } from '@angular/material/core';
import { CarouselModule } from "ngx-owl-carousel-o";
import { ImageCropperModule } from 'ngx-image-cropper';
import { MaterialModule } from '../material-module';
import { SearchPipe } from 'src/app/helpers/search-pipe';
import { SafePipe } from '../helpers/safe.pipe';
import { FriendLayoutComponent } from './friend-layout/friend-layout.component';
import { ImgLazyComponent } from './components/img-lazy/img-lazy.component';
import { LoadingComponent } from './components/loading/loading.component';
import { ImageCropperComponent } from './components/image-cropper/image-cropper.component';
import { StreamLayoutComponent } from './stream-layout/stream-layout.component';
import { AdvertisementComponent } from './components/advertisement/advertisement.component';

const routes: Routes = [];

@NgModule({
	imports: [
		CommonModule,
		MatNativeDateModule,
		MaterialModule,
		CarouselModule,
		ImageCropperModule,
		RouterModule.forChild(routes),
	],
	declarations: [DashboardLayoutComponent, UserLayoutComponent, UserSettingComponent, TimelineMenuComponent, UserBannerComponent, ExploreMenuComponent, UserProfileComponent, TrendsForYouComponent, FriendSuggestionComponent, PageYouLikeComponent, LandingNavbarComponent, UserProfileAbsoluteComponent, SearchPipe, SafePipe, FriendLayoutComponent, ImgLazyComponent, LoadingComponent, ImageCropperComponent, StreamLayoutComponent, AdvertisementComponent],
	exports: [LandingNavbarComponent, DashboardLayoutComponent, UserLayoutComponent, FriendLayoutComponent, StreamLayoutComponent, TrendsForYouComponent, LoadingComponent, PageYouLikeComponent, SearchPipe, SafePipe, ImgLazyComponent, UserProfileComponent, ExploreMenuComponent
	],
	providers: [
	],
	entryComponents: [ImgLazyComponent, ImageCropperComponent]
})
export class ViewModule {

	constructor(private injector: Injector) { }

	ngDoBootstrap() {
		const elements: any[] = [
			[ImgLazyComponent, 'img-lazy']
		];

		for (const [component, name] of elements) {
			const el:any = createCustomElement(component, { injector: this.injector });
			customElements.define(name, el);
		}
	}

}

<mat-dialog-content>
    <div class="row">
        <div class="col-12">
            Your Email Id is Unverified. Please click the Resend button to receive the Verification Email again.
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-button (click)="closeDialog()">No</button>
    <button mat-raised-button color="primary" [ladda]="submitted" (click)="submit()">Resend</button>
</mat-dialog-actions>

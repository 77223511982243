import { Component, OnInit } from '@angular/core';
import { GlobalService } from 'src/app/helpers/global.service';
import { Post } from 'src/app/models';
import { DefaultService } from 'src/app/service/default.service';
import { ItemService } from 'src/app/service/item.service';

@Component({
	selector: 'app-news',
	templateUrl: './news.component.html',
	styleUrls: ['./news.component.scss']
})

export class NewsComponent implements OnInit {
	loading: boolean = false;
	allpost: Post[] = [];
	currentVideo: Post = null;
	page = 1;

	constructor(
		private gs: GlobalService,
		private defaultService: DefaultService,
		private itemService: ItemService
	) {

	}
	
	ngOnInit() {
		this.gs.setTitle('Home');
		this.onLoad();
		/*this.defaultService.item("GET", null, {category_id: 6}).subscribe((data: any) => {
			console.log(data);
		}, (error: Response) => {
			this.gs.handleErrors(error);
		});*/

	}

	onLoad() {
		this.loading = true;
		this.itemService.post("GET", null, { key: 'post-videos', page: this.page }).subscribe(response => {
			this.loading = false;
			let data: any = this.gs.apiResponce(response);
			this.allpost = data.data ? data.data : [];
			this.currentVideo = data.data ? data.data[0] : null;
		}, (error: Response) => {
			this.loading = false;
			this.gs.handleErrors(error);
		});
	}

}

import { Action } from '@ngrx/store';
import { Notifications } from 'src/app/models/Notifications';
import { Pagination } from 'src/app/models/Pagination';
import { User } from 'src/app/models/User';

export enum NotificationsActionTypes {
    LIST_INITIALIZED = '[NOTIFICATIONS_LIST] Notifications List Initialized',
    LIST = '[NOTIFICATIONS_LIST] Notifications List',
    MORE = '[NOTIFICATIONS_MORE] Notifications More',
    MORE_INITIALIZED = '[NOTIFICATIONS_MORE_INITIALIZED] Notifications More Initialized',
    ADD = '[NOTIFICATIONS_ADD] Notifications Add',
    ADD_INITIALIZED = '[NOTIFICATIONS_ADD_INITIALIZED] Notifications Add Initialized',
    UPDATE = '[NOTIFICATIONS_UPDATE] Notifications Update',
    UPDATE_INITIALIZED = '[NOTIFICATIONS_UPDATE_INITIALIZED] Notifications Update Initialized',
    DELETE = '[NOTIFICATIONS_UPDATE] Notifications Detele',
    DELETE_INITIALIZED = '[NOTIFICATIONS_DELETE_INITIALIZED] Notifications Detele Initialized',
    REMOVE_COUNT = '[NOTIFICATIONS_REMOVE_COUNT] Notifications Remove Count',
    NOTIFICATIONS_FAILURE = '[NOTIFICATIONS_FAILURE] Notifications failed',
}

export class ListInitialized implements Action {
    readonly type = NotificationsActionTypes.LIST_INITIALIZED;
    constructor(public options: { method: string, params?: any, params2?: any, key?: string }) { }
}

export class List implements Action {
    readonly type = NotificationsActionTypes.LIST;
    constructor(public payload: { items: Notifications[], pagination: Pagination }) { }
}

export class More implements Action {
    readonly type = NotificationsActionTypes.MORE;
    constructor(public list: any) { }
}

export class MoreInitialized implements Action {
    readonly type = NotificationsActionTypes.MORE_INITIALIZED;
    constructor(public params: any) { }
}

export class Add implements Action {
    readonly type = NotificationsActionTypes.ADD;
    constructor(public item: Notifications) { }
}

export class AddInitialized implements Action {
    readonly type = NotificationsActionTypes.ADD_INITIALIZED;
    constructor(public params: any) { }
}

export class Update implements Action {
    readonly type = NotificationsActionTypes.UPDATE;
    constructor(public item: Notifications) { }
}

export class UpdateInitialized implements Action {
    readonly type = NotificationsActionTypes.UPDATE_INITIALIZED;
    constructor(public params: any) { }
}

export class Delete implements Action {
    readonly type = NotificationsActionTypes.DELETE;
    constructor(public item: Notifications) { }
}

export class DeleteInitialized implements Action {
    readonly type = NotificationsActionTypes.DELETE_INITIALIZED;
    constructor(public params: any) { }
}

export class RemoveCount implements Action {
    readonly type = NotificationsActionTypes.REMOVE_COUNT;
    constructor(public data: User) { }
}

export class failure implements Action {
    readonly type = NotificationsActionTypes.NOTIFICATIONS_FAILURE;
    constructor(public error: any) { }
}


export type NotificationsAction = ListInitialized | List | More | MoreInitialized | Add | AddInitialized | Update | UpdateInitialized | Delete | DeleteInitialized | RemoveCount | failure;
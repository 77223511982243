import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { GlobalService } from 'src/app/helpers/global.service';
import { ItemService } from 'src/app/service/item.service';
import { CommunityTimelineAction, EventTimelineAction, ExploreTimelineAction, FeedTimelineAction, GroupTimelineAction, HiddenPostTimelineAction, HideAllPostTimelineAction, HidedMyPostTimelineAction, PageTimelineAction, SchedulePostTimelineAction, UserTimelineAction } from '../action';

@Injectable()
export class TimelineEffects {
    constructor(
        private actions$: Actions,
        private itemService: ItemService,
        private gs: GlobalService
    ) { }

    feedsTimeline$ = createEffect(() => this.actions$.pipe(
        ofType(FeedTimelineAction.FeedTimelineTypes.FEED_LIST_PARAMS),
        mergeMap((options: FeedTimelineAction.ListParams) =>
            this.itemService.post("GET", null, options.options.params).pipe(map((item: any) => {
                //console.log('itemitemitemitem', item.data ? item.data : []);
                if (options.options.key === 'more') {
                    return new FeedTimelineAction.More(item.data ? item.data : []);
                } else {
                    return new FeedTimelineAction.List(item.data ? item.data : []);
                }
            }),
                catchError((error) => {
                    this.gs.handleErrors(error);
                    return [new FeedTimelineAction.failure(error)]
                })
            )
        )
    ));

    exploreTimeline$ = createEffect(() => this.actions$.pipe(
        ofType(ExploreTimelineAction.ExploreTimelineTypes.LIST_PARAMS),
        mergeMap((options: ExploreTimelineAction.ListParams) =>
            this.itemService.post("GET", null, options.options.params).pipe(map((item: any) => {
                if (options.options.key === 'more') {
                    return new ExploreTimelineAction.More(item.data ? item.data : []);
                } else {
                    return new ExploreTimelineAction.List(item.data ? item.data : []);
                }
            }),
                catchError((error) => {
                    this.gs.handleErrors(error);
                    return [new ExploreTimelineAction.failure(error)]
                })
            )
        )
    ));

    // 3534

    userTimeline$ = createEffect(() => this.actions$.pipe(
        ofType(UserTimelineAction.UserTimelineTypes.LIST_PARAMS),
        mergeMap((options: UserTimelineAction.ListParams) =>
            this.itemService.post("GET", null, options.options.params).pipe(map((item: any) => {
                if (options.options.key === 'more') {
                    return new UserTimelineAction.More(item.data ? item.data : []);
                } else {
                    return new UserTimelineAction.List(item.data ? item.data : []);
                }
            }),
                catchError((error) => {
                    this.gs.handleErrors(error);
                    return [new UserTimelineAction.failure(error)]
                })
            )
        )
    ));

    groupTimeline$ = createEffect(() => this.actions$.pipe(
        ofType(GroupTimelineAction.GroupTimelineTypes.LIST_PARAMS),
        mergeMap((options: GroupTimelineAction.ListParams) =>
            this.itemService.post("GET", null, options.options.params).pipe(map((item: any) => {
                if (options.options.key === 'more') {
                    return new GroupTimelineAction.More(item.data ? item.data : []);
                } else {
                    return new GroupTimelineAction.List(item.data ? item.data : []);
                }
            }),
                catchError((error) => {
                    this.gs.handleErrors(error);
                    return [new GroupTimelineAction.failure(error)]
                })
            )
        )
    ));

    pageTimeline$ = createEffect(() => this.actions$.pipe(
        ofType(PageTimelineAction.PageTimelineTypes.LIST_PARAMS),
        mergeMap((options: PageTimelineAction.ListParams) =>
            this.itemService.post("GET", null, options.options.params).pipe(map((item: any) => {
                if (options.options.key === 'more') {
                    return new PageTimelineAction.More(item.data ? item.data : []);
                } else {
                    return new PageTimelineAction.List(item.data ? item.data : []);
                }
            }),
                catchError((error) => {
                    this.gs.handleErrors(error);
                    return [new PageTimelineAction.failure(error)]
                })
            )
        )
    ));

    eventTimeline$ = createEffect(() => this.actions$.pipe(
        ofType(EventTimelineAction.EventTimelineTypes.LIST_PARAMS),
        mergeMap((options: EventTimelineAction.ListParams) =>
            this.itemService.post("GET", null, options.options.params).pipe(map((item: any) => {
                if (options.options.key === 'more') {
                    return new EventTimelineAction.More(item.data ? item.data : []);
                } else {
                    return new EventTimelineAction.List(item.data ? item.data : []);
                }
            }),
                catchError((error) => {
                    this.gs.handleErrors(error);
                    return [new EventTimelineAction.failure(error)]
                })
            )
        )
    ));

    communityTimeline$ = createEffect(() => this.actions$.pipe(
        ofType(CommunityTimelineAction.CommunityTimelineTypes.LIST_PARAMS),
        mergeMap((options: CommunityTimelineAction.ListParams) =>
            this.itemService.post("GET", null, options.options.params).pipe(map((item: any) => {
                if (options.options.key === 'more') {
                    return new CommunityTimelineAction.More(item.data ? item.data : []);
                } else {
                    return new CommunityTimelineAction.List(item.data ? item.data : []);
                }
            }),
                catchError((error) => {
                    this.gs.handleErrors(error);
                    return [new CommunityTimelineAction.failure(error)]
                })
            )
        )
    ));

    HiddenPostTimeline$ = createEffect(() => this.actions$.pipe(
        ofType(HiddenPostTimelineAction.HiddenPostTimelineTypes.LIST_PARAMS),
        mergeMap((options: HiddenPostTimelineAction.ListParams) =>
            this.itemService.post("GET", null, options.options.params).pipe(map((item: any) => {
                if (options.options.key === 'more') {
                    return new HiddenPostTimelineAction.More(item.data ? item.data : []);
                } else {
                    return new HiddenPostTimelineAction.List(item.data ? item.data : []);
                }
            }),
                catchError((error) => {
                    this.gs.handleErrors(error);
                    return [new HiddenPostTimelineAction.failure(error)]
                })
            )
        )
    ));

    HidedMyPostTimeline$ = createEffect(() => this.actions$.pipe(
        ofType(HidedMyPostTimelineAction.HidedMyPostTimelineTypes.LIST_PARAMS),
        mergeMap((options: HidedMyPostTimelineAction.ListParams) =>
            this.itemService.post("GET", null, options.options.params).pipe(map((item: any) => {
                if (options.options.key === 'more') {
                    return new HidedMyPostTimelineAction.More(item.data ? item.data : []);
                } else {
                    return new HidedMyPostTimelineAction.List(item.data ? item.data : []);
                }
            }),
                catchError((error) => {
                    this.gs.handleErrors(error);
                    return [new HidedMyPostTimelineAction.failure(error)]
                })
            )
        )
    ));

    HideAllPostTimeline$ = createEffect(() => this.actions$.pipe(
        ofType(HideAllPostTimelineAction.HideAllPostTimelineTypes.LIST_PARAMS),
        mergeMap((options: HideAllPostTimelineAction.ListParams) =>
            this.itemService.post("GET", null, options.options.params).pipe(map((item: any) => {
                if (options.options.key === 'more') {
                    return new HideAllPostTimelineAction.More(item.data ? item.data : []);
                } else {
                    return new HideAllPostTimelineAction.List(item.data ? item.data : []);
                }
            }),
                catchError((error) => {
                    this.gs.handleErrors(error);
                    return [new HideAllPostTimelineAction.failure(error)]
                })
            )
        )
    ));

    SchedulePostTimeline$ = createEffect(() => this.actions$.pipe(
        ofType(SchedulePostTimelineAction.SchedulePostTimelineTypes.LIST_PARAMS),
        mergeMap((options: SchedulePostTimelineAction.ListParams) =>
            this.itemService.post("GET", null, options.options.params).pipe(map((item: any) => {
                if (options.options.key === 'more') {
                    return new SchedulePostTimelineAction.More(item.data ? item.data : []);
                } else {
                    return new SchedulePostTimelineAction.List(item.data ? item.data : []);
                }
            }),
                catchError((error) => {
                    this.gs.handleErrors(error);
                    return [new SchedulePostTimelineAction.failure(error)]
                })
            )
        )
    ));

}
import {Component, OnInit} from '@angular/core';
import {AuthService} from 'src/app/auth/services/auth.service';
import {Router} from '@angular/router';
import {GlobalService} from 'src/app/helpers/global.service';
import {CommonService} from "../../service/common.service";

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
    youtubeVideo = null;

    constructor(
        private authService: AuthService,
        private router: Router,
        public gs: GlobalService,
        private commonService: CommonService
    ) {
    }

    ngOnInit() {
        if (this.authService.isAuthenticated()) {
            return this.router.navigate(['/timeline']);
        }
        this.gs.setTitle('Home');
        this.commonService.config("GET", null, { key: 'youtube_embed_code' }).subscribe((data: any) => {
            this.youtubeVideo = data.data.val;
        }, (error: Response) => {
            this.gs.handleErrors(error);
        });
    }
}

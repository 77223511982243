<nav class="landing-navbar">
    <ul class="nav nav-pills justify-content-center">
		<li class="nav-item">
            <a class="nav-link" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{ exact: true }" [routerLink]="['/']">Home</a>
		</li>
		<li class="nav-item">
            <a class="nav-link" [routerLinkActive]="['active']" [routerLink]="['/videos/list']">Videos</a>
		</li>
        <li class="nav-item">
            <a class="nav-link" href="https://www.diamondandsilkinc.com/tabletalknews" target="_blank">News</a>
		</li>
		<li class="nav-item" *ngIf="isLoggedIn$ | async">
            <a class="nav-link" [routerLinkActive]="['active']" [routerLink]="['/user/explore']">Explore</a>
		</li>
		<li class="nav-item" *ngIf="!(isLoggedIn$ | async)">
            <a class="nav-link" (click)="openAlert()" routerLink=".">Explore</a>
        </li>
		<li class="nav-item" *ngIf="!(isLoggedIn$ | async)">
            <a class="nav-link" [routerLinkActive]="['active']" routerLink="/register">Register</a>
        </li>
        <!--<li class="nav-item">
            <a class="nav-link" [routerLinkActive]="['active']" [routerLink]="['/coming-soon/groups']">Groups</a>
        </li>
        <li class="nav-item">
            <a class="nav-link" [routerLinkActive]="['active']" [routerLink]="['/coming-soon/pages']">Pages</a>
        </li>
        <li class="nav-item">
            <a class="nav-link" [routerLinkActive]="['active']" [routerLink]="['/coming-soon/events']">Events</a>
        </li>
        <li class="nav-item">
            <a class="nav-link" [routerLinkActive]="['active']" [routerLink]="['/coming-soon/blogs']">Blogs</a>
        </li>-->
    </ul>
</nav>

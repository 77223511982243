import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { GlobalService } from 'src/app/helpers/global.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CommonService } from 'src/app/service/common.service';
import { ItemService } from 'src/app/service/item.service';

@Component({
	selector: 'app-add-photo',
	templateUrl: './add-photo.component.html',
	styleUrls: ['./add-photo.component.scss']
})
export class AddPhotoComponent implements OnInit {

	form: FormGroup;
	submitted = false;
	heading = 'Upload Photos/Videos';
	privacy = "1";
	loading = false;
	progress: number;
	image = [];
	imageView = [];
	constructor(
		private formBuilder: FormBuilder,
		public gs: GlobalService,
		public commonService: CommonService,
		private itemService: ItemService,
		private dialogRef: MatDialogRef<AddPhotoComponent>,
		@Inject(MAT_DIALOG_DATA) public data
	) { }

	ngOnInit() {
		console.log(this.data);
		this.form = this.formBuilder.group({
			item: new FormControl(this.data.id, [Validators.required]),
			privacy: new FormControl(this.privacy, [Validators.required]),
			module: new FormControl(this.data.module, [Validators.required]),
			files: new FormControl(this.image, [Validators.required])
		});
	}

	submit(form) {
		this.submitted = true;
		if (!form.valid) {
			this.submitted = false;
			return false;
		}

		form.value.friends = (form.value.privacy === '4' || form.value.privacy === '5') ? form.value.friends : [null];
		this.itemService.media(form.value).subscribe((data: any) => {
			//console.log(data);
			this.gs.alert(this.data.videoMsg ? this.data.videoMsg : data.message);
			this.commonService.setAction(this.data.updatePage);
			this.dialogRef.close(data.model);
		}, (error: Response) => {
			this.loading = false;
		});
	}

	upload(files: FileList) {
		this.loading = true;
		this.commonService.basicUpload(files).subscribe(event => {
			this.loading = false;
			if (event.files !== undefined && event.files.length > 0) {
				let dd = event.files;
				dd.forEach(element => {
					this.image.push(element.id);
					this.imageView.push(element);
				});
				this.form.patchValue({
					files: this.image
				});
			}
		}, (error: Response) => {
			this.loading = false;
		});
	}

	deletePhoto(photo) {
		this.imageView.splice(this.imageView.indexOf(photo), 1);
		this.image.splice(this.image.indexOf(photo.id), 1);
		this.form.patchValue({
			files: this.image
		});
	}

	closeDialog() {
		this.dialogRef.close(this.commonService.setAction(this.data.updatePage));
	}

}

<div class="inner-page dashboard-page">
	<div class="container">
		<div class="row">
			<div class="col-lg-3 col-md-5 col-12">
				<app-user-profile></app-user-profile> <!-- Card profile end -->

				<app-explore-menu></app-explore-menu> <!-- Explore Menu -->

				<app-trends-for-you></app-trends-for-you> <!-- Trends ror you end -->
			</div>
			<div class="col-lg-6 col-md-7 col-12">
				<router-outlet></router-outlet>
			</div>
			<div class="col-lg-3 col-12">
				<!-- <app-advertisement></app-advertisement> -->

				<!-- <div *ngIf="advertisements">
					<owl-carousel-o [options]="advertisementtOptions">

						<ng-container *ngFor="let slide of advertisements">
							<ng-template carouselSlide [id]="slide.id">
								<div class="ad-box bg-white p-3">
									<a [href]="slide && slide.advertisement_link" target="_blank">
										<img [src]="slide && slide.advertisement_image && slide.advertisement_image.thumb"
											alt="" class="img-fluid w-100 mb-2">
										<div class="text-center">
											<a [href]="slide && slide.advertisement_link" target="_blank"
												class="btn btn-primary btn-post">Buy Now</a>
										</div>
									</a>
								</div>
							</ng-template>
						</ng-container>

					</owl-carousel-o>
				</div> -->

				<app-advertisement-view></app-advertisement-view>

				<app-friend-suggestion></app-friend-suggestion> <!-- Friend suggestion end -->

				<app-page-you-like></app-page-you-like> <!-- Page like end -->


				<!--[adSlot]="2930227358" -->

			</div>
		</div>
	</div>
</div>
<div class="card custom-card setting-menu">
	<div class="card-body">
		<h3 class="card-title">General</h3>
		<ul class="nav flex-column explore-menu">
			<li><a [routerLink]="['/user/setting']" routerLinkActive="active"><i class="fas fa-user-cog"></i> General</a></li>
			<li><a [routerLink]="['/user/setting/privacy']" routerLinkActive="active"><i class="fas fa-lock"></i> Privacy</a></li>
			<li><a [routerLink]="['/user/setting/newsfeed-tagging']" routerLinkActive="active"><i class="fas fa-newspaper"></i> Newsfeed & Tagging</a></li>
			<li><a [routerLink]="['/user/setting/notification']" routerLinkActive="active"><i class="fas fa-bell"></i> Notifications</a></li>
			<li><a [routerLink]="['/user/setting/block-setting']" routerLinkActive="active"><i class="fas fa-ban"></i> Block Settings</a></li>
			<li><a [routerLink]="['/user/setting/theme']" routerLinkActive="active"><i class="fas fa-mask"></i> Customized Theme</a></li>
			<li><a [routerLink]="['/user/setting/change-password']" routerLinkActive="active"><i class="fas fa-user"></i> Change Password</a></li>
			<li><a [routerLink]="['/user/setting/deactivate-account']" routerLinkActive="active"><i class="fas fa-user-slash"></i> Deactivate Account</a></li>
		</ul>
	</div>
</div> <!-- User Setting Menu List -->
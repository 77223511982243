import { Action } from '@ngrx/store';

export enum ItemActionTypes {
    ITEM_STAFF_PARAMS = '[ITEM_STAFF_PARAMS] Staff Params',
    ITEM_FAILURE = '[ITEM_FAILURE] failed',
}

export class params implements Action {
    readonly type = ItemActionTypes.ITEM_STAFF_PARAMS;
    constructor(public params: any) { }
}

export class failure implements Action {
    readonly type = ItemActionTypes.ITEM_FAILURE;
    constructor(public error: any) { }
}

export type ItemAction = params | failure;
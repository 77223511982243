import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { GlobalService } from 'src/app/helpers/global.service';
import { DefaultService } from 'src/app/service/default.service';
import { ItemService } from 'src/app/service/item.service';
import { DefaultAction } from '../action';

@Injectable()
export class DefaultEffects {
    constructor(
        private actions$: Actions,
        private defaultService: DefaultService,
        private itemService: ItemService,
        private gs: GlobalService
    ) { }

    trends$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DefaultAction.DefaultActionTypes.DEFAULT_TRENDS_PARAMS),
            mergeMap((options: DefaultAction.TrendsParams) =>
                this.itemService.post("GET", null, options.options.params).pipe(
                    map((item: any) => {
                        if(options.options.key === 'more') {
                            return new DefaultAction.trendMore(item.data.items);
                        } else {
                            return new DefaultAction.trends(item.data);
                        }
                    }),
                    catchError((error) => {
                        this.gs.handleErrors(error);
                        return [new DefaultAction.failure(error)]
                    })
                )
            )
        )
    );

    advertisement$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DefaultAction.DefaultActionTypes.DEFAULT_ADVERTISEMENT_PARAMS),
            mergeMap((options: DefaultAction.AdvertisementParams) =>
                this.defaultService.item("GET", null, options.options.params).pipe(
                    map((item: any) => {
                        return new DefaultAction.Advertisement(item.data);
                    }),
                    catchError((error) => {
                        this.gs.handleErrors(error);
                        return [new DefaultAction.failure(error)]
                    })
                )
            )
        )
    );

    announcement$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DefaultAction.DefaultActionTypes.DEFAULT_ANNOUNCEMENT_PARAMS),
            mergeMap((options: DefaultAction.AnnouncementParams) =>
                this.defaultService.item("GET", null, options.options.params).pipe(
                    map((item: any) => {
                        return new DefaultAction.Announcement(item.data);
                    }),
                    catchError((error) => {
                        this.gs.handleErrors(error);
                        return [new DefaultAction.failure(error)]
                    })
                )
            )
        )
    );

    reactions$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DefaultAction.DefaultActionTypes.DEFAULT_REACTIONS_PARAMS),
            mergeMap((options: DefaultAction.ReactionsParams) =>
                this.defaultService.reactions().pipe(
                    map((item: any) => {
                        return new DefaultAction.ReactionsList(item.data);
                    }),
                    catchError((error) => {
                        this.gs.handleErrors(error);
                        return [new DefaultAction.failure(error)]
                    })
                )
            )
        )
    );
    

}
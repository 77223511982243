import { Item } from 'src/app/models/Item';
import { Pagination } from 'src/app/models/Pagination';
import { Trends } from 'src/app/models/Trends';
import { DefaultActionTypes, DefaultAction } from '../action/default.action'; 


export const featureKey = 'default';

export interface State {
	trends: Trends[],
	trendPagination: Pagination,
	trendLoading: boolean,
	advertisement: Item[],
	announcement: Item[],
	reactions: any,
	loading: boolean,
	error: any
}

export const initialState: State = {
	trends: null,
	trendPagination: null,
	trendLoading: false,
	advertisement: null,
	announcement: null,
	reactions: null,
	loading: false,
	error: ''
};

export function reducer(state = initialState, action: DefaultAction): State {

	switch (action.type) {
		case DefaultActionTypes.DEFAULT_TRENDS_PARAMS:
			return {
				...state,
				trendLoading: true,
				error: ''
			}
		case DefaultActionTypes.DEFAULT_ADVERTISEMENT_PARAMS:
		case DefaultActionTypes.DEFAULT_ANNOUNCEMENT_PARAMS:
		case DefaultActionTypes.DEFAULT_REACTIONS_PARAMS:
			return {
				...state,
				loading: true,
				error: ''
			}
		case DefaultActionTypes.DEFAULT_TRENDS:
			const items = action.trends?.items;
			const pagination = action.trends?.pagination;
			return {
				...state,
				trendPagination: pagination,
				trends: items,
				trendLoading: false,
			}
		case DefaultActionTypes.DEFAULT_TRENDS_MORE:
			return {
				...state,
				trends: [...state.trends.concat(action.moreList)],
				trendLoading: false,
			}
		case DefaultActionTypes.DEFAULT_ADVERTISEMENT:
			return {
				...state,
				advertisement: action.advertisement,
				loading: false
			}	
		case DefaultActionTypes.DEFAULT_ANNOUNCEMENT:
			return {
				...state,
				announcement: action.announcement,
				loading: false
			}	
		case DefaultActionTypes.DEFAULT_REACTIONS:
			return {
				...state,
				reactions: action.reactions,
				loading: false
			}	
        case DefaultActionTypes.DEFAULT_FAILURE:
			return {
				...state,
				loading: false,
                error: action.error,
			}
		default:
			return state;
	}
};
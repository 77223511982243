import { Pagination } from 'src/app/models/Pagination';
import { Group } from 'src/app/modules/group/models/Group';
import { GroupMember } from 'src/app/modules/group/models/GroupMember';
import { GroupAction, GroupActionTypes } from '../action/group.actions';

export const featureKey = 'group';

export interface State {
    view: Group,
    members: GroupMember[]
    memberLoading: boolean,
    memberPagination: Pagination,
    moderators: GroupMember[]
    moderatorLoading: boolean,
    moderatorPagination: Pagination,
    admins: GroupMember[]
	adminLoading: boolean,
	adminPagination: Pagination,
	loading: boolean,
	error: any
}

export const initialState: State = {
    view: null,
    members: null,
    memberPagination: null,
	memberLoading: false,
    moderators: null,
    moderatorPagination: null,
	moderatorLoading: false,
    admins: null,
    adminPagination: null,
	adminLoading: false,
	loading: false,
	error: ''
};

export function reducer(state = initialState, action: GroupAction): State {

	switch (action.type) {
        case GroupActionTypes.GROUP_VIEW_PARAMS:
            return {
                ...state,
                loading: true,
                error: ''
            }
        case GroupActionTypes.GROUP_MEMBERS_LIST_PARAMS:
            return {
                ...state,
                memberLoading: true,
                error: ''
            }
        case GroupActionTypes.GROUP_MODERATORS_LIST_PARAMS:
            return {
                ...state,
                moderatorLoading: true,
                error: ''
            }
        case GroupActionTypes.GROUP_ADMIN_LIST_PARAMS:
            return {
                ...state,
                adminLoading: true,
                error: ''
            }
        case GroupActionTypes.GROUP_VIEW:
            return {
                ...state,
                view: action.view,
                loading: false,
            }
        case GroupActionTypes.GROUP_ADD_VIEW:
            return {
                ...state,
                view: action.addView,
                loading: false,
            }
        // Group Members start
        case GroupActionTypes.GROUP_MEMBERS_LIST:
            return {
                ...state,
                members: action.memberList ? action.memberList.items : [],
                memberPagination: action.memberList ? action.memberList.pagination : null,
                memberLoading: false,
            }
        case GroupActionTypes.GROUP_MEMBERS_MORE:
            return {
                ...state,
                members: [...state.members.concat(action.more)],
                memberLoading: false,
            }
        case GroupActionTypes.GROUP_MEMBERS_ADD:
            return {
                ...state,
                members: [...state.members, action.add],
                memberLoading: false,
            }
        case GroupActionTypes.GROUP_MEMBERS_UPDATE:
            const memberI = state.members.findIndex(f => f.id === action.update.id);
            const memberO = action.update;
            return {
                ...state,
                members: [...state.members.slice(0, memberI),
                memberO,
                ...state.members.slice(memberI + 1)
                ],
                memberLoading: false,
            }
        case GroupActionTypes.GROUP_MEMBERS_REMOVE:
            return {
                ...state,
                members: [...state.members.filter( val => val.id !== action.remove.id )],
                memberLoading: false,
            }

        // Group Moderators start
        case GroupActionTypes.GROUP_MODERATORS_LIST:
            return {
                ...state,
                moderators: action.moderatorsList ? action.moderatorsList.items : [],
                moderatorPagination: action.moderatorsList ? action.moderatorsList.pagination : null,
                moderatorLoading: false,
            }
        case GroupActionTypes.GROUP_MODERATORS_MORE:
            return {
                ...state,
                moderators: [...state.moderators.concat(action.more)],
                moderatorLoading: false,
            }
        case GroupActionTypes.GROUP_MODERATORS_ADD:
            return {
                ...state,
                moderators: [...state.moderators, action.add],
                moderatorLoading: false,
            }
        case GroupActionTypes.GROUP_MODERATORS_UPDATE:
            const moderatorI = state.moderators.findIndex(f => f.id === action.update.id);
            const moderatorO = action.update;
            return {
                ...state,
                moderators: [...state.moderators.slice(0, moderatorI),
                moderatorO,
                ...state.moderators.slice(moderatorI + 1)
                ],
                moderatorLoading: false,
            }
        case GroupActionTypes.GROUP_MODERATORS_REMOVE:
            return {
                ...state,
                moderators: [...state.moderators.filter( val => val.id !== action.remove.id )],
                moderatorLoading: false,
            }
        
        // Group Admins start
        case GroupActionTypes.GROUP_ADMIN_LIST:
            return {
                ...state,
                admins: action.adminList ? action.adminList.items : [],
                adminPagination: action.adminList ? action.adminList.pagination : null,
                adminLoading: false,
            }
        case GroupActionTypes.GROUP_ADMIN_MORE:
            return {
                ...state,
                admins: [...state.admins.concat(action.more)],
                adminLoading: false,
            }
        case GroupActionTypes.GROUP_ADMIN_ADD:
            return {
                ...state,
                admins: [...state.admins, action.add],
                adminLoading: false,
            }
        case GroupActionTypes.GROUP_ADMIN_UPDATE:
            const adminsI = state.admins.findIndex(f => f.id === action.update.id);
            const adminsO = action.update;
            return {
                ...state,
                admins: [...state.admins.slice(0, adminsI),
                adminsO,
                ...state.members.slice(adminsI + 1)
                ],
                adminLoading: false,
            }
        case GroupActionTypes.GROUP_ADMIN_REMOVE:
            return {
                ...state,
                admins: [...state.admins.filter( val => val.id !== action.remove.id )],
                adminLoading: false,
            }
        case GroupActionTypes.GROUP_DESTROY:
            return {
                ...state,
                view: null,
                members: null,
                moderators: null,
                admins: null,
            }
		case GroupActionTypes.GROUP_FAILURE:
			return {
				...state,
				loading: false,
				error: action.error
			}
		default:
			return state;
	}
};
import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as groupReducer from '../reducer/group.reducer';

// Lookup the 'Joke' feature state managed by NgRx
const getState = createFeatureSelector<groupReducer.State>(
    groupReducer.featureKey
);

export const view = createSelector(
    getState,
    state => state.view
);

export const error = createSelector(
    getState,
    state => state.error
);

export const loading = createSelector(
    getState,
    state => state.loading
);

export const members = createSelector(
    getState,
    state => state.members
);
export const memberPagination = createSelector(
    getState,
    state => state.memberPagination
);
export const memberLoading = createSelector(
    getState,
    state => state.memberLoading
);

export const moderators = createSelector(
    getState,
    state => state.moderators
);
export const moderatorPagination = createSelector(
    getState,
    state => state.moderatorPagination
);
export const moderatorLoading = createSelector(
    getState,
    state => state.moderatorLoading
);

export const admins = createSelector(
    getState,
    state => state.admins
);
export const adminPagination = createSelector(
    getState,
    state => state.adminPagination
);
export const adminLoading = createSelector(
    getState,
    state => state.adminLoading
);
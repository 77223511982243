import { Action } from '@ngrx/store';
import { Post } from 'src/app/models/Post';

export enum SchedulePostTimelineTypes {
    FAILURE = '[SCHEDULEPOST_TIMELINE_FAILURE] failed',
    LIST_PARAMS = '[SCHEDULEPOST_TIMELINE_LIST_PARAMS] SCHEDULEPOST List Params',
    LIST = '[SCHEDULEPOST_TIMELINE_LIST] SCHEDULEPOST List',
    LIST_MORE = '[SCHEDULEPOST_TIMELINE_LIST_MORE] SCHEDULEPOST List More',
    ADD = '[SCHEDULEPOST_TIMELINE_ADD] SCHEDULEPOST Add',
    UPDATE = '[SCHEDULEPOST_TIMELINE_UPDATE] SCHEDULEPOST Update',
    DELETE = '[SCHEDULEPOST_TIMELINE_UPDATE] SCHEDULEPOST Detele',
}

export class ListParams implements Action {
    readonly type = SchedulePostTimelineTypes.LIST_PARAMS;
    constructor(public options: { params?: any, key?: string}) { }
} 

export class List implements Action {
    readonly type = SchedulePostTimelineTypes.LIST;
    constructor(public list: any) { }
}

export class More implements Action {
    readonly type = SchedulePostTimelineTypes.LIST_MORE;
    constructor(public more: any) { }
}

export class Add implements Action {
    readonly type = SchedulePostTimelineTypes.ADD;
    constructor(public item: Post) { }
}

export class Update implements Action {
    readonly type = SchedulePostTimelineTypes.UPDATE;
    constructor(public item: Post) { }
}

export class Delete implements Action {
    readonly type = SchedulePostTimelineTypes.DELETE;
    constructor(public item: Post) { }
}

export class failure implements Action {
    readonly type = SchedulePostTimelineTypes.FAILURE;
    constructor(public error: any) { }
}


export type SchedulePostTimelineAction = ListParams | List | More | Add | Update | Delete | failure;
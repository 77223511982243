import { Component, OnInit } from '@angular/core';
import { GlobalService } from 'src/app/helpers/global.service';
import { AngularFireDatabase } from '@angular/fire/database';
import { DefaultService } from 'src/app/service/default.service';
import { CommonService } from 'src/app/service/common.service';
import { UserService } from 'src/app/service/user.service';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { User } from 'src/app/models/User';
import { AuthSelector } from 'src/app/store/selector';
import { AuthActions } from 'src/app/store/action';

@Component({
	selector: 'app-notification',
	templateUrl: './notification.component.html',
	styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit {
	actions: any = [];
	notifications: any = [];
	userObservable: Observable<User> = this.store.select(AuthSelector.User);
	user: User = null;
	requestLoading = false;
	loading = true;
	page: number = 1;
	constructor(
		public gs: GlobalService,
		public defaultService: DefaultService,
		public commonService: CommonService,
		public userService: UserService,
		private firestore: AngularFireDatabase,
		private store: Store<{}>,
	) {
		
	}

	ngOnInit() {
		this.userObservable.subscribe(data => this.user = data);
		// var msg = this;
		// var userRef = this.firestore.database.ref('notification/' + this.user?.id);
		// userRef.on('value', function (snapshot) {
		// 	msg.userService.profile("GET", null, {username: msg.gs.identity.username}).subscribe((data: any) => {
		// 		msg.store.dispatch(new AuthActions.loginSuccess({ user: data }));
		// 	});
		// });
	}

	notifiyData() {
		this.defaultService.notification("GET", null, { page: this.page }).subscribe(data => {
			this.notifications = data.data?.items;
			this.loading = false;
			this.requestLoading = true;
		});
	}

	readNotification(item) {
		this.defaultService.notification("POST", { selection: [item.message_id] }).subscribe(data => {
			this.page = 1;
			this.notifiyData();
		});
	}

	onScroll(event) {
		this.page = this.page += 1;
		this.notifiyData();
	}

	loadFirst() {
		this.loading = true;
		this.requestLoading = false;
		this.page = 1;
		this.notifications = [];
		this.notifiyData();
		let newItem = this.user;
        let user = Object.assign({}, newItem, { userProfile: { notifications: 0, messages: newItem.userProfile.messages } });
		this.store.dispatch(new AuthActions.loginSuccess({ user: user }));
	}

	updateCount() {
		let newItem = this.user;
        let user = Object.assign({}, newItem, { userProfile: { notifications: 0, messages: newItem.userProfile.messages } });
		this.store.dispatch(new AuthActions.loginSuccess({ user: user }));
	}

}




import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { TimelineService } from '../../../../modules/timeline/services/timeline.service';
import { GlobalService } from '../../../../helpers/global.service';
import { CommonService } from '../../../../service/common.service';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '../../confirmation-dialog/confirmation-dialog.component';
import { ReportAbuseComponent } from '../../report-abuse/report-abuse.component';
import { Router } from '@angular/router';
import { ItemService } from 'src/app/service/item.service';
import { UserService } from 'src/app/service/user.service';
import { Post } from 'src/app/models/Post';
import { Store } from '@ngrx/store';
import { AuthActions, CommunityTimelineAction, EventTimelineAction, ExploreTimelineAction, FeedTimelineAction, GroupTimelineAction, HiddenPostTimelineAction, HideAllPostTimelineAction, HidedMyPostTimelineAction, PageTimelineAction, UserTimelineAction } from 'src/app/store/action';
import { User } from 'src/app/models/User';
import { Observable } from 'rxjs';
import { AuthSelector } from 'src/app/store/selector';

@Component({
    selector: 'app-comment',
    templateUrl: './comment.component.html',
    styleUrls: ['./comment.component.scss']
})
export class CommentComponent implements OnInit {
    @Input() public comment;
    @Input() public owner: User = null;
    @Input() public postId: number = null;
    @Input() public itemId: number = null;
    @Input() public module: string = null;
    @Input() public shareModule: string = null;
    @Input() public myTimeline: boolean = false;
    @Input() public item: Post = null;
    @Input() public timeline: string = 'Feed';
    @Output() public replyDelete = new EventEmitter();
    @Output() public commentDelete = new EventEmitter();
    @Output() public hideAllCommentOnPost = new EventEmitter();
    userObservable: Observable<User> = this.store.select(AuthSelector.User);
	user: User = null;
    activeEditForm = true;
    activeEditComment = true;
    replyEditFormContent = true;
    replyEditFormId = null;
    replyEditFormId2 = null;

    postDialogRef: MatDialogRef<ReportAbuseComponent>;

    constructor(
        public timelineService: TimelineService,
        public userService: UserService,
        public gs: GlobalService,
        public commonService: CommonService,
        public itemService: ItemService,
        public dialog: MatDialog,
        private router: Router,
        private store: Store<{}>,
    ) {
    }

    ngOnInit() {
        this.userObservable.subscribe(data => this.user = data);
        //console.log('commentcommentcomment', this.comment);
        console.log('comment', this.comment);
    }

    onlyComment(comment, action = 'hide') {
        const alert = (action === 'hide') ? 
        `Do you want to ${action} this comment only?` : 
        `Do you want to ${action} this comment only?`;
        const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
            width: '350px',
            data: alert
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.itemService.comment("POST", null, { key: 'only-comment', comment_id: comment.id, item_id: this.postId, module: 'Post' }).subscribe(data => {
                    this.gs.alert(`You have successfully ${action} this comment`);
                    this.comment.status = (action === 'hide') ? 3 : 1;
                }, (error => {
                    this.gs.handleErrors(error);
                }));
            }
        });
    }

    postComments(comment, action = 'hide') {
        const alert = (action === 'hide') ? 
        `Do you want to ${action} all comments from this user on this post?` : 
        `Do you want to ${action} all comments from this user on this post?`;
        const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
            width: '350px',
            data: alert
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.itemService.comment("POST", null, { key: 'post-comments', friend_id: comment.user.id, item_id: this.postId, module: 'Post' }).subscribe(data => {
                    this.gs.alert(`You have successfully ${action} all comment form this user on this post`);
                    this.hideAllCommentOnPost.emit(comment);
                }, (error => {
                    this.gs.handleErrors(error);
                }));
            }
        });
    }

    allPostsComment(comment, action = 'hide') {
        const alert = (action === 'hide') ? 
        `Do you want to ${action} all comments from this user?` : 
        `Do you want to ${action} all comments from this user on this post?`;
        const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
            width: '350px',
            data: alert
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.itemService.comment("POST", null, { key: 'all-posts-comment', friend_id: comment.user.id, module: 'Post' }).subscribe(data => {
                    this.gs.alert(`You have successfully ${action} all comment form this user.`);
                    this.timelineService.followCount('hideAllCommentThisUser');
                }, (error => {
                    this.gs.handleErrors(error);
                }));
            }
        });
    }
    //  Hide All Comments from This User------------------------------------

    hideThisCommentReply(comment) {
        const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
			width: '350px',
			data: "Do you want to Hide this comment only?"
		});
		dialogRef.afterClosed().subscribe(result => {
			if (result) {
				this.timelineService.hideUserComment({comment_id: comment.id}).subscribe(data => {
                    this.gs.alert('You have successfully Delete this comment');
                    this.commentDelete.emit(this.comment);
                }, (error1 => {
                    console.log(error1);
                }));
			}
        });
    } //  Block user Comments

    blockUser(key?) {
        const alert = (key === 'permanently') ? `Do you want to ${(this.comment.user.friendState === 5) ? 'Unblock' : 'Block'} This User Permanently?` : `Do you want to take a 30 day ${(this.comment.user.friendState === 0) ? 'unblock' : 'block'} from seeing this user?`;
        const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
            width: '350px',
            data: alert
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.itemService.block("POST", null, { key: 'permanently', friend_id: this.comment.user.id }).subscribe(data => {
                    const item = data.data;
                    const msg = (item.status === 1) ? 'You have successfully unblock this user.': 'You have successfully block this user.';
                    this.gs.alert(msg);
                    this.viewProfile('update_count');
                    if(key === 'permanently' && item.status === 5) {
                        this.comment.user.friendState = 5;
                    } else if(key === 'temporary' && item.status === 4) {
                        this.comment.user.friendState = 5;
                    } else {
                        this.comment.user.friendState = 1;
                    }
                }, (error1 => {
                    this.gs.handleErrors(error1);
                }));
            }
        });
    }

    editComment() {
        this.activeEditComment = false;
    } // Edit comment

    updateCmnt(event) {
        this.activeEditComment = true;
        if(event != null) {
            this.comment.content = event.content;
        }
    }

    deleteComment() {
        const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
			width: '350px',
			data: "Do you want to Delete this comment only?"
		});
		dialogRef.afterClosed().subscribe(result => {
			if (result) {
				this.itemService.comment("DELETE", null, { comment_id: this.comment.id }).subscribe(data => {
                    this.commentDelete.emit(this.comment);
                    //this.commonService.setAction('removeCommentCount');
                    const item = Object.assign({}, this.item, { commentCount: Number(this.item.commentCount) - 1 });
                    //this.store.dispatch(new FeedTimelineAction.Update(item));
                    switch(this.timeline) {
                        case 'Feed':
                            this.store.dispatch(new FeedTimelineAction.Update(item));
                            break;
                        case 'Explore':
                            this.store.dispatch(new ExploreTimelineAction.Update(item));
                            break;
                        case 'user_post':
                            this.store.dispatch(new UserTimelineAction.Update(item));
                            break;
                        case 'group_post':
                            this.store.dispatch(new GroupTimelineAction.Update(item));
                            break;
                        case 'page_post':
                            this.store.dispatch(new PageTimelineAction.Update(item));
                            break;
                        case 'event_post':
                            this.store.dispatch(new EventTimelineAction.Update(item));
                            break;
                        case 'community_post':
                            this.store.dispatch(new CommunityTimelineAction.Update(item));
                            break;
                        case 'hidden_post':
                            this.store.dispatch(new HiddenPostTimelineAction.Update(item));
                            break;
                        case 'hidedmy_post':
                            this.store.dispatch(new HidedMyPostTimelineAction.Update(item));
                            break;
                        case 'hideall_post':
                            this.store.dispatch(new HideAllPostTimelineAction.Update(item));
                            break;
                        default:;
                    }
                });
			}
        });
    } // Delete comment

    replyEditComment(item) {
        //console.log(item);
        let commentReplies = this.comment.userComments;
        if( commentReplies && commentReplies.length > 0){
            commentReplies.forEach(reply => {
                if ( item.id === reply.id ) {
                    this.replyEditFormContent = true;
                    this.replyEditFormId = reply.id;
                    this.replyEditFormId2 = reply.id;
                }
            });
        }

    }

    replyDeleteComment(item) {
        const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
			width: '350px',
			data: "Do you want to Delete this comment only?"
		});
		dialogRef.afterClosed().subscribe(result => {
			if (result) {
				this.itemService.comment("DELETE", null, { comment_id: item.id }).subscribe(data => {
                    this.replyDelete.emit(item);
                });
			}
        });
    }

    replyUpdateCmnt(event, item) {
        this.replyEditFormContent = true;
        this.replyEditFormId = null;
        this.replyEditFormId2 = null;
        if(event != null) {
            let commentReplies = this.comment.userComments;
            if( commentReplies && commentReplies.length > 0){
                commentReplies.forEach(reply => {
                    if ( item.id === reply.id ) {
                        reply.content = event.content;
                    }
                });
            }
        }
    }


    likeComment(id, type) {
        const params = {
            "item_id": id,
            "module": "UserComment",
            "reaction_id": 1
        }
        this.itemService.reaction("POST", params).subscribe(data => {
            let item = data.data;
            if(type === 'comment') {
                if (item.item === this.comment.id) {
                    this.comment.reactionCount = item.reactionCount;
                    this.comment.isLike = ( this.comment.isLike === false ) ;
                }
            } else {
                const commentReplies = this.comment.userComments;
                if(commentReplies && commentReplies.length > 0){
                    this.comment.userComments.forEach(reply => {
                        if (item.item === reply.id) {
                            reply.reactionCount = item.reactionCount;
                            reply.isLike = ( reply.isLike === false ) ;
                        }
                    });
                }
            }
            this.gs.alert(`You have successfully like ${type} in timeline.`);
        }, (error: Response) => {
            this.gs.handleErrors(error);
        });
    } //Like comment

    // Comment Share
    commentShare() {
        const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
			width: '350px',
			data: "Do you confirm share the comment?"
		});
		dialogRef.afterClosed().subscribe(result => {
			if (result) {
                const form = {
                    PostForm: {
                        content: this.comment.content,
                        visibility: 1,
                        parent_id: this.postId,
                        item_id: this.itemId, // group_id
                        module: this.shareModule, // group module
                        refer_id:  this.comment.id,
                        refer_module: "UserComment",
                    }
                }
				this.itemService.post("POST", form).subscribe((data: any) => {
                    this.gs.alert('You have successfully share comment in timeline.');
                    //this.commonService.postAction(data.data);
                    switch(this.timeline) {
                        case 'Feed':
                            this.store.dispatch(new FeedTimelineAction.Add(data.data));
                            break;
                        case 'Explore':
                            this.store.dispatch(new ExploreTimelineAction.Add(data.data));
                            break;
                        case 'user_post':
                            this.store.dispatch(new UserTimelineAction.Add(data.data));
                            break;
                        case 'group_post':
                            this.store.dispatch(new GroupTimelineAction.Add(data.data));
                            break;
                        case 'page_post':
                            this.store.dispatch(new PageTimelineAction.Add(data.data));
                            break;
                        case 'event_post':
                            this.store.dispatch(new EventTimelineAction.Add(data.data));
                            break;
                        case 'community_post':
                            this.store.dispatch(new CommunityTimelineAction.Add(data.data));
                            break;
                        case 'hidden_post':
                            this.store.dispatch(new HiddenPostTimelineAction.Add(data.data));
                            break;
                        case 'hidedmy_post':
                            this.store.dispatch(new HidedMyPostTimelineAction.Add(data.data));
                            break;
                        case 'hideall_post':
                            this.store.dispatch(new HideAllPostTimelineAction.Add(data.data));
                            break;
                        default:;
                    }
                    this.comment.shareCount = parseInt(this.comment.shareCount) + 1;
                }, (error: Response) => {
                    this.gs.handleErrors(error);
                });
			}
        });
    }

    // User Comment report abuse
    reportComment(id) {
        this.postDialogRef = this.dialog.open(ReportAbuseComponent, {
            width: '550px',
            disableClose: true,
            panelClass: 'custom-dialog',
            data: {
                id: id,
                module: 'UserComment',
            }
        });
    }

    // Follow user
    followUser(item, action) {
        const method = (action === 'follow') ? "POST" : "DELETE";
        this.userService.follow(method, null, { following_id: item.user.id }).subscribe(data => {
            this.gs.alert(`You have successfully ${action} this user.`);
            this.itemService.followCount('update_count');
            item.user.is_follow = (action === 'follow') ? true : false;
            this.comment = item;
            this.viewProfile('update_count');
        }, (error: Response) => {
            this.gs.handleErrors(error);
        });
    }

    viewFriend(slug) {
		this.router.navigate(['/user/friend/timeline/', slug]);
		this.userService.friendSlug(slug);
		this.userService.profile("GET", null, { username: slug }).subscribe((data: any) => {
			sessionStorage.setItem('public', JSON.stringify(data));
		}, (error: Response) => {
			this.gs.handleErrors(error)
		});
    }
    
    viewProfile(action?) {
		this.userService.profile().subscribe((response: any) => {
			this.store.dispatch(new AuthActions.loginSuccess({ user: response }));
		});
    }
    
}

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GlobalService } from '../helpers/global.service';
import { environment } from '../../environments/environment';
import { catchError, map } from 'rxjs/operators';

@Injectable({
	providedIn: 'root'
})
export class LiveStreamService {

	apiRoot: string = environment.restStreamApi;

	constructor(
		private http: HttpClient,
		private gs: GlobalService,
	) { }

	broadcastsCount() {
		return fetch(`${this.apiRoot}/LiveApp/rest/v2/broadcasts/count`, { method: 'GET' })
			.then(response => response.text())
			.then(data => {
				return data;
			})
			.catch((error) => {
				this.gs.handleHttpError(error);
				console.log('error', error)
			});
	}

	broadcastsCreate(data = null) {
		return fetch(`${this.apiRoot}/LiveApp/rest/v2/broadcasts/create`, {
			method: 'POST', // or 'PUT'
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(data),
		})
			.then(response => response.json())
			.then(data => {
				return data;
			})
			.catch((error) => {
				console.error('Error:', error);
			});
	}

	vods(streamId = null, key = 'WebRTCApp') {
		return fetch(`${this.apiRoot}/${key}/rest/v2/vods/list/0/1?streamId=${streamId}&sort_by=date&order_by=desc`, { method: 'GET', redirect: 'follow' })
			.then(response => response.text())
			.then(data => {
				return data;
			})
			.catch((error) => {
				this.gs.handleHttpError(error);
				console.log('error', error)
			});
	}
}

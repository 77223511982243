import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GlobalService } from 'src/app/helpers/global.service';
import { UserProfile } from 'src/app/models/UserProfile';
import { ItemService } from 'src/app/service/item.service';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';

@Component({
	selector: 'app-show-block-user-post',
	templateUrl: './show-block-user-post.component.html',
	styleUrls: ['./show-block-user-post.component.scss']
})
export class ShowBlockUserPostComponent implements OnInit {
	currentUser: UserProfile = null;
	userList = [];
	requestLoading = false;
	loading = false;
	page = 1;
	constructor(
		private dialogRef: MatDialogRef<ShowBlockUserPostComponent>, @Inject(MAT_DIALOG_DATA) public data,
		private gs: GlobalService,
		private itemService: ItemService,
		private dialog: MatDialog
	) { }

	ngOnInit(): void {
		console.log(this.data);
		this.loadList();
	}

	loadList() {
		this.loading = true;
		this.itemService.block("GET", null, { post_id: this.data.id, key: 'post' }).subscribe(data => {
			this.loading = false;
			this.requestLoading = true;
			data.data?.forEach(element => {
				const index = this.userList.findIndex(f => f.user_id == element.user_id);
				if (index !== -1) {
					this.userList[index] = element;
				} else {
					this.userList.push(element);
				}
			});
		}, ((error: any) => {
			this.loading = false;
			this.gs.handleErrors(error);
		}))
	}

	unBlock(item) {
		const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
            width: '350px',
            data: "Do you want to unblock this user?"
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.itemService.block("POST", null, { key: 'post', post_id: this.data.id, friend_id: item.user_id }).subscribe(data => {
					this.gs.alert('You have successfully unblock user this post.');
					this.userList.splice(this.userList.indexOf(item), 1);
				}, ((error: any) => {
					this.gs.handleErrors(error);
				}));
            }
        });
		
	}

	onScroll() {
		this.loading = true;
		this.page = this.page + 1;
		this.loadList();
	}

	closeDialog() {
		this.dialogRef.close();
	}

}

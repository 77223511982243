import { Component, OnInit } from '@angular/core';
import { GlobalService } from 'src/app/helpers/global.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UserProfile } from '../../models/UserProfile';
import { TimelineService } from 'src/app/modules/timeline/services/timeline.service';
import { ConfirmationDialogComponent } from 'src/app/widgets/components/confirmation-dialog/confirmation-dialog.component';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { SendMessageComponent } from 'src/app/widgets/components/send-message/send-message.component';
import { UserService } from 'src/app/service/user.service';
import { User } from 'src/app/models/User';
import { Observable } from 'rxjs';
import { AuthSelector } from 'src/app/store/selector';
import { Store } from '@ngrx/store';
import { AuthActions } from 'src/app/store/action';

@Component({
	selector: 'app-friend-layout',
	templateUrl: './friend-layout.component.html',
	styleUrls: ['./friend-layout.component.scss']
})
export class FriendLayoutComponent implements OnInit {
    userObservable: Observable<User> = this.store.select(AuthSelector.User);
	user: User = null;
	messageDialogRef: MatDialogRef<SendMessageComponent>;
    slug = null;
	constructor(
		private gs: GlobalService,
		private route: ActivatedRoute,
		private router: Router,
		private userService: UserService,
		private timelineService: TimelineService,
		private dialog: MatDialog,
		private store: Store<{}>
	) { }

	ngOnInit() {
		//this.userObservable.subscribe(data => this.user = data);

		this.route.children[0].params.subscribe(params => {
		    this.slug = params.slug;
			this.profile(params.slug);
		});

		this.userService.slug$.subscribe(action => {
			this.profile(action);
		});
	}

	profile(slug) {
		this.userService.profile("GET", null, { username: slug }).subscribe((data: any) => {
			sessionStorage.setItem('public', JSON.stringify(data));
			this.user = data;
			if(data.success === false) {
				this.router.navigate(['/not-found']);
			}
			this.userService.isBirthdat(data.is_birthday);
		}, (error: Response) => {
			this.gs.handleErrors
		});
	}

	addFriend(friend, action) {
		this.userService.friends("POST", null, { friend_id: friend.id }).subscribe((data: any) => {
				this.gs.alert('Friend request send successfully.');
				if(action === 'add') {
					let state = Object.assign({}, friend, { friendState: 3 });
					this.user = state;
				} else {
					let state = Object.assign({}, friend, { friendState: 1 });
					this.user = state;
				}
			}, (error: Response) => {
				this.gs.handleErrors(error);
			});
	}

	declineFriend(friend) {
		this.userService.friends("DELETE", null, { friend_id: friend.id }).subscribe((data: any) => {
				this.gs.alert('Friend request decline successfully.', 'bg-danger');
				let state = Object.assign({}, friend, { friendState: 0 });
				this.user = state;
			}, (error: Response) => {
				this.gs.handleErrors(error);
			});
	}

	unFriend(friend) {
		const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
			width: '350px',
			data: "Are you sure to Unfriend your friend"
        });
        dialogRef.afterClosed().subscribe(result => {
			if (result) {
				this.userService.friends("DELETE", null, { friend_id: friend.id, key: 'cancel' }).subscribe((data: any) => {
					this.gs.alert('Remove from friend list', 'bg-danger');
					let state = Object.assign({}, friend, { friendState: 1 });
					this.user = state;
				}, (error: Response) => {
					this.gs.handleErrors(error);
				});
			}
		});
	}

	followUser(friend, action) {
		const method = (action === 'follow') ? "POST" : "DELETE";
		const bg = (action === 'follow') ? "bg-success" : "bg-danger";
        this.userService.follow(method, null, { following_id: friend.id }).subscribe(data => {
			let state = Object.assign({}, friend, { is_follow: (action === 'follow') ? true : false });
			this.user = state;
            this.gs.alert(`You have successfully ${action} this user.`, bg);
            this.viewProfile();
        }, (error: Response) => {
            this.gs.handleErrors(error);
        });
	}

	sendMessage() {
		//alert('Under Construction. Thanks for your Patience.');
		//return false;
		this.messageDialogRef = this.dialog.open(SendMessageComponent, {
            width: '550px',
            disableClose: true,
            panelClass: 'custom-dialog',
            data: this.user
        });
	}

	viewProfile() {
		this.userService.profile().subscribe((data: any) => {
			this.store.dispatch(new AuthActions.loginSuccess({ user: data }));
		});
	}

	

}

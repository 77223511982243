<form [formGroup]="form" (ngSubmit)="submit(form)">
	<div class="header bg-primary text-white">
		<h3 mat-dialog-title class="d-flex align-items-center">
			<span class="col pl-0">Submit a FAQ</span>
			<button mat-button type="button" (click)="closeDialog()"><i class="fas fa-times"></i></button>
		</h3>
	</div>
	<mat-dialog-content>
		<div class="row">
			<div class="col-12">
				<mat-form-field appearance="outline">
					<mat-label>Title</mat-label>
					<input matInput placeholder="Title" formControlName="title" name="title">
					<mat-error *ngIf="form.get('title').hasError('required') && (submitted || form.get('title').touched)">Title is required</mat-error>
				</mat-form-field>
				<mat-form-field appearance="outline">
					<mat-label>Description</mat-label>
					<textarea matInput matTextareaAutosize minRows="6" formControlName="content"></textarea>
					<mat-error *ngIf="form.get('content').hasError('required') && (submitted || form.get('content').touched)">
						Description is required</mat-error>
				</mat-form-field>
			</div>
		</div>
	</mat-dialog-content>
	<mat-dialog-actions>
		<button mat-raised-button color="primary" [ladda]="submitted" [disabled]="!form.valid" type="submit" class="px-5 py-1 text-uppercase">Submit</button>
	</mat-dialog-actions>
</form>
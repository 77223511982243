import { Action } from '@ngrx/store';

export enum DefaultActionTypes {
    DEFAULT_TRENDS_PARAMS = '[DEFAULT_TRENDS_PARAMS] Default Trends Params',
    DEFAULT_TRENDS = '[DEFAULT_TRENDS] Trends List',
    DEFAULT_TRENDS_MORE = '[DEFAULT_TRENDS_MORE] Trends More',
    DEFAULT_ADVERTISEMENT_PARAMS = '[DEFAULT_ADVERTISEMENT_PARAMS] Advertisement Params',
    DEFAULT_ADVERTISEMENT = '[DEFAULT_ADVERTISEMENT] Advertisement',
    DEFAULT_ANNOUNCEMENT_PARAMS = '[DEFAULT_ANNOUNCEMENT_PARAMS] Announcement Params',
    DEFAULT_ANNOUNCEMENT = '[DEFAULT_ANNOUNCEMENT] Announcement',
    DEFAULT_REACTIONS_PARAMS = '[DEFAULT_REACTIONS_PARAMS] Reactions Params',
    DEFAULT_REACTIONS = '[DEFAULT_REACTIONS] Reactions',
    DEFAULT_FAILURE = '[DEFAULT_FAILURE] failed',
}

export class TrendsParams implements Action {
    readonly type = DefaultActionTypes.DEFAULT_TRENDS_PARAMS;
    constructor(public options: { params?: any, key?: string}) { }
} 

export class trends implements Action {
    readonly type = DefaultActionTypes.DEFAULT_TRENDS;
    constructor(public trends: any) { }
}

export class trendMore implements Action {
    readonly type = DefaultActionTypes.DEFAULT_TRENDS_MORE;
    constructor(public moreList: any) { }
}

export class AdvertisementParams implements Action {
    readonly type = DefaultActionTypes.DEFAULT_ADVERTISEMENT_PARAMS;
    constructor(public options: { params?: any, key?: string}) { }
} 

export class Advertisement implements Action {
    readonly type = DefaultActionTypes.DEFAULT_ADVERTISEMENT;
    constructor(public advertisement: any) { }
}

export class AnnouncementParams implements Action {
    readonly type = DefaultActionTypes.DEFAULT_ANNOUNCEMENT_PARAMS;
    constructor(public options: { params?: any, key?: string}) { }
} 

export class Announcement implements Action {
    readonly type = DefaultActionTypes.DEFAULT_ANNOUNCEMENT;
    constructor(public announcement: any) { }
}

export class ReactionsParams implements Action {
    readonly type = DefaultActionTypes.DEFAULT_REACTIONS_PARAMS;
    constructor(public options?: any) { }
} 

export class ReactionsList implements Action {
    readonly type = DefaultActionTypes.DEFAULT_REACTIONS;
    constructor(public reactions: any) { }
}

export class failure implements Action {
    readonly type = DefaultActionTypes.DEFAULT_FAILURE;
    constructor(public error: any) { }
}

export type DefaultAction = TrendsParams | trends | trendMore | AdvertisementParams | Advertisement | AnnouncementParams | Announcement | ReactionsParams | ReactionsList | failure;
import { User } from 'src/app/models/User';
import { UserProfile } from 'src/app/models/UserProfile';
import { AuthAction, AuthActionTypes } from '../action/auth.actions';

export const featureKey = 'auth';

export interface State {
	auth: User,
	userProfile: UserProfile,
	loading: boolean,
	error: any
}

const authentication = JSON.parse(localStorage.getItem('user'));
const profile = JSON.parse(localStorage.getItem('userProfile'));

export const initialState: State = {
	auth: authentication ? authentication : null,
	userProfile: profile ? profile : null,
	loading: false,
	error: ''
};

export function reducer(state = initialState, action: AuthAction): State {
	switch (action.type) {
		case AuthActionTypes.AUTH_LOGIN:
			return {
				...state,
				auth: null,
				loading: true,
				error: ''
			}
		case AuthActionTypes.AUTH_REGISTER:
		case AuthActionTypes.AUTH_BYAUTH:
		case AuthActionTypes.AUTH_EMAIL_VERIFICATION:
		case AuthActionTypes.AUTH_FORGOTPASSWORD:
		case AuthActionTypes.AUTH_RESETPASSWORD:
		case AuthActionTypes.AUTH_RESEND_VERIFICATION:
			return {
				...state,
				auth: null,
				loading: true,
				error: ''
			}
		case AuthActionTypes.AUTH_SUCCESS:
			//console.log('action.payload.user', action.payload.user)
			return {
				...state,
				auth: action.payload.user,
				loading: false,
			}
		case AuthActionTypes.AUTH_FAILURE:
		case AuthActionTypes.AUTH_MESSAGE:
			return {
				...state,
				error: action.payload.error,
				loading: false,
			}
		case AuthActionTypes.AUTH_LOGOUT:
			return {
				...state,
				auth: null,
				loading: false,
			}
		default:
			return state;
	}
};
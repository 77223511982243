import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { GlobalService } from 'src/app/helpers/global.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TimelineService } from 'src/app/modules/timeline/services/timeline.service';
import { CommonService } from 'src/app/service/common.service';
import { ItemService } from 'src/app/service/item.service';

@Component({
	selector: 'app-share-post',
	templateUrl: './share-post.component.html',
	styleUrls: ['./share-post.component.scss']
})
export class SharePostComponent implements OnInit {

	form: FormGroup;
	submitted = false;
	loading = false;
	constructor(
		private formBuilder: FormBuilder,
		public gs: GlobalService,
		public timelineService: TimelineService,
		public itemService: ItemService,
		private dialogRef: MatDialogRef<SharePostComponent>,
		@Inject(MAT_DIALOG_DATA) public data,
		private commonService: CommonService,
	) { }

	get f() {return this.form.controls}

	ngOnInit() {
		this.form = this.formBuilder.group({
			content: new FormControl('')
		});

	}

	submit(form) {
		this.submitted = true;
		if (!form.valid) {
			this.submitted = false;
			return false;
		}
		this.itemService.post("POST", form.value, { post_id: this.data.id, key: 'share' }).subscribe(data => {
			this.gs.alert('You have successfully share post in timeline.');
			this.commonService.postAction(data.data);
			this.dialogRef.close();
		}, (error: Response) => {
			this.gs.handleErrors(error);
			this.submitted = false;
		});
	}

	

	closeDialog() {
		this.dialogRef.close();
	}

}

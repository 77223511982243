import { Action } from '@ngrx/store';
import { User } from 'src/app/models/User';

export enum UserActionTypes {
    PROFILE_PARAMS = '[PROFILE_PARAMS] User Profile Params',
    PROFILE = '[PROFILE] User Profile',
    PROFILE_SUGGESTIONS_PARAMS = '[PROFILE_SUGGESTIONS_PARAMS] User Suggestions Params',
    PROFILE_SUGGESTIONS = '[PROFILE_SUGGESTIONS] User Suggestions',
    PROFILE_SUGGESTION_MORE = '[PROFILE_SUGGESTION_MORE] User Suggestions More',
    PROFILE_SUGGESTION_UPDATE = '[PROFILE_SUGGESTION_UPDATE] User Suggestions Update',
    PEOPLE_YOU_MAY_KNOW_PARAMS = '[PEOPLE_YOU_MAY_KNOW_PARAMS] People you may know Params',
    PEOPLE_YOU_MAY_KNOW = '[PEOPLE_YOU_MAY_KNOW] People you may know',
    PROFILE_FAILURE = '[PROFILE_] failed',
}

export class Profile implements Action {
    readonly type = UserActionTypes.PROFILE;
    constructor(public options?: { method: string, params?: any, params2?: any }) { }
}

export class SuggestionParams implements Action {
    readonly type = UserActionTypes.PROFILE_SUGGESTIONS_PARAMS;
    constructor(public options: { params?: any, key?: string}) { }
}

export class Suggestions implements Action {
    readonly type = UserActionTypes.PROFILE_SUGGESTIONS;
    constructor(public suggestions: any) { }
}

export class SuggestionMore implements Action {
    readonly type = UserActionTypes.PROFILE_SUGGESTION_MORE;
    constructor(public more: any) { }
}

export class SuggestionUpdate implements Action {
    readonly type = UserActionTypes.PROFILE_SUGGESTION_UPDATE;
    constructor(public item: User) { }
}

export class PeopleYouMayKnowParams implements Action {
    readonly type = UserActionTypes.PEOPLE_YOU_MAY_KNOW_PARAMS;
    constructor(public options: { params?: any, key?: string}) { }
}

export class PeopleYouMayKnow implements Action {
    readonly type = UserActionTypes.PEOPLE_YOU_MAY_KNOW;
    constructor(public peopleYouMayKnow: any) { }
}

export class failure implements Action {
    readonly type = UserActionTypes.PROFILE_FAILURE;
    constructor(public error: any) { }
}


export type UserAction = Profile | SuggestionParams | Suggestions | SuggestionMore | SuggestionUpdate | PeopleYouMayKnowParams | PeopleYouMayKnow | failure;
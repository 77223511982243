import { Action } from '@ngrx/store';
import { Post } from 'src/app/models/Post';

export enum EventTimelineTypes {
    FAILURE = '[EVENT_TIMELINE_FAILURE] failed',
    LIST_PARAMS = '[EVENT_TIMELINE_LIST_PARAMS] EVENT List Params',
    LIST = '[EVENT_TIMELINE_LIST] EVENT List',
    LIST_MORE = '[EVENT_TIMELINE_LIST_MORE] EVENT List More',
    ADD = '[EVENT_TIMELINE_ADD] EVENT Add',
    UPDATE = '[EVENT_TIMELINE_UPDATE] EVENT Update',
    DELETE = '[EVENT_TIMELINE_UPDATE] EVENT Detele',
}

export class ListParams implements Action {
    readonly type = EventTimelineTypes.LIST_PARAMS;
    constructor(public options: { params?: any, key?: string}) { }
} 

export class List implements Action {
    readonly type = EventTimelineTypes.LIST;
    constructor(public list: any) { }
}

export class More implements Action {
    readonly type = EventTimelineTypes.LIST_MORE;
    constructor(public more: any) { }
}

export class Add implements Action {
    readonly type = EventTimelineTypes.ADD;
    constructor(public item: Post) { }
}

export class Update implements Action {
    readonly type = EventTimelineTypes.UPDATE;
    constructor(public item: Post) { }
}

export class Delete implements Action {
    readonly type = EventTimelineTypes.DELETE;
    constructor(public item: Post) { }
}

export class failure implements Action {
    readonly type = EventTimelineTypes.FAILURE;
    constructor(public error: any) { }
}


export type EventTimelineAction = ListParams | List | More | Add | Update | Delete | failure;
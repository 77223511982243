import { Component, OnInit } from '@angular/core';
import { GlobalService } from 'src/app/helpers/global.service';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { AskFaqComponent } from './ask-faq/ask-faq.component';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { DefaultService } from 'src/app/service/default.service';

@Component({
	selector: 'app-faqs',
	templateUrl: './faqs.component.html',
	styleUrls: ['./faqs.component.scss']
})
export class FaqsComponent implements OnInit {
	form: FormGroup;
	faqDialogRef: MatDialogRef<AskFaqComponent>;
	panelOpenState = false;
	requestLoading = false;
	loading = false;
	faqs = [];
	page = 1;
	perPage: number = null;
	totalCount: number = null;
	constructor(
		public gs: GlobalService,
		public defaultService: DefaultService,
		private dialog: MatDialog,
		private fb: FormBuilder,
	) { }

	ngOnInit() {
		this.form = this.fb.group({
			title: new FormControl(''),
		});
		this.loadFaqs();
	}

	loadFaqs(condition = '') {
		this.loading = true;
		this.requestLoading = false;
		this.defaultService.item("GET", null, { moduleId: 'faq', q: condition, 'per-page': 50 }).subscribe(data => {
			console.log('data', data);
			this.faqs = data.data ? data?.data?.items : [];
			/*data.data && data?.data?.items.forEach(el => {
				const index = this.faqs.findIndex(f => f.id == el.id);
				if (index !== -1) {
					this.faqs[index] = el;
				} else {
					this.faqs.push(el);
				}
			});*/
			//this.totalCount = data?.data?.pagination?.totalCount;
			//this.perPage = data?.data?.pagination?.pageSize;
			this.requestLoading = true;
			this.loading = false;
		});
	}

	search(form) {
		if (!form.valid) {
			return false;
		}
		this.loading = true;
		//this.page = 1;
		//this.faqs = [];
		this.loadFaqs(form.value.title);
	}

	askFaq() {
		if(!this.gs.user) {
			this.gs.alert('Login is required', 'danger');
			return
		}
		this.faqDialogRef = this.dialog.open(AskFaqComponent, {
			width: '550px',
			disableClose: true,
			panelClass: 'custom-dialog',
			data: []
		});
	}

	pageChange() {
		this.page += 1;
		this.loadFaqs();
	}

}

import { Component, OnInit } from '@angular/core';
import { GlobalService } from 'src/app/helpers/global.service';
import { Post } from 'src/app/models';
import { ItemService } from 'src/app/service/item.service';
import { environment } from 'src/environments/environment';

@Component({
	selector: 'app-home-live-video',
	templateUrl: './home-live-video.component.html',
	styleUrls: ['./home-live-video.component.scss']
})
export class HomeLiveVideoComponent implements OnInit {
	loading: boolean = false;
	allpost: Post[] = [];
	currentVideo: Post = null;
	page = 1;
	liveStremUrl = environment.restStreamApi;

	constructor(
		public gs: GlobalService,
		private itemService: ItemService
	) { }

	ngOnInit(): void {
		this.onLoad();
	}

	onLoad() {
		this.loading = true;
		this.itemService.post("GET", null, { key: 'post-videos', page: this.page }).subscribe(response => {
			this.loading = false;
			let data: any = this.gs.apiResponce(response);
			this.allpost = data.data ? data.data : [];
			this.currentVideo = data.data ? data.data[0] : null;
		}, (error: Response) => {
			this.loading = false;
			this.gs.handleErrors(error);
		});
	}

	streamVideo(item) {
		//return `${this.liveStremUrl}/${item?.postVideos?.stream_type}/${item?.postVideos?.url}`; 
		return item?.postVideos?.url;
	}


	loadMainVideo(item) {
		this.currentVideo = item;
	}

}

<div class="card profile-card">
	<div class="card-header"></div>
	<div class="card-body">
		<div class="profile d-flex flex-wrap">
			<div class="image" *ngIf="userObservable | async as user">
				<a routerLink="/user/timeline/{{ user.username }}">
					<img src="{{ user.avatar }}" alt="" class="img-fluid rounded-circle" width="75" height="75">
				</a>
			</div>
			<div class="heading">
				<h6><a routerLink="/user/timeline/{{ user.username }}">{{ user.name }}</a></h6>
				<p>@{{ user.username }}</p>
			</div>
		</div>
		<ul class="d-flex" *ngIf="userObservable | async as user">
			<li>
				<a routerLink="/user/friends/{{user.username}}">Friends <br> 
					<b>{{user.friends_count}}</b>
				</a>
			</li>
			<li>
				<a routerLink="/user/followers/{{user.username}}">Followers <br> 
					<b>{{user.followers_count}}</b>
				</a>
			</li>
			<li>
				<a routerLink="/user/following/{{user.username}}">Following <br> 
					<b>{{user.followings_count}}</b>
				</a>
			</li>
		</ul>
	</div>
</div>

import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-show-analytics',
  templateUrl: './show-analytics.component.html',
  styleUrls: ['./show-analytics.component.scss']
})
export class ShowAnalyticsComponent implements OnInit {

  constructor(
    private dialogRef: MatDialogRef<ShowAnalyticsComponent>,
		@Inject(MAT_DIALOG_DATA) public data
  ) { }

  ngOnInit() {
    console.log('this.data', this.data);
  }

  closeDialog() {
		this.dialogRef.close();
	}

}

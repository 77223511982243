import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, debounceTime, map, mergeMap } from 'rxjs/operators';
import { GlobalService } from 'src/app/helpers/global.service';
import { ItemService } from 'src/app/service/item.service';
import { PageAction } from '../action';

@Injectable()
export class PageEffects {
    constructor(
        private actions$: Actions,
        private itemService: ItemService,
        private gs: GlobalService
    ) { }

    feedsTimeline$ = createEffect(() => this.actions$.pipe(
        ofType(PageAction.PageActionTypes.PAGE_MAY_LIKE_PARAMS),
        mergeMap((options: PageAction.MayLikeParams) =>
            this.itemService.page("GET", null, options.options.params).pipe(map((item: any) => {
                return new PageAction.MayLikes(item.data ? item.data : []);
            }),
                catchError((error) => {
                    this.gs.handleErrors(error);
                    return [new PageAction.failure(error)]
                })
            )
        )
    ));

}
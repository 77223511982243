import { Component, OnInit, Inject } from '@angular/core';
import { FormControl, Validators, FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { GlobalService } from 'src/app/helpers/global.service';
import { ItemService } from 'src/app/service/item.service';

@Component({
	selector: 'app-report-abuse',
	templateUrl: './report-abuse.component.html',
	styleUrls: ['./report-abuse.component.scss']
})
export class ReportAbuseComponent implements OnInit {

	form: FormGroup;
	submitted = false;
	heading = 'Report Abuse';
	constructor(
		private formBuilder: FormBuilder,
		private itemService: ItemService,
		public gs: GlobalService,
		private dialogRef: MatDialogRef<ReportAbuseComponent>,
		@Inject(MAT_DIALOG_DATA) public data
	) { }

	ngOnInit() {
		//console.log(this.data);
		this.form = this.formBuilder.group({
			item_id: new FormControl(this.data.id, [Validators.required]),
			module: new FormControl(this.data.module, [Validators.required]),
			content: new FormControl('', [Validators.required]),
			is_abused: new FormControl('1')
		});

	}

	submit(form) {
		this.submitted = true;
		if (!form.valid) {
			this.submitted = false;
			return false;
		}
		this.itemService.comment("POST", { CommentForm: form.value }).subscribe((data: any) => {
			//console.log(data);
			this.submitted = false;
			this.gs.alert('You have successfully send report to admin.');
			this.dialogRef.close(form);
		}, (error: Response) => {
			this.submitted = false;
			this.gs.handleErrors(error);
		});
	}

	closeDialog() {
		this.dialogRef.close()
	}

}

import { Component, OnInit } from '@angular/core';
import { GlobalService } from 'src/app/helpers/global.service';
import { TimelineService } from 'src/app/modules/timeline/services/timeline.service';
import { Router, NavigationExtras } from '@angular/router';
import { ItemService } from 'src/app/service/item.service';
import { Store } from '@ngrx/store';
import { DefaultAction } from 'src/app/store/action';
import { Observable } from 'rxjs';
import { Trends } from 'src/app/models/Trends';
import { DefaultSelector } from 'src/app/store/selector';
import { Pagination } from 'src/app/models/Pagination';

@Component({
	selector: 'app-trends-for-you',
	templateUrl: './trends-for-you.component.html',
	styleUrls: ['./trends-for-you.component.scss']
})
export class TrendsForYouComponent implements OnInit {
	per_page = 20;
	page: number = 1;
	hide = false;

	trends: Observable<Trends[]> = this.store.select(DefaultSelector.trends);
	paginatio: Observable<Pagination> = this.store.select(DefaultSelector.trendPagination);
	loader: Observable<boolean> = this.store.select(DefaultSelector.trendloading);

	constructor(
		public gs: GlobalService,
		public timelineService: TimelineService,
		public itemService: ItemService,
		private router: Router,
		private store: Store<{}>
	) { }

	ngOnInit() {
		this.trends.subscribe(data => {
			(data === null || data.length === 0) && this.loadTrend();
			if(data && data.length > 70) { this.hide = true; }
		});
	}

	loadTrend(key = 'list') {
		this.store.dispatch(new DefaultAction.TrendsParams({ params: { key: 'trend', 'per-page': this.per_page, page: this.page }, key: key}));
	}

	pagination() {
		if(this.page === 4) {
			this.hide = true;
		}
		this.page += 1; 
		this.loadTrend('more');
	}

	viewTrans(trend) {
		let navigationExtras: NavigationExtras = {
			queryParams: {
				"search": trend
			}
		};
		this.router.navigate(['/timeline/trends'], navigationExtras);
	}

}

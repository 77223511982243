import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { GlobalService } from 'src/app/helpers/global.service';
import { User } from 'src/app/models/User';
import { UserService } from 'src/app/service/user.service';
import { AuthActions } from 'src/app/store/action';

@Component({
	selector: 'app-friend-request',
	templateUrl: './friend-request.component.html',
	styleUrls: ['./friend-request.component.scss']
})
export class FriendRequestComponent implements OnInit {

	requestLoading = false;
	friends: User[] | null;

	constructor(
		public gs: GlobalService,
		private userService: UserService,
		private store: Store<{}>
	) { }

	ngOnInit() {
	}

	loadRequest() {
		this.userService.friends("GET", null, { key: 'requests' }).subscribe((data: any) => {
			this.friends = data ? data : [];
			this.requestLoading = true;
		}, (error: Response) => {
			this.gs.handleErrors(error);
		});
	}

	addFriend(id, friend) {
		this.userService.friends("POST", null, { friend_id: id }).subscribe((data: any) => {
				this.gs.alert('You have successfully add friend list.');
				this.friends.splice(this.friends.indexOf(friend), 1);
				this.viewProfile();
			}, (error: Response) => {
				this.gs.handleErrors(error);
			});
	}

	declineFriend(id, friend) {
		if (confirm("Are you sure to decline your friend request")) {
			this.userService.friends("DELETE", null, { friend_id: id }).subscribe((data: any) => {
					this.gs.alert('Friend request decline successfully.');
					this.friends.splice(this.friends.indexOf(friend), 1);
				}, (error: Response) => {
					this.gs.handleErrors(error);
				});
		}
	}

	viewProfile() {
		this.userService.profile().subscribe((response: any) => {
			this.store.dispatch(new AuthActions.loginSuccess({ user: response }));
		});
	}

}

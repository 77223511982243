<div class="user-banner">
	<div class="w-100" style="position: relative;">
		<img src="{{ user.banner }}" alt="" class="img-fluid" width="1170" height="270" (click)="openImage()" style="cursor: pointer;">
		<div class="loader" *ngIf="loading"><span></span> <span></span></div>
		<div class="coverBorder" style="pointer-events: none;"></div>
	</div>
	<div class="upload">
		<label for="" class="btn btn-primary"><i class="fas fa-camera"></i>
			<input type="file" (change)="openCropper($event)" />
		</label>
	</div>
	<div class="info">
		<a routerLinkActive="active" [routerLink]="['/user/setting']" class="btn btn-white">Settings <i class="fas fa-user-cog"></i></a>
	</div>
</div>
import { ActionReducerMap, MetaReducer } from '@ngrx/store';
import { environment } from '../../environments/environment';
import { AuthReducer, NotificationsReducer, DefaultReducer, TimelineReducer, UserReducer, PageReducer, GroupReducer } from '../store/reducer'; 

export interface State {
  [AuthReducer.featureKey]: AuthReducer.State,
  [UserReducer.featureKey]: UserReducer.State,
  [NotificationsReducer.featureKey]: NotificationsReducer.State,
  [DefaultReducer.featureKey]: DefaultReducer.State,
  [TimelineReducer.featureKey]: TimelineReducer.State,
  [PageReducer.featureKey]: PageReducer.State,
  [GroupReducer.featureKey]: GroupReducer.State,
}

export const reducers: ActionReducerMap<State> = {
  [AuthReducer.featureKey]: AuthReducer.reducer,
  [UserReducer.featureKey]: UserReducer.reducer,
  [NotificationsReducer.featureKey]: NotificationsReducer.reducer,
  [DefaultReducer.featureKey]: DefaultReducer.reducer,
  [TimelineReducer.featureKey]: TimelineReducer.reducer,
  [PageReducer.featureKey]: PageReducer.reducer,
  [GroupReducer.featureKey]: GroupReducer.reducer,
};


export const metaReducers: MetaReducer<State>[] = !environment.production ? [] : [];
<form [formGroup]="form" (ngSubmit)="submit(form)">
	<div class="header bg-primary text-white">
		<h3 mat-dialog-title class="d-flex align-items-center">
			<span class="col pl-0">Share On Your Timeline</span>
			<button mat-button type="button" (click)="closeDialog()"><i class="fas fa-times"></i></button>
		</h3>
	</div>
	<mat-dialog-content>
		<div class="row">
			<div class="col-12">
				<mat-form-field appearance="outline" class="comment">
					<textarea matInput matTextareaAutosize  class="link-view" placeholder="Click Here to include a comment before sharing this post or Click the ReChat button to share this post without a comment" formControlName="content" name="content"></textarea>
				</mat-form-field>
				<div class="timeline-post card">
					<div class="group-image" *ngIf="data.medias.length > 0">
						<div class="d-flex flex-wrap img-count-{{data.medias.length}}">
							<div *ngFor="let image of data.medias; let i = index" class="w-50 open-image" [ngClass]="{'hide': i > 3}">
								<ng-container *ngIf="(image.file.type === 'image')">
									<img src="{{image.file.thumb}}" alt="" class="img-fluid w-100">
								</ng-container>
								<ng-container *ngIf="(image.file.type === 'video')">
									<video name="media">
										<source src="{{image.file.videoUrl}}" type="{{image.file.mimetype}}">
									</video>
								</ng-container>
								<div *ngIf="(i === 3 && data.medias.length > 4)" class="more-images d-flex align-items-center justify-content-between">
									<span class="w-100 text-center">+ {{ data.medias.length - 4 }}</span>
								</div>
							</div>
						</div>
					</div>
					<div class="card-body" *ngIf="data.content">
						<div class="card-text">
							<read-more [text]="data.content" [maxLength]="250"></read-more>
						</div>
					</div>
				</div>
			</div>
		</div>
	</mat-dialog-content>
	<mat-dialog-actions>
		<button mat-raised-button color="primary" [ladda]="submitted" type="submit" class="px-5 py-1 text-uppercase">ReChat</button>
	</mat-dialog-actions>
</form>
import { Action } from '@ngrx/store';
import { Post } from 'src/app/models/Post';

export enum HidedMyPostTimelineTypes {
    FAILURE = '[HIDEDMYPOST_TIMELINE_FAILURE] failed',
    LIST_PARAMS = '[HIDEDMYPOST_TIMELINE_LIST_PARAMS] HIDEDMYPOST List Params',
    LIST = '[HIDEDMYPOST_TIMELINE_LIST] HIDEDMYPOST List',
    LIST_MORE = '[HIDEDMYPOST_TIMELINE_LIST_MORE] HIDEDMYPOST List More',
    ADD = '[HIDEDMYPOST_TIMELINE_ADD] HIDEDMYPOST Add',
    UPDATE = '[HIDEDMYPOST_TIMELINE_UPDATE] HIDEDMYPOST Update',
    DELETE = '[HIDEDMYPOST_TIMELINE_UPDATE] HIDEDMYPOST Detele',
}

export class ListParams implements Action {
    readonly type = HidedMyPostTimelineTypes.LIST_PARAMS;
    constructor(public options: { params?: any, key?: string}) { }
} 

export class List implements Action {
    readonly type = HidedMyPostTimelineTypes.LIST;
    constructor(public list: any) { }
}

export class More implements Action {
    readonly type = HidedMyPostTimelineTypes.LIST_MORE;
    constructor(public more: any) { }
}

export class Add implements Action {
    readonly type = HidedMyPostTimelineTypes.ADD;
    constructor(public item: Post) { }
}

export class Update implements Action {
    readonly type = HidedMyPostTimelineTypes.UPDATE;
    constructor(public item: Post) { }
}

export class Delete implements Action {
    readonly type = HidedMyPostTimelineTypes.DELETE;
    constructor(public item: Post) { }
}

export class failure implements Action {
    readonly type = HidedMyPostTimelineTypes.FAILURE;
    constructor(public error: any) { }
}


export type HidedMyPostTimelineAction = ListParams | List | More | Add | Update | Delete | failure;
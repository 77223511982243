import { Component, OnInit, ChangeDetectorRef, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { CommonService } from 'src/app/service/common.service';
import { ReCaptcha2Component } from 'ngx-captcha';
import { Title } from '@angular/platform-browser';
import { GlobalService } from 'src/app/helpers/global.service';

@Component({
	selector: 'app-contact',
	templateUrl: './contact.component.html',
	styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

	form: FormGroup;
	submitted = false;
	errors = [];
	public captchaIsLoaded = false;
	public captchaSuccess = false;
	public captchaResponse?: string;
	public captchaIsExpired = false;

	@ViewChild('captchaElem', { static: false }) captchaElem: ReCaptcha2Component;

	public siteKey = environment.recaptchaSiteKey;

	constructor(
		private fb: FormBuilder,
		private cdr: ChangeDetectorRef,
		private commonService: CommonService,
		public gs: GlobalService,
		private titleService: Title
	) { }

	get f() { return this.form.controls; }

	ngOnInit() {
		this.titleService.setTitle(this.gs.mainTitle + ' | ' + 'Contact Us');
		this.form = this.fb.group({
			name: new FormControl('', [Validators.required]),
			email: new FormControl('', [Validators.compose([Validators.required, Validators.email])]),
			subject: new FormControl('', [Validators.required]),
			message: new FormControl('', [Validators.required]),
			recaptcha: new FormControl('', [Validators.required])
		});
	}

	contact(form) {
		var formData = {
			"contactForm" : {
				name: form.value.name,
				email: form.value.email,
				subject: form.value.subject,
				body: form.value.message,
			}
		}
		//console.log(formData);
		//return false;
		this.submitted = true;
		if (!form.valid) { 
			this.submitted = false;
			return false; 
		}
		this.commonService.contactForm(formData).subscribe((data: any) => {
			this.submitted = false;
			this.gs.alert('You have successfully submit contact form.');
			this.form.reset();
			this.reload();
		}, (error: Response) => {
			this.submitted = false;
		});
	}

	handleLoad(): void {
		this.captchaIsLoaded = true;
		this.cdr.detectChanges();
	}

	handleSuccess(captchaResponse: string): void {
		this.captchaSuccess = true;
		this.captchaResponse = captchaResponse;
		this.cdr.detectChanges();
	}

	handleExpire(): void {
		this.captchaSuccess = false;
		this.captchaIsExpired = true;
		this.cdr.detectChanges();
	}

	handleReset() {
		this.captchaSuccess = false;
		this.captchaResponse = undefined;
		this.captchaIsExpired = false;
		this.cdr.detectChanges();
	}

	reload(): void {
		this.captchaElem.reloadCaptcha();
	}

}

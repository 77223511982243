import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, catchError } from 'rxjs/operators';
import { GlobalService } from '../helpers/global.service';
import { environment } from '../../environments/environment';
import { Subject } from 'rxjs';

@Injectable({
	providedIn: 'root'
})

export class ItemService {

	apiRoot: string = environment.apiUrl;
	auth = null;
	private _memberRequestCount = new Subject<string>();
	memberRequestCount$ = this._memberRequestCount.asObservable();
	private _commentId = new Subject<number>();
    private _popupCommentList = new Subject<any>();
    private _commentReplyId = new Subject<any>();
    private _followCount = new Subject<any>();
    private _thisUserAllCommentDelete = new Subject<string>();
    commentId$ = this._commentId.asObservable();
    popupCommentList$ = this._popupCommentList.asObservable();
    commentReplyId$ = this._commentReplyId.asObservable();
    followCount$ = this._followCount.asObservable();
    thisUserCommentDelete$ = this._thisUserAllCommentDelete.asObservable();

	constructor(
		private http: HttpClient,
		private gs: GlobalService,
	) { 
		this.auth = this.gs.user;
	}

	requestMembersCount(title: string) {
		this._memberRequestCount.next(title);
		this.auth = this.gs.user;
	}

	popupCommentList(data: number) {
        this._popupCommentList.next(data);
    }

    commentTriger(id: number) {
        this._commentId.next(id);
    }

    commentReplyId(data: any) {
        this._commentReplyId.next(data);
    }

    followCount(data: any) {
        this._followCount.next(data);
    }

    thisUserCommentDelete(data: any) {
        this._thisUserAllCommentDelete.next(data);
    }
	
	// Pages APi
	page(method = "GET", params = null, params2 = null) {
		return this.http.request(method, `${this.apiRoot}/page/index`, { body: params, params: params2 })
			.pipe(map((response: any) => {
					return response;
				}), catchError(this.gs.handleHttpError)
			);
	}

	pageMembers(method = "GET", params = null, params2 = null) {
		return this.http.request(method, `${this.apiRoot}/page/members`, { body: params, params: params2 })
			.pipe(map((response: any) => {
					return response;
				}), catchError(this.gs.handleHttpError)
			);
	}

	// Group Api
	group(method = "GET", params = null, params2 = null) {
		return this.http.request(method, `${this.apiRoot}/group/index`, { body: params, params: params2 })
			.pipe(map((response: any) => {
					return response;
				}), catchError(this.gs.handleHttpError)
			);
	}

	groupMembers(method = "GET", params = null, params2 = null) {
		return this.http.request(method, `${this.apiRoot}/group/members`, { body: params, params: params2 })
			.pipe(map((response: any) => {
					return response;
				}), catchError(this.gs.handleHttpError)
			);
	}

	// Event Api
	event(method = "GET", params = null, params2 = null) {
		return this.http.request(method, `${this.apiRoot}/event/index`, { body: params, params: params2 })
			.pipe(map((response: any) => {
					return response;
				}), catchError(this.gs.handleHttpError)
			);
	}

	// Post api
	post(method = "GET", params = null, params2 = null) {
		let action = `${this.apiRoot}/post/${this.auth ? 'index' : 'public-index'}`;
		return this.http.request(method, action, { body: params, params: params2 })
			.pipe(map((response: any) => {
					return response;
				}), catchError(this.gs.handleHttpError)
			);
	}

	/*shared(method = "GET", params = null, params2 = null) {
		return this.http.request(method, `${this.apiRoot}/post/shared`, { body: params, params: params2 })
			.pipe(map((response: any) => {
					return response;
				}), catchError(this.gs.handleHttpError)
			);
	}

	pinPost(method = "GET", params = null, params2 = null) {
		return this.http.request(method, `${this.apiRoot}/post/pin-post`, { body: params, params: params2 })
			.pipe(map((response: any) => {
					return response;
				}), catchError(this.gs.handleHttpError)
			);
	}*/

	reaction(method = "GET", params = null, params2 = null) {
		return this.http.request(method, `${this.apiRoot}/post/reaction`, { body: params, params: params2 })
			.pipe(map((response: any) => {
					return response;
				}), catchError(this.gs.handleHttpError)
			);
	}

	comment(method = "GET", params = null, params2 = null) {
		return this.http.request(method, `${this.apiRoot}/post/comment`, { body: params, params: params2 })
			.pipe(map((response: any) => {
					return response;
				}), catchError(this.gs.handleHttpError)
			);
	}

	media(method = "GET", params = null, params2 = null) {
		return this.http.request(method, `${this.apiRoot}/post/media`, { body: params, params: params2 })
			.pipe(map((response: any) => {
					return response;
				}), catchError(this.gs.handleHttpError)
			);
	}

	block(method = "GET", params = null, params2 = null) {
		return this.http.request(method, `${this.apiRoot}/post/block-users`, { body: params, params: params2 })
			.pipe(map((response: any) => {
					return response;
				}), catchError(this.gs.handleHttpError)
			);
	}

	broadcastMessage(method = "GET", params = null, params2 = null) {
		return this.http.request(method, `${this.apiRoot}/post/broadcast-message`, { body: params, params: params2 })
			.pipe(map((response: any) => {
					return response;
				}), catchError(this.gs.handleHttpError)
			);
	}

}

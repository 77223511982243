import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { GlobalService } from 'src/app/helpers/global.service';
import { CommonService } from 'src/app/service/common.service';
import { DefaultService } from 'src/app/service/default.service';

@Component({
	selector: 'app-subscribe',
	templateUrl: './subscribe.component.html',
	styleUrls: ['./subscribe.component.css']
})
export class SubscribeComponent implements OnInit {
	@Input() title: string = '';
	loading: boolean = false;
	form: FormGroup;
	submitted = false;
	filteredCountry = [];
	localCode: string = '+1';

	constructor(
		public gs: GlobalService,
		private defaultService: DefaultService,
		private commonService: CommonService,
		private fb: FormBuilder
	) { }

	get f() { return this.form.controls; }

	ngOnInit(): void {
		this.gs.clearErrorMessages();
		this.form = this.fb.group({
			name: new FormControl(''),
			email: new FormControl('', [Validators.required, Validators.email]),
			phone: new FormControl('', [Validators.required, Validators.maxLength(13), Validators.pattern('[0-9]{3}-[0-9]{3}-[0-9]{4}')]),
		});
		this.commonService.country().subscribe((data: any) => {
			//console.log('data.data', data.data);
			this.filteredCountry = data.data;
		}, (error: Response) => {
			this.gs.handleErrors(error);
		});
	}


	onSubmit(form: any) {
		this.submitted = true;
		if (!form.valid) {
			this.submitted = false;
			this.gs.validateAllFormFields(this.form);
			return;
		}
		this.gs.clearErrorMessages();
		//console.log()
		let oldValue = this.localCode + form.value.phone;
		form.value.phone = oldValue.split('-').join('');
		const params = { NewsletterForm: form.value };
		this.defaultService.newsletter(params).subscribe(response => {
			this.submitted = false;
			let data = this.gs.apiResponce(response);
			this.gs.alert(`Thanks for subscribing. You will receive regular updates from ${this.gs.mainTitle}.`);
			this.form.reset();
		}, (error: Response) => {
			this.submitted = false;
			this.form.reset();
			this.gs.handleErrors(error);
		})
	}

}

<div class="blog-list w-100 float-left py-4">
	<div class="container">
		<div class="row">
			<div class="col-12">
				<div class="card page-card">
					<div class="card-header d-flex flex-wrap align-items-center">
						<h3 class="card-title col pl-0">Faqs <pre>
						</pre></h3>
						<a mat-raised-button color="primary" (click)="askFaq()">Submit a Query</a>
					</div>
					<div class="card-body">
						<div class="any-search mb-3 mb-lg-4 w-100 float-left">
							<form [formGroup]="form" (ngSubmit)="search(form)">
								<div class="input-group">
									<input type="text" class="form-control" formControlName="title" placeholder="Search Any Question">
									<div class="input-group-prepend">
										<button class="btn btn-info" type="submit">Search</button>
									</div>
								</div>
							</form>
						</div>
						
						<mat-accordion>
							<!-- {{ faqs.length | json}} -->
							<mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false" *ngFor="let faq of faqs">
								<mat-expansion-panel-header>
									<mat-panel-title>
										{{faq.title}}
									</mat-panel-title>
								</mat-expansion-panel-header>
								<div [innerHtml]="faq.content"></div>
							</mat-expansion-panel>
							<div class="w-100 p-3" *ngIf="(faqs.length === 0) && (!loading)">No Result Found</div>
						</mat-accordion>
						<div *ngIf="loading">
							<div class="d-flex align-items-center justify-content-center w-100">
								<mat-spinner color="primary" class="spinner-size"></mat-spinner>
							</div>
						</div>
						<div class="text-center d-flex justify-content-center mt-4" *ngIf="totalCount > perPage">
							<button *ngIf="totalCount !== faqs.length" mat-stroked-button color="primary" type="button" (click)="pageChange()">Load More</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
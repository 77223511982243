import { ItemAction, ItemActionTypes } from '../action/item.action';

export const featureKey = 'item';

export interface State {
	loading: boolean,
	error: any
}

export const initialState: State = {
	loading: false,
	error: ''
};

export function reducer(state = initialState, action: ItemAction): State {

	switch (action.type) {
		case ItemActionTypes.ITEM_STAFF_PARAMS:
			return {
				...state,
				loading: true,
				error: ''
			}
		case ItemActionTypes.ITEM_FAILURE:
			return {
				...state,
				loading: false,
				error: action.error
			}
		default:
			return state;
	}
};
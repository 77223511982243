<div class="load-1 timeline" *ngIf="(layout === 'timeline') else loaded">
	<div class="L1 mb-3 border flex-wrap" *ngFor="let i of [0,1,2]">
		<div class="L2">
			<div class="L3 L4"></div>
		</div>
		<div class="L5 mb-3">
			<div class="L7">
				<div class="L3 ntTy4"></div>
			</div>
			<div class="ZnqJl">
				<div class="L3 L3w"></div>
			</div>
		</div>
		<div class="ZnqJl w-100" *ngFor="let i of [0,1,2]">
			<div class="L3 L3w"></div>
		</div>
	</div>
</div>

<div class="load-1" *ngIf="(layout === 'friend-chat')">
	<div class="L1" *ngFor="let i of [0,1,2,3]">
		<div class="L2">
			<div class="L3 L4"></div>
		</div>
		<div class="L5">
			<div>
				<div class="L3 L6"></div>
			</div>
			<div class="L7">
				<div class="L3 ntTy4"></div>
			</div>
		</div>
		<div class="L1w"></div>
	</div>
</div>

<ng-template #loaded>
	<div class="load-1">
		<div class="L1" *ngFor="let i of [0,1,2,3,4]">
			<div class="L2">
				<div class="L3 L4"></div>
			</div>
			<div class="L5">
				<div>
					<div class="L3 L6"></div>
				</div>
				<div class="L7">
					<div class="L3 ntTy4"></div>
				</div>
				<div class="ZnqJl">
					<div class="L3 L3w"></div>
				</div>
			</div>
			<div class="L1w"></div>
		</div>
	</div>
</ng-template>

<div class="card custom-card">
	<div class="card-body pb-0">
		<h3 class="card-title">
			<a routerLink="." (click)="mainSearch()" style="font-size: 13px;">Who To Follow / Find Friends</a>
		</h3>
	</div>
	<ul class="list-group list-group-flush suggestion-list">
		<div class="w-100 pb-3">
			<li class="list-group-item d-flex align-items-center" *ngFor="let friend of friends | async">
				<div class="image">
					<img src="{{ friend.avatar }}" alt="" class="img-fluid rounded-circle border" width="50" height="50">
				</div>
				<div class="caption d-flex align-items-center">
					<h6 class="text-truncate">
						<a routerLink="/user/friend/timeline/{{friend.username}}">{{ friend.name }}</a> <br>
						<small>Mutual Friends: {{ friend.mutual_friends_count }}</small>
					</h6>
					<div class="button">
						<button class="btn btn-sm btn-block text-nowrap btn-outline-secondary" *ngIf="friend.friendState == 5" (click)="addFriend(friend)"><i class="fas fa-user-plus"></i> Add Friend</button>
						<button class="btn btn-sm btn-block text-nowrap btn-primary" *ngIf="friend.friendState == 3" (click)="declineFriend(friend)"><i class="fas fa-user-friends"></i> Remove</button>
						<button class="btn btn-sm btn-block text-nowrap btn-outline-secondary" *ngIf="friend.is_follow == false" (click)="followUser(friend, 'follow')"><i class="fas fa-rss"></i> Follow</button>
						<button class="btn btn-sm btn-block text-nowrap btn-primary" *ngIf="friend.is_follow == true" (click)="followUser(friend, 'unfollow')"><i class="fas fa-rss"></i> UnFollow</button>
					</div>
				</div>
			</li>
			<ng-container *ngIf="friends | async as lengthCount">
				<li class="list-group-item d-flex align-items-center" style="padding-left: 18px" *ngIf="lengthCount.length === 0">
					Friends not found
				</li>
			</ng-container>
		</div>
	</ul>
	<div *ngIf="loader | async" class="d-flex align-items-center justify-content-center">
		<mat-spinner color="primary" class="spinner-size"></mat-spinner>
	</div>

</div> <!-- Trends ror you end -->

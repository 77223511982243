import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, switchMap, switchMapTo, tap } from 'rxjs/operators';
import { GlobalService } from 'src/app/helpers/global.service';
import { AuthActions, NotificationsAction } from '../action';
import { Observable } from 'rxjs';
import { Action, Store } from '@ngrx/store';
import { DefaultService } from 'src/app/service/default.service';


@Injectable()
export class NotificationsEffects {

	constructor(
		private actions$: Actions, 
		private defaultService: DefaultService,
		private store: Store<any>,
		private gs: GlobalService
	) { }

	/*@Effect() notoifications$ = this.actions$.pipe(
		ofType(NotificationsAction.NotificationsActionTypes.LIST_INITIALIZED),
		mergeMap((action: NotificationsAction.ListInitialized) => this.defaultService
			.notification().pipe(
				map((data: any) => new NotificationsAction.List({items: data.data.items, pagination: data.data.pagination})),
				catchError(error => {
					this.gs.handleErrors(error);
					return [new NotificationsAction.failure(error)]
				})
			)
		)
	);*/

	@Effect() notoifications: Observable<Action> = this.actions$.pipe(
		ofType(NotificationsAction.NotificationsActionTypes.LIST_INITIALIZED),
		map(action => action),
		switchMap((payloads: NotificationsAction.ListInitialized) => {
			let payload = payloads.options;
			return this.defaultService.notification(payload.method, payload.params, payload.params2).pipe(
				map((data: any) => {
					let options = payloads.options;
					switch(options.key) {
						case 'More':
							this.store.dispatch(new NotificationsAction.More(data.data.items));
							break;
						case 'Read':
							this.store.dispatch(new NotificationsAction.Update(data.data));
							break;
						case 'Delete':
							this.store.dispatch(new NotificationsAction.Delete(data.data));
							break;
						default:
							this.store.dispatch(new NotificationsAction.List({items: data.data.items, pagination: data.data.pagination}));
					}
				}),
			);
		}),
		switchMap((data) => {return []}),
		catchError(error => {
			this.gs.handleErrors(error);
			return [new NotificationsAction.failure(error)]
		})
	 );

	 @Effect() removeCount: Observable<Action> = this.actions$.pipe(
		ofType(NotificationsAction.NotificationsActionTypes.REMOVE_COUNT),
		map(action => action),
		switchMap((payloads: NotificationsAction.RemoveCount) => {
			let response = payloads.data;
        	response.userProfile.notifications = 0;
			return [new AuthActions.loginSuccess({user: response})];
		})
	 );

	
	/*@Effect() notoifications$ = this.actions$.pipe(
		ofType(NotificationsAction.NotificationsActionTypes.LIST_INITIALIZED),
		mergeMap((action: NotificationsAction.ListInitialized) => this.defaultService
			.notification().pipe(
				map((data: any) => new NotificationsAction.List({items: data.data.items, pagination: data.data.pagination})),
				catchError(error => {
					this.gs.handleErrors(error);
					return [new NotificationsAction.failure(error)]
				})
			)
		)
	);*/
	
}

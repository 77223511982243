import { Page } from 'src/app/modules/pages/models/Page';
import { PageAction, PageActionTypes } from '../action/page.actions';

export const featureKey = 'page';

export interface State {
    mayLikes: Page[],
	loading: boolean,
	error: any
}

export const initialState: State = {
    mayLikes: null,
	loading: false,
	error: ''
};

export function reducer(state = initialState, action: PageAction): State {

	switch (action.type) {
        case PageActionTypes.PAGE_MAY_LIKE_PARAMS:
            return {
                ...state,
                loading: true,
                error: ''
            }
        case PageActionTypes.PAGE_MAY_LIKE:
            return {
                ...state,
                mayLikes: action.maylike,
                loading: false,
            }
		case PageActionTypes.ITEM_FAILURE:
			return {
				...state,
				loading: false,
				error: action.error
			}
		default:
			return state;
	}
};
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, catchError } from 'rxjs/operators';
import { GlobalService } from '../helpers/global.service';
import { environment } from '../../environments/environment';
import { Subject } from 'rxjs';

@Injectable({
	providedIn: 'root'
})

export class DefaultService {

	apiRoot: string = environment.apiUrl;

	private _trigger = new Subject<string>();
	trigger$ = this._trigger.asObservable();

	constructor(
		private http: HttpClient,
		private gs: GlobalService,
	) { 
		//console.log('this.gs.identity', this.gs.identity)
	}

	trigger(data: string) {
		this._trigger.next(data);
	}

	item(method = "GET", params = null, params2 = null) {
		let action = `item/${this.gs.identity ? 'index' : 'public-index'}`;
		return this.http.request(method, `${this.apiRoot}/${action}`, { body: params, params: params2 }).pipe(
			map((response: any) => { return response }),
			catchError(this.gs.handleHttpError)
		);
	}

	// Plan
	plans(method = "GET", params = null, params2 = null) {
		return this.http.request(method, `${this.apiRoot}/default/plans`, { body: params, params: params2 })
			.pipe(map((response: any) => {
				return response;
			}), catchError(this.gs.handleHttpError)
			);
	}

	newsletter(params = null) {
		return this.http.request("POST", `${this.apiRoot}/default/newsletter`, { body: params, params: null }).pipe(
			map((response: any) => response.data),
			catchError(this.gs.handleHttpError)
		);
	}

	businesses(method = "GET", params = null, params2 = null) {
		return this.http.request(method, `${this.apiRoot}/default/businesses`, { body: params, params: params2 })
			.pipe(map((response: any) => {
				return response;
			}), catchError(this.gs.handleHttpError));
	}

	categories(method = "GET", params = null, params2 = null) {
		return this.http.request(method, `${this.apiRoot}/default/categories`, { body: params, params: params2 })
			.pipe(map((response: any) => {
				return response;
			}), catchError(this.gs.handleHttpError));
	}

	notification(method = "GET", params = null, params2 = null) {
		return this.http.request(method, `${this.apiRoot}/default/notifications`, { body: params, params: params2 })
			.pipe(map((response: any) => {
				return response;
			}), catchError(this.gs.handleHttpError));
	}

	reactions(method = "GET", params = null, params2 = null) {
		return this.http.request(method, `${this.apiRoot}/default/reactions`, { body: params, params: params2 })
			.pipe(map((response: any) => {
					return response;
				}), catchError(this.gs.handleHttpError)
			);
	}

	support(method = "GET", params = null, params2 = null) {
		return this.http.request(method, `${this.apiRoot}/default/support`, { body: params, params: params2 })
			.pipe(map((response: any) => {
					return response;
				}), catchError(this.gs.handleHttpError)
			);
	}

	college(method = "GET", params = null, params2 = null) {
		return this.http.request(method, `${this.apiRoot}/default/colleges`, { body: params, params: params2 })
			.pipe(map((response: any) => {
					return response;
				}), catchError(this.gs.handleHttpError)
			);
	}

	school(method = "GET", params = null, params2 = null) {
		return this.http.request(method, `${this.apiRoot}/default/schools`, { body: params, params: params2 })
			.pipe(map((response: any) => {
					return response;
				}), catchError(this.gs.handleHttpError)
			);
	}

	message(method = "GET", params = null, params2 = null) {
		return this.http.request(method, `${this.apiRoot}/default/message`, { body: params, params: params2 })
			.pipe(map((response: any) => {
					return response;
				}), catchError(this.gs.handleHttpError)
			);
	}

}

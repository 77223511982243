<div class="blog-list w-100 float-left py-4">
	<div class="container">
		<div class="row">
			<div class="col-12">
				<div class="card page-card">
					<div class="card-header d-flex flex-wrap align-items-center">
						<h3 class="card-title col pl-0">Contact Us</h3>
					</div>
					<div class="card-body">
						<div class="row">
							<div class="col-md-6 col-12">
								<h3>Address</h3>
								<p class="mb-0">ChatDit LLC</p>
								<p class="mb-0">7711 South Raeford Rd Suite 102-157</p>
								<p class="mb-0">Fayetteville NC 28304</p>
								<p class="mb-0">Email Address: support@chatdit.com</p>
								<iframe class="w-100 my-4 rounded"
									src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3267.2265509735867!2d-79.05692038475893!3d35.02606188035228!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89ab3fad35332161%3A0x9cc111b4aba475a1!2s7711%20S%20Raeford%20Rd%20Suite%20102-157%2C%20Fayetteville%2C%20NC%2028304%2C%20USA!5e0!3m2!1sen!2sin!4v1577085491191!5m2!1sen!2sin"
									width="400" height="400" frameborder="0" style="border:0;"
									allowfullscreen=""></iframe>
							</div>
							<div class="col-md-6 col-12">
								<h3>Contact form</h3>
								<form novalidate (ngSubmit)="contact(form)" [formGroup]="form" autocomplete="off">
									<mat-form-field appearance="outline">
										<mat-label>Name</mat-label>
										<input matInput placeholder="Name" formControlName="name" required>
										<mat-error
											*ngIf="form.get('name').hasError('required') && (submitted || form.get('name').touched)">
											Name is required</mat-error>
									</mat-form-field>
									<mat-form-field appearance="outline">
										<mat-label>Email Address</mat-label>
										<input matInput placeholder="Email" formControlName="email" required>
										<mat-error *ngIf="f.email.errors && f.email.touched">
											<div *ngIf="f.email.errors.required">Email address is required</div>
											<div *ngIf="f.email.errors.email">Email address not valid</div>
										</mat-error>
									</mat-form-field>
									<mat-form-field appearance="outline">
										<mat-label>Subject</mat-label>
										<input matInput placeholder="Subject" formControlName="subject" required>
										<mat-error
											*ngIf="form.get('subject').hasError('required') && (submitted || form.get('subject').touched)">
											Subject is required</mat-error>
									</mat-form-field>
									<mat-form-field appearance="outline">
										<mat-label>Message</mat-label>
										<textarea matInput placeholder="Message" formControlName="message"
											required></textarea>
										<mat-error
											*ngIf="form.get('message').hasError('required') && (submitted || form.get('message').touched)">
											Message is required</mat-error>
									</mat-form-field>
									<div class="form-group">
										<ngx-recaptcha2 #captchaElem [siteKey]="siteKey" type="image"
											(expire)="handleExpire()" (load)="handleLoad()"
											(success)="handleSuccess($event)" (reset)="handleReset()"
											formControlName="recaptcha">
										</ngx-recaptcha2>
									</div>
									<div class="form-group">
										<button type="submit" mat-raised-button color="primary" class="px-5 py-2"
											[ladda]="submitted" [disabled]="!form.valid">Submit</button>
									</div>
									<!-- <button class="btn btn-info" (click)="reload()">Try</button> -->
								</form>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
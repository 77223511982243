<a href="javascript:void(0);" ngbDropdownToggle (click)="loadRequest()" class="nav-link">
	<!-- <img src="/assets/img/head-icon-1.png" alt=""> -->
	<i class="fas fa-user-friends"></i>
</a>
<div class="pb-0 dropdown-menu-right dropdown-menu-media" ngbDropdownMenu>
	<div class="dropdown-arrow"></div>
	<div class="dropdown-header text-center">
		<div class="d-flex">
			<span class="font-18 col text-left"><strong>Friend Request</strong></span>
			<a class="text-muted pr-3" routerLink="/user/friend-request">View all</a>
		</div>
	</div>
	<div class="px-0 scrolldiv" *ngIf="requestLoading else loaded">
		<ul class="request" *ngIf="friends">
			<li *ngFor="let friend of friends">
				<div class="friend-box d-flex align-items-center">
					<div class="image">
							<img src="{{ friend.avatar }}" alt="" width="55" height="55" class="border-right">
					</div>
					<div class="caption d-flex align-items-center col">
						<h4><a routerLink="/user/friend/timeline/{{ friend.username }}">{{ friend.name }}</a></h4>
						<div class="button">
							<a mat-flat-button (click)="addFriend(friend.id, friend)" color="primary">Confirm</a>
							<a mat-stroked-button (click)="declineFriend(friend.id, friend)">Decline</a>
						</div>
					</div>
				</div>
			</li>
			<li *ngIf="friends.length == 0" class="p-3">
				No friend request
			</li>
		</ul>
	</div>
	<ng-template #loaded>
		<div class="p-3 d-flex align-items-center justify-content-center">
			<mat-spinner color="primary" class="spinner-size"></mat-spinner>
		</div>
	</ng-template>
</div>


<div class="blog-list w-100 float-left py-4">
	<div class="container">
		<div class="row">
			<div class="col-12">
				<div class="card page-card">
					<div class="card-header d-flex flex-wrap align-items-center">
						<h3 class="card-title col pl-0">About</h3>
					</div>
					<div class="card-body">
						Lorem ipsum, dolor sit amet consectetur adipisicing elit. Quisquam aspernatur magni autem ducimus, nostrum reprehenderit dignissimos aliquid perspiciatis recusandae temporibus voluptatibus quos minus? Molestiae libero ullam odio maiores dolores dignissimos!

					</div>
				</div>
			</div>
		</div>
	</div>
</div>


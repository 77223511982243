import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/auth/services/auth.service';

@Component({
	selector: 'app-landing-navbar',
	templateUrl: './landing-navbar.component.html',
	styleUrls: ['./landing-navbar.component.scss']
})
export class LandingNavbarComponent implements OnInit {

	isLoggedIn$: Observable<boolean>;
	constructor(
		private authService: AuthService,
	) { }

	ngOnInit() {
		this.isLoggedIn$ = this.authService.authenticationState;
	}

	openAlert() {
		alert('You must be logged in to view content');
	}

}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-friend-box',
  templateUrl: './friend-box.component.html',
  styleUrls: ['./friend-box.component.css']
})
export class FriendBoxComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

<div class="header bg-primary text-white">
    <h3 mat-dialog-title class="d-flex align-items-center">
        <span class="col pl-0">Post Block User List</span>
        <button mat-button type="button" (click)="closeDialog()"><i class="fas fa-times"></i></button>
    </h3>
</div>
<mat-dialog-content class="like-user-list pt-0">
    <div class="model-inner">
        <div class="user-list css-scroll" *ngIf="userList" infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50" [scrollWindow]="false" (scrolled)="onScroll($event)">
            <ng-container *ngIf="requestLoading">
                <div class="media text-muted py-2 px-3 border-bottom border-gray" *ngFor="let user of userList">
                    <img alt="" class="img-fluid rounded-circle border" height="50" width="50" [src]="user.user.avatar">
                    <div class="media-body">
                        <div class="d-flex justify-content-between align-items-center w-100">
                            <div><strong class="text-gray-dark mx-3">{{user.user.name}}</strong>
                                <p class="mx-3 p-0 mb-0">
                                    <small *ngIf="user.user.mutual_friends_count">{{user.user.mutual_friends_count}} Mutual friends</small>
                                </p>
                            </div>
                            <a mat-stroked-button class="ml-2" (click)="unBlock(user)">Unblock</a>
                        </div>
                    </div>
                </div>
                <div class="d-flex align-items-center justify-content-center w-100" *ngIf="userList.length === 0">
                    User not found
                </div>
            </ng-container>
            <ng-container *ngIf="loading">
                <div class="d-flex align-items-center justify-content-center w-100">
                    <mat-spinner color="primary" class="spinner-size"></mat-spinner>
                </div>
            </ng-container>
        </div>
    </div>
</mat-dialog-content>
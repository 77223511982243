import { Component, OnInit, Input } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { chunkArray } from 'src/app/helpers/common.helper';
import { GlobalService } from 'src/app/helpers/global.service';
import { ICmsItem } from 'src/app/models';
import { DefaultService } from 'src/app/service/default.service';


@Component({
	selector: 'app-category-widgets',
	templateUrl: './category-widgets.component.html',
	styleUrls: ['./category-widgets.component.scss']
})
export class CategoryWidgetsComponent implements OnInit {
	requestLoading = false;
	@Input() public widgetClass: any = null;
	@Input() public category_slug: any = null;
	items: ICmsItem[] | any = null;

	constructor(
		private gs: GlobalService,
		private defaultService: DefaultService
	) { }

	ngOnInit() {
		const params = {
			moduleId: 'news',
			category_slug: this.widgetClass
		}
		this.defaultService.item("GET", null, params).subscribe((response: any) => {
			const data: any = this.gs.apiResponce(response);
			if(this.widgetClass === 'politics') {
				this.items = chunkArray(data.data ? data.data : [], 7);
			} else {
				this.items = chunkArray(data.data ? data.data : [], 5);
			}
			this.requestLoading = true;
		}, (error: Response) => {
			this.gs.handleErrors(error);
		});

	}

	storyOptions: OwlOptions = {
		items: 1,
		loop: true,
		dots: false,
		nav: true,
		navSpeed: 700,
		autoplay: false,
		autoplayTimeout: 5000,
		animateOut: 'fadeOut',
    	animateIn: 'fadeIn',
		navText: ['<i class="fas fa-chevron-left"></i>', '<i class="fas fa-chevron-right"></i>'],
	}

}

import { Action } from '@ngrx/store';
import { Post } from 'src/app/models/Post';

export enum PageTimelineTypes {
    FAILURE = '[PAGE_TIMELINE_FAILURE] failed',
    LIST_PARAMS = '[PAGE_TIMELINE_LIST_PARAMS] PAGE List Params',
    LIST = '[PAGE_TIMELINE_LIST] PAGE List',
    LIST_MORE = '[PAGE_TIMELINE_LIST_MORE] PAGE List More',
    ADD = '[PAGE_TIMELINE_ADD] PAGE Add',
    UPDATE = '[PAGE_TIMELINE_UPDATE] PAGE Update',
    DELETE = '[PAGE_TIMELINE_UPDATE] PAGE Detele',
}

export class ListParams implements Action {
    readonly type = PageTimelineTypes.LIST_PARAMS;
    constructor(public options: { params?: any, key?: string}) { }
} 

export class List implements Action {
    readonly type = PageTimelineTypes.LIST;
    constructor(public list: any) { }
}

export class More implements Action {
    readonly type = PageTimelineTypes.LIST_MORE;
    constructor(public more: any) { }
}

export class Add implements Action {
    readonly type = PageTimelineTypes.ADD;
    constructor(public item: Post) { }
}

export class Update implements Action {
    readonly type = PageTimelineTypes.UPDATE;
    constructor(public item: Post) { }
}

export class Delete implements Action {
    readonly type = PageTimelineTypes.DELETE;
    constructor(public item: Post) { }
}

export class failure implements Action {
    readonly type = PageTimelineTypes.FAILURE;
    constructor(public error: any) { }
}


export type PageTimelineAction = ListParams | List | More | Add | Update | Delete | failure;
<div class="card profile-card absolute-pic">
	<div class="card-header"></div>
	<div class="card-body">
		<div class="profile d-flex flex-wrap">
			<div class="image">
				<div class="loading" style="position: relative;" *ngIf="userObservable | async as user">
					<img src="{{ user.avatar }}" alt="" class="img-fluid rounded-circle" width="100" height="100" (click)="openImage()" style="cursor: pointer;">
					<div class="loader rounded-circle" *ngIf="loading"><span></span> <span></span></div>
				</div>
				<div class="upload">
					<label for="" class="btn btn-primary"><i class="fas fa-camera"></i>
						<!-- <input type="file" (change)="openCropper($event.target.files)" /> -->
						<input type="file" (change)="openCropper($event)" />
					</label>
				</div>
				<h6 *ngIf="userObservable | async as user">{{ user.name }}</h6>
			</div>
			<div class="heading text-center" *ngIf="userObservable | async as user">
				<h6><a routerLink="/user/timeline/{{ user.username }}">{{ user.name }}</a></h6>
				<p >@{{ user.username }}</p>
			</div>
		</div>
		<ul class="d-flex" *ngIf="userObservable | async as user">
			<li>
				<a routerLink="/user/friends/{{user.username}}">Friends <br> 
					<b>{{user.friends_count}}</b>
				</a>
			</li>
			<li>
				<a routerLink="/user/followers/{{user.username}}">Followers <br>
					<b>{{user.followers_count}}</b>
				</a>
			</li>
			<li>
				<a routerLink="/user/following/{{user.username}}">Following <br>
					<b>{{user.followings_count}}</b>
				</a>
			</li>
		</ul>
	</div>
</div> <!-- Card profile end -->

import { Component, OnInit } from '@angular/core';
import { GlobalService } from 'src/app/helpers/global.service';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { ImagePopupComponent } from 'src/app/widgets/components/image-popup/image-popup.component';
import { TimelineService } from 'src/app/modules/timeline/services/timeline.service';
import { ImageCropperComponent } from '../image-cropper/image-cropper.component';
import { ItemService } from 'src/app/service/item.service';
import { DefaultService } from 'src/app/service/default.service';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { AuthSelector, DefaultSelector } from 'src/app/store/selector';
import { AuthActions, DefaultAction, ExploreTimelineAction, FeedTimelineAction, UserTimelineAction } from 'src/app/store/action';
import { User } from 'src/app/models/User';

@Component({
    selector: 'app-user-banner',
    templateUrl: './user-banner.component.html',
    styleUrls: ['./user-banner.component.scss']
})
export class UserBannerComponent implements OnInit {
    progress: number;
    loading = false;
    userObservable: Observable<User> = this.store.select(AuthSelector.User);
    user: User = null;
    reactionsList: Observable<any> = this.store.select(DefaultSelector.reactions);
    reactions: any = [];

    imgDialogRef: MatDialogRef<ImagePopupComponent>;
    cropperDialogRef: MatDialogRef<ImageCropperComponent>;

    constructor(
        public gs: GlobalService,
        private dialog: MatDialog,
        public timelineService: TimelineService,
        public defaultService: DefaultService,
        public itemService: ItemService,
		private store: Store<{}>
    ) {
    }

    ngOnInit() {
        this.userObservable.subscribe(data => this.user = data);
        
        this.reactionsList.subscribe(data => {
            this.reactions = data;
			(data === null) && this.store.dispatch(new DefaultAction.ReactionsParams());
        });
    }

    openCropper(files: any) {
        let file: FileList = files.target.files;
        if(file.length > 0) {
            this.cropperDialogRef = this.dialog.open(ImageCropperComponent, {
                width: '750px',
                disableClose: false,
                panelClass: 'cropper-dialog',
                data: {
                    width: 1200,
                    height: 400,
                    imageName: 'banner-image',
                    cropper: files
                }
            });
            this.cropperDialogRef.afterClosed().pipe().subscribe(name => {
                if(name !== true) {
                    this.upload(name);
                }
            });
        }
    }

    upload(image) {
        this.loading = true;
        const params = {
            "PostForm": {
                "item_id": this.user.id,
                "module": "UserProfile",
                "key": "profile_cover",
                "files": [image.id]
            }
        }
        this.itemService.post("POST", params).subscribe((data) => {
            let item = data.data;
            if(data.code) {
                let newItem = this.user;
                let user = Object.assign({}, newItem, { banner: item.owner.banner, banner_id: item.medias[0].file_id });
                this.store.dispatch(new AuthActions.loginSuccess({ user: user }));
                this.store.dispatch(new UserTimelineAction.Add(item));
                this.store.dispatch(new FeedTimelineAction.Add(item));
                this.store.dispatch(new ExploreTimelineAction.Add(item));
            }
            this.loading = false;
        }, (error: Response) => {
            this.gs.handleErrors(error);
            this.loading = false;
        });
    }

    openImage() {
        this.loading = true;
        const params = {
            username: this.user.username,
            page: 1,
            'per-page': 50,
            item_id: this.user.id,
            module: 'UserProfile',
            key: 'profile_cover'
        }
        this.itemService.media("GET", null, params).subscribe((data) => {
            var currentImageId = null;
            const imgGroup = [];
            const images: any = data.data?.items;
            if ((images && images.length === 0) || (images === null) || (images === undefined)) {
                this.loading = false;
                return false
            }
            images.forEach(element => {
                if (element.id === this.user.userProfile.banner_id) {
                    currentImageId = element;
                }
            });
            images.forEach(image => imgGroup.push(image));

            this.imgDialogRef = this.dialog.open(ImagePopupComponent, {
                width: '550px',
                disableClose: false,
                panelClass: 'popup-dialog',
                data: {
                    id: currentImageId.id,
                    imgGroup: imgGroup,
                    module: 'Photo',
                    owner: this.user,
                    postDate: currentImageId.created_at,
                    reactionsList: this.reactions
                }
            });
            this.imgDialogRef.afterClosed().pipe().subscribe(name => {
                this.loading = false;
            });
        }, (error: Response) => {
            this.gs.handleErrors(error);
        });
    }

}

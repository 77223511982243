import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-announcement-video',
  templateUrl: './announcement-video.component.html',
  styleUrls: ['./announcement-video.component.scss']
})
export class AnnouncementVideoComponent implements OnInit {

  constructor(
    private dialogRef: MatDialogRef<AnnouncementVideoComponent>, @Inject(MAT_DIALOG_DATA) public data
  ) { }

  ngOnInit() {
    console.log(this.data)
  }

  closeDialog() {
		this.dialogRef.close();
	}

}


export const fileManupulate = (uploadFiles: any) => {
    let files: any[] = [];
    uploadFiles.docs && uploadFiles.docs.map((file: any) => files.push(file.id));
    uploadFiles.image && uploadFiles.image.map((file: any) => files.push(file.id));
    uploadFiles.video && uploadFiles.video.map((file: any) => files.push(file.id));
    return files;
};

export const categoryListToTree = (items: any[] | null, id: any = null, link: string = 'parent_id') => {
    let newItem: any = items && items.filter((item: any) => item[link] === id).map((item: any) => ({ ...item, children: categoryListToTree(items, item.id) }));
    return newItem;
}

export const chunkArray = (inputArray: any[], perChunk = 4) => {
    let result = inputArray && inputArray.reduce((resultArray, item, index) => {
        const chunkIndex = Math.floor(index / perChunk)
        if (!resultArray[chunkIndex]) {
            resultArray[chunkIndex] = [] // start a new chunk
        }
        resultArray[chunkIndex].push(item)
        return resultArray
    }, []);
    return result;
}
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { GlobalService } from 'src/app/helpers/global.service';
import { ItemService } from 'src/app/service/item.service';
import { GroupAction } from '../action';

@Injectable()
export class GroupEffects {
    constructor(
        private actions$: Actions,
        private itemService: ItemService,
        private gs: GlobalService
    ) { }

    groupView$ = createEffect(() => this.actions$.pipe(
        ofType(GroupAction.GroupActionTypes.GROUP_VIEW_PARAMS),
        mergeMap((options: GroupAction.ViewParams) =>
            this.itemService.group("GET", null, options.options.params).pipe(map((item: any) => {
                return new GroupAction.View(item.data ? item.data : {});
            }),
            catchError((error) => {
                this.gs.handleErrors(error);
                return [new GroupAction.failure(error)]
            })))
    ));

    groupMembers$ = createEffect(() => this.actions$.pipe(
        ofType(GroupAction.GroupActionTypes.GROUP_MEMBERS_LIST_PARAMS),
        mergeMap((options: GroupAction.MembersListParams) =>
            this.itemService.groupMembers("GET", null, options.options.params2).pipe(map((data: any) => {
                if (options.options.key === 'more') {
                    return new GroupAction.MembersMore(data ? data.data.items : []);
                } else {
                    return new GroupAction.MembersList(data ? data.data : []);
                }
            }),
            catchError((error) => {
                this.gs.handleErrors(error);
                return [new GroupAction.failure(error)]
            })))
    ));

    groupModerators$ = createEffect(() => this.actions$.pipe(
        ofType(GroupAction.GroupActionTypes.GROUP_MODERATORS_LIST_PARAMS),
        mergeMap((options: GroupAction.ModeratorListParams) =>
            this.itemService.groupMembers("GET", null, options.options.params2).pipe(map((data: any) => {
                if (options.options.key === 'more') {
                    return new GroupAction.ModeratorMore(data ? data.data.items : []);
                } else {
                    return new GroupAction.ModeratorsList(data ? data.data : []);
                }
            }),
            catchError((error) => {
                this.gs.handleErrors(error);
                return [new GroupAction.failure(error)]
            })))
    ));

    groupAdmins$ = createEffect(() => this.actions$.pipe(
        ofType(GroupAction.GroupActionTypes.GROUP_ADMIN_LIST_PARAMS),
        mergeMap((options: GroupAction.AdminListParams) =>
            this.itemService.groupMembers("GET", null, options.options.params2).pipe(map((data: any) => {
                if (options.options.key === 'more') {
                    return new GroupAction.AdminMore(data ? data.data.items : []);
                } else {
                    return new GroupAction.AdminList(data ? data.data : []);
                }                
            }),
            catchError((error) => {
                this.gs.handleErrors(error);
                return [new GroupAction.failure(error)]
            })))
    ));

}
import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { GlobalService } from 'src/app/helpers/global.service';
import { ItemService } from 'src/app/service/item.service';
import { Emoji } from '@ctrl/ngx-emoji-mart/ngx-emoji';
import { Post } from 'src/app/models/Post';
import { Store } from '@ngrx/store';
import { CommunityTimelineAction, EventTimelineAction, ExploreTimelineAction, FeedTimelineAction, GroupTimelineAction, HiddenPostTimelineAction, HideAllPostTimelineAction, HidedMyPostTimelineAction, PageTimelineAction, UserTimelineAction } from 'src/app/store/action';
import { Observable } from 'rxjs';
import { User } from 'src/app/models/User';
import { AuthSelector } from 'src/app/store/selector';

@Component({
	selector: 'app-post-comment-form',
	templateUrl: './post-comment-form.component.html',
	styleUrls: ['./post-comment-form.component.scss']
})
export class PostCommentFormComponent implements OnInit {
	@Input() public postComments;
	@Input() public module;
	@Input() public editComment:boolean = false;
	@Input() public commentValue:string = null;
	@Input() public commentId: number = null;
	@Input() public item: Post = null;
	@Input() public isPopup:boolean = false;
    @Input() public timeline: string = 'Feed';
    @Input() public popupMedia: any = null;
	@Output() public updateComment = new EventEmitter;
    @Output() public commentDelete = new EventEmitter();
	form: FormGroup;
	submitted = false;
	userObservable: Observable<User> = this.store.select(AuthSelector.User);
	user: User = null;
	@ViewChild('commentText', { static: false }) input: ElementRef<HTMLInputElement>;

	// Emoji properties
	show: boolean = false;

	constructor(
		private fb: FormBuilder,
		public gs: GlobalService,
		private itemService: ItemService,
		private store: Store<{}>,
	) { }

	get f() { return this.form.controls; }

	ngOnInit() {
		this.userObservable.subscribe(data => this.user = data);
		this.form = this.fb.group({
			CommentForm: this.fb.group({
				item_id: new FormControl(this.postComments),
				module: new FormControl(this.module),
				content: new FormControl('', [Validators.required]),
			})
		});
		if( this.isPopup ) {
			/*this.itemService.commentId$.subscribe(action => {
				this.postComments = action;
				this.form.get('CommentForm').patchValue({
					item_id: action,
				});
			});*/
		}

		if( this.editComment === true ) {
			this.form.get('CommentForm').patchValue({
				content: this.commentValue
			});
		}

	}

	postComment(form) {
		// console.log(form.value);
		// return false;
		this.submitted = true;
		if (!form.valid) {
			this.submitted = false;
			return;
		}
		if( this.editComment === true ) {
			const data = {
				CommentForm: {
					item_id: this.postComments,
					module: this.module,
					content: form.value.CommentForm.content,
				}
			}
			this.itemService.comment("POST", data, { comment_id: this.commentId }).subscribe(reply => {
				this.submitted = false;
				//this.form.reset();
				this.updateComment.emit(reply.data);
				this.gs.alert('You have successfully update comment in timeline.');
				this.form.get('CommentForm').patchValue({
					content: ''
				});
			});
		} else {
			this.itemService.comment("POST", form.value).subscribe((data: any) => {
				this.submitted = false;
				this.form.get('CommentForm').patchValue({
					content: ''
				});
				this.itemService.popupCommentList(data.data);
				console.log('this.item', this.item);
				if(this.item) {
					const item = Object.assign({}, this.item, { commentCount: Number(this.item.commentCount === null ? 0 : this.item.commentCount) + 1 });
					switch(this.timeline) {
						case 'Feed':
							this.store.dispatch(new FeedTimelineAction.Update(item));
							break;
						case 'Explore':
							this.store.dispatch(new ExploreTimelineAction.Update(item));
							break;
						case 'user_post':
							this.store.dispatch(new UserTimelineAction.Update(item));
							break;
						case 'group_post':
							this.store.dispatch(new GroupTimelineAction.Update(item));
							break;
						case 'page_post':
							this.store.dispatch(new PageTimelineAction.Update(item));
							break;
						case 'event_post':
							this.store.dispatch(new EventTimelineAction.Update(item));
							break;
						case 'community_post':
							this.store.dispatch(new CommunityTimelineAction.Update(item));
							break;
						case 'hidden_post':
							this.store.dispatch(new HiddenPostTimelineAction.Update(item));
							break;
						case 'hidedmy_post':
							this.store.dispatch(new HidedMyPostTimelineAction.Update(item));
							break;
						case 'hideall_post':
							this.store.dispatch(new HideAllPostTimelineAction.Update(item));
							break;
						case 'view':
							this.store.dispatch(new UserTimelineAction.View(item));
							break;
						default:;
					}
				} else {
					this.itemService.followCount('UpdatePopupCommentCount');
				}
				this.gs.alert('You have successfully post comment in timeline.');
				this.form.get('CommentForm').patchValue({
					item_id: this.postComments,
					module: this.module,
				});
			}, (error: Response) => {
				this.gs.handleErrors(error);
				this.submitted = false;
			});
		}
	}
	cancelUpdate() {
		this.updateComment.emit(null);
	}

	toggle() {
		this.show = !this.show;
	}

	addEmojiPost(selected: Emoji) {
		const emoji: string = (selected.emoji as any).native;
		const input = this.input.nativeElement;
		input.focus();
		if (document.execCommand){				
			var event = new Event('input');
			document.execCommand('insertText', false, emoji);
			return; 
		}
		const [start, end] = [input.selectionStart, input.selectionEnd]; 
		input.setRangeText(emoji, start, end, 'end');
	}
}

import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as TimelineReducer from '../reducer/timeline.reducer';

const getState = createFeatureSelector<TimelineReducer.State>(
    TimelineReducer.featureKey
);

export const feedList = createSelector(
    getState,
    state => state.feedlist
);

export const explore = createSelector(
    getState,
    state => state.explore
);

export const user_post = createSelector(
    getState,
    state => state.user_post
);

export const group_post = createSelector(
    getState,
    state => state.group_post
);

export const page_post = createSelector(
    getState,
    state => state.page_post
);

export const event_post = createSelector(
    getState,
    state => state.event_post
);

export const community_post = createSelector(
    getState,
    state => state.community_post
);

export const hidden_post = createSelector(
    getState,
    state => state.hidden_post
);

export const hidedmy_post = createSelector(
    getState,
    state => state.hidedmy_post
);

export const hideall_post = createSelector(
    getState,
    state => state.hideall_post
);

export const schedule_post = createSelector(
    getState,
    state => state.schedule_post
);

export const view = createSelector(
    getState,
    state => state.view
);

export const error = createSelector(
    getState,
    state => state.error
);

export const loading = createSelector(
    getState,
    state => state.loading
);
import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {GlobalService} from 'src/app/helpers/global.service';
import {environment} from 'src/environments/environment';
import {map, catchError} from 'rxjs/operators';
import {Subject} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class TimelineService {

    apiRoot: string = environment.apiUrl;
    page: any = 1;
    perPage: any = 5;
    params = null;

    private _commentId = new Subject<number>();
    private _popupCommentList = new Subject<any>();
    private _commentReplyId = new Subject<any>();
    private _followCount = new Subject<any>();
    private _thisUserAllCommentDelete = new Subject<string>();
    commentId$ = this._commentId.asObservable();
    popupCommentList$ = this._popupCommentList.asObservable();
    commentReplyId$ = this._commentReplyId.asObservable();
    followCount$ = this._followCount.asObservable();
    thisUserCommentDelete$ = this._thisUserAllCommentDelete.asObservable();

    constructor(
        private http: HttpClient,
        private gs: GlobalService,
    ) {
        this.setPage(this.page);
    }

    popupCommentList(data: number) {
        this._popupCommentList.next(data);
    }

    commentTriger(id: number) {
        this._commentId.next(id);
    }

    commentReplyId(data: any) {
        this._commentReplyId.next(data);
    }

    followCount(data: any) {
        this._followCount.next(data);
    }

    thisUserCommentDelete(data: any) {
        this._thisUserAllCommentDelete.next(data);
    }

    setPage(page, perPage = null) {
        this.page = page;
        if (perPage != null) {
            this.perPage = perPage;
        }
        let params = new HttpParams()
            .set('page', this.page)
            .set('per-page', this.perPage);
        this.params = {params: params};
        return this;
    }

    hideUserComment(params) {
        return this.http.post(this.apiRoot + '/post/hide-user-comment', [], {params: params})
            .pipe(
                map((response: any) => response.data),
                catchError(this.gs.handleHttpError)
            );
    } // Hide This Comment only

    hideUserAllPostComment(params) {
        return this.http.post(this.apiRoot + '/post/hide-user-all-posts-comment', [], {params: params})
            .pipe(
                map((response: any) => response.data),
                catchError(this.gs.handleHttpError)
            );
    } // Hide All Comments from This User

    hideUserPostComments(params) {
        return this.http.post(this.apiRoot + '/post/hide-user-post-comments', [], {params: params})
            .pipe(
                map((response: any) => response.data),
                catchError(this.gs.handleHttpError)
            );
    } // Hide All Comments From This User On This Post Only

    unhideUserComment(params) {
        return this.http.post(this.apiRoot + '/post/unhide-user-comment', [], {params: params})
            .pipe(
                map((response: any) => response.data),
                catchError(this.gs.handleHttpError)
            );
    } // UnHide This Comment only

    unhideUserPostComments(params) {
        return this.http.post(this.apiRoot + '/post/unhide-user-post-comments', [], {params: params})
            .pipe(
                map((response: any) => response.data),
                catchError(this.gs.handleHttpError)
            );
    } // UnHide All Comments From This User On This Post Only

    unhideUserAllPostComment(params) {
        return this.http.post(this.apiRoot + '/post/unhide-user-all-posts-comment', [], {params: params})
            .pipe(
                map((response: any) => response.data),
                catchError(this.gs.handleHttpError)
            );
    } // UnHide All Comments from This User

    userBreak(friend_id) {
        return this.http.post(this.apiRoot + '/post/block-user-temporary?friend_id=' + friend_id, [])
            .pipe(
                map((response: any) => response.data),
                catchError(this.gs.handleHttpError)
            );
    } // Take a 30 day break from seeing comments from this user


    postBlockUserPermanently(params) {
        return this.http.post(this.apiRoot + '/post/block-user-permanently', [], {params: params})
            .pipe(
                map((response: any) => response.data),
                catchError(this.gs.handleHttpError)
            );
    } // Block This User Permanently

    userLikeList(params?) {
        return this.http.get(this.apiRoot + '/post/reaction', {params: params}).pipe(
            map((response: any) => response.data),
            catchError(this.gs.handleHttpError)
        )
    }

    postReaction(data) {
        return this.http.post(this.apiRoot + '/post/reaction', data)
            .pipe(
                map((response: any) => response.data),
                catchError(this.gs.handleHttpError)
            );
    }

}

import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
 
import { StripeService, StripeCardComponent } from 'ngx-stripe';
import { StripeCardElementOptions, StripeElementsOptions } from '@stripe/stripe-js';

@Component({
	selector: 'app-stripe-payment',
	templateUrl: './stripe-payment.component.html',
	styleUrls: ['./stripe-payment.component.scss']
})
export class StripePaymentComponent implements OnInit {

	@ViewChild(StripeCardComponent, {static: false}) card: StripeCardComponent;

	cardOptions: StripeCardElementOptions = {
		style: {
			base: {
				iconColor: '#000',
				color: '#666',
				lineHeight: '40px',
				fontWeight: '300',
				fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
				fontSize: '18px',
				'::placeholder': {
					color: '#ddd'
				}
			}
		}
	};

	elementsOptions: StripeElementsOptions = {
		locale: 'en'
	};

	stripeTest: FormGroup;

	constructor(
		private fb: FormBuilder,
		private stripeService: StripeService) { }

	ngOnInit() {
		this.stripeTest = this.fb.group({
			name: ['', [Validators.required]]
		});
	}

	buy() {
		const name = this.stripeTest.get('name').value;
		this.stripeService
			.createToken(this.card.getCard(), { name })
			.subscribe(result => {
				if (result.token) {
					// Use the token to create a charge or a customer
					// https://stripe.com/docs/charges
					console.log(result.token.id);
				} else if (result.error) {
					// Error creating the token
					console.log(result.error.message);
				}
			});
	}

}

import { Directive, HostListener, Input } from '@angular/core';
import { GlobalService } from 'src/app/helpers/global.service';

@Directive({ selector: '[socialShare]' })

export class SocialShareDirective {

	@Input('socialShare') socialShare: any | null;
	@Input('url') url: any | null;

	constructor( public gs: GlobalService) {

	}

	@HostListener('click') onMouseEnter() {

		const url = this.url ? this.url : encodeURIComponent(window.location.href);

		switch (this.socialShare) {
			case 'fb':
				window.open(`https://www.facebook.com/sharer/sharer.php?u=${url}`);
				break;
			case 'tw':
				window.open(`https://twitter.com/home?status=${url}`);
				break;
			case 'in':
				window.open(`https://www.linkedin.com/sharing/share-offsite/?url=${url}`);
				break;
			case 'tr':
				window.open(`https://www.tumblr.com/widgets/share/tool?canonicalUrl=${url}`);
				break;
			case 'dg':
				window.open(`http://digg.com/submit?url=${url}`);
				break;
			case 'rd':
				window.open(`https://reddit.com/submit?url=${url}`);
				break;
			case 'pn':
				window.open(`https://www.pinterest.com/pin/find/?url=${url}`);
				break;
			case 'cpl':
				let selBox = document.createElement('textarea');
				selBox.style.position = 'fixed';
				selBox.style.left = '0';
				selBox.style.top = '0';
				selBox.style.opacity = '0';
				selBox.value = url;
				document.body.appendChild(selBox);
				selBox.focus();
				selBox.select();
				document.execCommand('copy');
				document.body.removeChild(selBox);
				this.gs.alert('Copied');
				break;
			default:
				break;
		}

	}

}
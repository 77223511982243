<a href="javascript:void(0);" ngbDropdownToggle class="nav-link" (click)="loadFirst()">
	<!-- <img src="/assets/img/head-icon-3.png" alt="">  -->
	<i class="fas fa-bell"></i>
	<span class="badge badge-danger" *ngIf="user.userProfile?.notifications > 0">{{ user.userProfile?.notifications }}</span>
</a>
<div class="dropdown-menu-right dropdown-menu-media" ngbDropdownMenu>

	<div class="dropdown-arrow"></div>
	<div class="dropdown-header text-center">
		<div>
			<span class="font-18"><strong *ngIf="notifications">New</strong> Notifications</span>
		</div>
		<a class="text-muted font-13" routerLink="/user/notifications">view all</a>

	</div>
	<div class="w-100" *ngIf="requestLoading">
		<ng-scrollbar *ngIf="notifications" class="timeline pl-0 scroller2" [ngClass]="{'scroller': notifications.length > 6}" thumbClass="white-scrollbars" appearance="compact" [sensorDisabled]="true">
			<div class="scroll" scrollViewport infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50" [scrollWindow]="false" (scrolled)="onScroll($event)" >

				<div class="timeline-item" *ngFor="let noti of notifications" [ngClass]="{'bg-light': noti.status === 'UnRead', 'bg-white': noti.status === 'Read'}">
					<ng-container [ngSwitch]="noti.action">
						<ng-container *ngSwitchCase="'update-family-members'">
							<a [routerLink]="['/timeline/timeline-review']" (click)="readNotification(noti)">
								<i class="fas fa-heart timeline-icon"></i>
								<strong>{{ noti.textJson.username }}</strong> listed you as <strong>{{ noti.textJson.relation }}</strong>. To add this to your Timeline, go to Timeline Review.
								<small class="float-right text-muted ml-2 nowrap">{{ noti.created_at * 1000 | date:'MMM d, h:mm' }}</small>
							</a>
						</ng-container>
						<ng-container *ngSwitchDefault>
							<a [routerLink]="gs.notificationLink(noti)" (click)="readNotification(noti)">
								<i class="fas fa-check timeline-icon"></i>
								{{ noti.notification }}
								<small class="float-right text-muted ml-2 nowrap">{{ noti.created_at * 1000 | date:'MMM d, h:mm' }}</small>
							</a>
						</ng-container>
					</ng-container>
				</div>
			</div>
			<div class="timeline-item justify-content-center pt-3" *ngIf="(notifications.length == 0)"> Notifications not found </div>
		</ng-scrollbar>
	</div>
	<div class="timeline-item d-flex justify-content-center align-items-center" *ngIf="loading" style="min-height: 200px;">
		<div class="spinner-border text-primary">
			<span class="sr-only">Loading...</span>
		</div>
	</div>
</div>
<div class="card custom-card">
	<div class="card-body pb-0">
		<h3 class="card-title">Page you may like</h3>
	</div>
	<ul class="list-group list-group-flush suggestion-list">
		<li class="list-group-item d-flex align-items-center" *ngFor="let page of pages | async">
			<div class="image" *ngIf="page.user">
				<img src="{{ page.avatar }}" alt="" class="img-fluid rounded-circle border" width="24" height="24">
			</div>
			<div class="caption d-flex align-items-center">
				<h6 class="text-truncate">
					<a routerLink="/pages/view/{{page.id}}">{{ page.title }}</a> <br>
					<small>@{{ page.username }}</small>
				</h6>
				<div class="button">
					<button
						class="btn btn-sm text-nowrap btn-outline-secondary"
						*ngIf="page.pageLike === 'No'"
						(click) ="pageLike(page)">
							<i class="far fa-thumbs-up"></i> Follow
					</button>
					<button
						class="btn btn-sm text-nowrap btn-primary"
						*ngIf="page.pageLike === 'Yes'"
						(click) ="pageLike(page)">
							<i class="far fa-thumbs-up"></i> Followed
					</button>
				</div>
			</div>
		</li>
		<ng-container *ngIf="pages | async as lengthCount">
			<li class="list-group-item d-flex align-items-center" style="padding-left: 18px" *ngIf="lengthCount.length === 0">
				Page not found
			</li>
		</ng-container>
	</ul>
	<div class="d-flex align-items-center justify-content-center" *ngIf="loading | async">
		<mat-spinner color="primary" class="spinner-size"></mat-spinner>
	</div>
</div>

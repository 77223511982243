import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as NotificationsReducer from '../reducer/notifications.reducer';

const getState = createFeatureSelector<NotificationsReducer.State>(
    NotificationsReducer.featureKey
);

export const notifications = createSelector(
    getState,
    state => state.notifications
);

export const pagination = createSelector(
    getState,
    state => state.pagination
);

export const loading = createSelector(
    getState,
    state => state.loading
);

export const loadingMore = createSelector(
    getState,
    state => state.loadingMore
);

export const error = createSelector(
    getState,
    state => state.error
);
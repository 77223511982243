import { Action } from '@ngrx/store';

export enum PageActionTypes {
    PAGE_MAY_LIKE_PARAMS = '[PAGE_MAY_LIKE_PARAMS] Page May Like Params',
    PAGE_MAY_LIKE = '[PAGE_MAY_LIKE] Page May Like',
    ITEM_FAILURE = '[ITEM_FAILURE] failed',
}

export class MayLikeParams implements Action {
    readonly type = PageActionTypes.PAGE_MAY_LIKE_PARAMS;
    constructor(public options: { params?: any, key?: string}) { }
} 

export class MayLikes implements Action {
    readonly type = PageActionTypes.PAGE_MAY_LIKE;
    constructor(public maylike: any) { }
} 

export class failure implements Action {
    readonly type = PageActionTypes.ITEM_FAILURE;
    constructor(public error: any) { }
}

export type PageAction = MayLikeParams | MayLikes | failure;
<main class="all-content user-layout">
	<div class="container">
		<div class="row">
			<div class="col-12">
				<div class="user-banner" *ngIf="user" style="min-height: 256px">
					<img src="{{user.banner}}" alt="" class="img-fluid" width="1170" height="270" style="max-height: 270px;">
					<div class="button">
						<a mat-stroked-button *ngIf="user.is_follow === false" class="mr-2" (click)="followUser(user, 'follow')"><i class="fas fa-rss"></i> Follow</a>
						<a mat-flat-button *ngIf="user.is_follow === true" color="primary" class="mr-2" (click)="followUser(user, 'unfollow')"><i class="fas fa-rss"></i> UnFollow</a>

						<a mat-stroked-button *ngIf="user.friendState === 0" (click)="addFriend(user, 'add')"><i class="fas fa-user-plus"></i> Add Friend</a>
						<a mat-flat-button color="primary" *ngIf="user.friendState === 1" (click)="unFriend(user)"><i class="fas fa-user-minus"></i> Unfriend</a>
						<a mat-stroked-button *ngIf="user.friendState === 3" (click)="declineFriend(user)"><i class="fas fa-user-friends"></i> Request Sent</a>
						<a mat-flat-button *ngIf="user.friendState === 2" (click)="addFriend(user)" color="primary"><i class="fas fa-user-friends"></i> Confirm</a>
						<a mat-stroked-button *ngIf="user.friendState == 2" (click)="declineFriend(user)"><i class="fas fa-user-times"></i> Decline</a>
						<a mat-stroked-button *ngIf="user.friendState == 5" [routerLink]="['/user/setting/block-setting']"><i class="fas fa-unlock"></i> Unblock</a>

						<div class="btn-group" role="group" aria-label="Basic example">
							<a mat-stroked-button class="ml-2" (click)="sendMessage()"><i class="fab fa-facebook-messenger"></i> Message</a>
							<!-- <a mat-flat-button [matMenuTriggerFor]="menu"><i class="fas fa-ellipsis-v"></i></a>
							<mat-menu #menu="matMenu">
								<button mat-menu-item>Item 1</button>
								<button mat-menu-item>Item 2</button>
							</mat-menu> -->
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-lg-3 col-12">
				<div class="card profile-card absolute-pic">
					<div class="card-header"></div>
					<div class="card-body">
						<div class="profile d-flex flex-wrap">
							<div class="image" *ngIf="user">
								<img src="{{user.avatar}}" alt="" class="img-fluid rounded-circle" width="100" height="100">
								<h6 >{{user.name}}</h6>
							</div>
							<div class="heading text-center" *ngIf="user">
								<h6><a routerLink="/user/friend/timeline/{{ user.username }}">{{ user.name }}</a></h6>
								<p >@{{ user.username }}</p>
							</div>
						</div>
						<ul class="d-flex" *ngIf="user">
							<li>
								<a [routerLink]="['/user/friend/friends/', user.username]">Friends <br>
									<b *ngIf="user">{{user.friends_count}}</b>
								</a>
							</li>
							<li>
								<a [routerLink]="['/user/friend/followers/', user.username]">Followers <br>
									<b *ngIf="user">{{user.followers_count}}</b>
								</a>
							</li>
							<li>
								<a [routerLink]="['/user/friend/following/', user.username]">Following <br>
									<b *ngIf="user">{{user.followings_count}}</b>
								</a>
							</li>
						</ul>
					</div>
				</div> <!-- Card profile end routerLink="/user/timeline-view/friend-view/{{user.username}}" routerLinkActive="active" -->
			</div>

			<div class="col-lg-9 col-12">
				<div class="timeline-menu">
					<ul class="nav nav-fill" *ngIf="user">
						<li class="nav-item">
							<a class="nav-link" routerLink="/user/friend/timeline/{{user.username}}" routerLinkActive="active">Home</a>
						</li>
						<li class="nav-item"><a class="nav-link" routerLink="/user/friend/about/{{user.username}}" routerLinkActive="active">About</a></li>
						<li class="nav-item">
							<a class="nav-link" routerLink="/user/friend/friends/{{user.username}}" routerLinkActive="active">
							Friends <span *ngIf="user">({{user.friends_count}})</span>
							</a>
						</li>
						<li class="nav-item"><a class="nav-link" routerLink="/photos/friend/my-photo/{{user.username}}" routerLinkActive="active">Photos</a></li>
						<li class="nav-item"><a class="nav-link" routerLink="/groups/friend/list/{{user.username}}" routerLinkActive="active">Groups</a></li>
						<li class="nav-item"><a class="nav-link" routerLink="/events/friend/list/{{user.username}}" routerLinkActive="active">Events</a></li>
						<li class="nav-item"><a class="nav-link" routerLink="/pages/friend/list/{{user.username}}" routerLinkActive="active">Pages</a></li>
						<!-- <li class="nav-item"><a class="nav-link" routerLink="/videos/friend/list/{{user.username}}" routerLinkActive="active">Videos</a></li> -->
					</ul>
				</div>

				<router-outlet></router-outlet>
			</div>
		</div>
	</div>
</main>


<ng-container *ngIf="requestLoading else loaded">
	<div *ngIf="items && items?.length == 0">News not found</div>
	<owl-carousel-o [options]="storyOptions">
		<ng-template carouselSlide *ngFor="let news of items">
			<ul class="item {{widgetClass}}">
				<li *ngFor="let item of news">
					<div class="ctn-new-list">
						<ng-container *ngIf="item.external_url else loaded2">
							<a [href]="item.external_url" target="_blank">
								<div class="ctn-news-img">
									<img src="{{item.live_image_url}}" alt="Image">
								</div>
								<div class="detail">
									<span>{{item.title | slice:0:50}}</span>
									<p [innerHtml]="item.content | slice:0:70"></p>
									<a [href]="item.external_url ? item.external_url : '/news/view/'+item.slug" target="_blank" class="sports_readmore">Read More ....</a>
								</div>
							</a>
						</ng-container>
						<ng-template #loaded2>
							<a routerLink="/news/view/{{item.slug}}">
								<div class="ctn-news-img">
									<img src="{{ item.live_image_url }}" alt="Image">
								</div>
								<div class="detail">
									<span>{{item.title | slice:0:50}}</span>
									<p [innerHtml]="item.content | slice:0:70"></p>
									<a [href]="item.external_url ? item.external_url : '/news/view/'+item.slug" target="_blank" class="sports_readmore">Read More ....</a>
								</div>
							</a>
						</ng-template>
					</div>
				</li>
			</ul>
		</ng-template>
	</owl-carousel-o>
</ng-container>
<ng-template #loaded>
	<div class="d-flex align-items-center justify-content-center w-100">
		<mat-spinner color="primary" class="spinner-size"></mat-spinner>
	</div>
</ng-template>

import { Component, OnInit } from '@angular/core';
import { GlobalService } from 'src/app/helpers/global.service';
import { Router, NavigationExtras } from '@angular/router';
import { UserService } from 'src/app/service/user.service';
import { Store } from '@ngrx/store';
import { AuthActions, UserAction } from 'src/app/store/action';
import { Observable } from 'rxjs';
import { User } from 'src/app/models/User';
import { UserSelector } from 'src/app/store/selector';

@Component({
	selector: 'app-friend-suggestion',
	templateUrl: './friend-suggestion.component.html',
	styleUrls: ['./friend-suggestion.component.scss'],
})
export class FriendSuggestionComponent implements OnInit {
	loader: Observable<boolean> = this.store.select(UserSelector.loading);
	friends: Observable<User[]> = this.store.select(UserSelector.suggestions);

	constructor(
		private userService: UserService,
		public gs: GlobalService,
		private router: Router,
		private store: Store<{}>,
	) { }

	ngOnInit() {
		//this.loadFriend();
		this.friends.subscribe(data => {
			(data === null) && this.store.dispatch(new UserAction.SuggestionParams({ params: { key: 'suggested-friends', page: 1 } }));
		})
	}

	loadFriend() {
		this.userService.friends("GET", null, { key: 'suggested-friends' }).subscribe((data: any) => {
			this.friends = data ? data : [];
		}, (error: Response) => {
			this.gs.handleErrors(error);
		});
	}

	addFriend(friend) {
		this.userService.friends("POST", null, { friend_id: friend.id }).subscribe((data: any) => {
				this.gs.alert('You have successfully add friend list.');
				let item = Object.assign({}, friend, { friendState: 3 });
				this.store.dispatch(new UserAction.SuggestionUpdate(item));
			}, (error: Response) => {
				this.gs.handleErrors(error);
			});
	}

	declineFriend(friend) {
		this.userService.friends("DELETE", null, { friend_id: friend.id }).subscribe((data: any) => {
				this.gs.alert('Friend request decline successfully.', 'bg-danger');
				let item = Object.assign({}, friend, { friendState: 5 });
				this.store.dispatch(new UserAction.SuggestionUpdate(item));
			}, (error: Response) => {
				this.gs.handleErrors(error);
			});
	}

	followUser(friend, action) {
		const method = (action === 'follow') ? "POST" : "DELETE";
		const bg = (action === 'follow') ? "bg-success" : "bg-danger";
		this.userService.follow(method, null, { following_id: friend.id }).subscribe(data => {
			this.gs.alert(`You have successfully ${action} this user.`, bg);
			let item = Object.assign({}, friend, { is_follow: (action === 'follow') ? true : false });
			this.store.dispatch(new UserAction.SuggestionUpdate(item));
			this.viewProfile();
		}, (error: Response) => {
			this.gs.handleErrors(error);
		});
	}

	mainSearch() {
		let navigationExtras: NavigationExtras = {
			queryParams: {
				"search": ''
			}
		};
		this.router.navigate(['/search'], navigationExtras);
	}

	viewProfile() {
		this.userService.profile().subscribe((response: any) => {
			this.store.dispatch(new AuthActions.loginSuccess({ user: response }));
		});
	}

}

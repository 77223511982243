import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { MustMatch } from 'src/app/helpers/must-match.validator';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { CommonService } from 'src/app/service/common.service';
import { GlobalService } from 'src/app/helpers/global.service';
import { Title } from '@angular/platform-browser';

@Component({
	selector: 'app-reset-password',
	templateUrl: './reset-password.component.html',
	styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
	submitted = false;
	title = 'Reset Password';
	hide = false;

	error = {};

	token = null;

	reset: FormGroup;

	errors = [];

	get f() { return this.reset.controls; }

	constructor(
		private fb: FormBuilder,
		private router: Router,
		private siteService: CommonService,
		public gs: GlobalService,
		private titleService: Title,
		private route: ActivatedRoute
	) { }

	ngOnInit() {
		this.titleService.setTitle(this.gs.mainTitle + ' | ' + this.title);

		this.reset = this.fb.group({
			password: new FormControl('', [Validators.required, Validators.minLength(6), Validators.maxLength(24)]),
			password_repeat: new FormControl('', [Validators.required, Validators.minLength(6), Validators.maxLength(24)])
		}, { validators: MustMatch('password', 'password_repeat') });


		this.route.queryParams.subscribe(params => {
			//console.log(params.token);
			this.token = params.token;
		});

	}

	onReset(reset) {
		this.submitted = true;
		if (!reset.valid) { return false; }
		this.gs.clearErrorMessages();
		//console.log(this.token);
		this.siteService.resetPassword(reset.value, this.token).subscribe((data: any) => {
			//console.log(data);
			this.submitted = false;
			if (data.code) {
				this.gs.alert(data.message);
				this.router.navigate(['/']);
			} else {
				this.gs.alert(data.message, 'bg-danger');
			}
		}, (error: Response) => {
			this.gs.handleErrors(error);
			this.submitted = false;
		});
	}

}

<!-- <div class="livetxt" *ngIf="(currentVideo && currentVideo.postVideos) && (currentVideo.postVideos.is_live)">Live Video</div> -->
<div class="card page-card border-0 card-one">
    <div class="card-body p-0">
        <div class="row">
            <div class="col-sm-8">
                <app-public-video-box *ngIf="currentVideo" [item]="currentVideo"></app-public-video-box>
            </div>
            <div class="col-sm-4">
                <div *ngIf="(currentVideo && currentVideo.postVideos)">
                    <div *ngIf="gs.identity">
                        <app-broadcast-message [post_id]="currentVideo.id"></app-broadcast-message>
                    </div>
                    <div *ngIf="!gs.identity">
                        <h3 style="text-align: center; font-size: 18px; padding-top: 150px;">Please login to see the messages</h3>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div [ngClass]="{'hide-comment': ((comment.status === 3) || (comment.status === 4) || (comment.status === 5))}">

	<div class="bg-list">
		<div class="d-flex group mb-1">
			<div class="profile">
				<img src="{{comment.user.avatar}}" class="img-fluid border rounded-circle" alt="">
			</div>
			<div class="caption col pr-0">
				<h6>
					<a routerLink="." (click)="viewFriend(comment.user.username)">{{comment.user.name}}</a>
					<div class="action">
						<div class="dropdown">
							<a class="dropdown-toggle tooltip1" data-toggle="dropdown">
								<!-- <span class="tooltiptext">Options</span> -->
								<i class="fas fa-ellipsis-h"></i>
							</a>
							<div class="dropdown-menu comment-dropdown">
								<a class="dropdown-item" mat-button color="primary" *ngIf="(comment.user.id === user.id)" (click)="editComment()">Edit</a>
								<a class="dropdown-item" mat-button color="primary" *ngIf="(comment.user.id === user.id)" (click)="deleteComment()">Delete</a>
								<ng-container *ngIf="(owner.id === user.id) && (comment.user.id != owner.id)">
									<a class="dropdown-item" mat-button color="primary" (click)="deleteComment()">Delete</a>
									<a *ngIf="comment.status === 1" class="dropdown-item" mat-button color="primary" (click)="onlyComment(comment, 'hide')">Hide This Comment only</a>
									<a *ngIf="comment.status === 3" class="dropdown-item" mat-button color="primary" (click)="onlyComment(comment, 'unhide')">Unhide This Comment only</a>
									<a *ngIf="comment.status === 1" class="dropdown-item" mat-button color="primary" (click)="postComments(comment, 'hide')">Hide All Comments From This User On This Post Only</a>
									<a *ngIf="comment.status === 4" class="dropdown-item" mat-button color="primary" (click)="postComments(comment, 'unhide')">Unhide All Comments From This User On This Post Only</a>
									<a *ngIf="comment.status === 1" class="dropdown-item" mat-button color="primary" (click)="allPostsComment(comment, 'hide')">Hide All Comments from This User</a>
									<a *ngIf="comment.status === 5" class="dropdown-item" mat-button color="primary" (click)="allPostsComment(comment, 'unhide')">Unhide All Comments from This User</a>
									<!-- <a class="dropdown-item" mat-button color="primary" (click)="block(comment, 'user')">Block User Comment This Post</a> -->
									<!-- <a class="dropdown-item" mat-button color="primary" (click)="blockUserComments(comment)">Block user Comments</a> -->
									<a class="dropdown-item" *ngIf="(myTimeline) && (comment.user.friendState === 1)" mat-button color="primary" (click)="blockUser('permanently')">Block This User Permanently</a>
									<a class="dropdown-item" *ngIf="(myTimeline) && (comment.user.friendState === 5)" mat-button color="primary" (click)="blockUser('permanently')">Unblock This User Permanently</a>
									<a class="dropdown-item" *ngIf="(myTimeline) && (comment.user.friendState === 1)" mat-button color="primary" (click)="blockUser('temporary')">Take a 30 day break from seeing comments from this user</a>
									<a class="dropdown-item" *ngIf="(myTimeline) && (comment.user.friendState === 4)" mat-button color="primary" (click)="blockUser('temporary')">Take a 30 day unbreak from seeing comments from this user</a>
								</ng-container>

								<ng-container *ngIf="(comment.user.id !== user.id)">
									<a class="dropdown-item" mat-button color="primary"
										(click)="reportComment(comment.id)">Report This Comment / User</a>
									<a *ngIf="!comment.user.is_follow" class="dropdown-item" mat-button color="primary" (click)="followUser(comment, 'follow')">
										Follow {{ comment.user.name }} Moments etc
									</a>
									<a *ngIf="comment.user.is_follow" class="dropdown-item" mat-button color="primary" (click)="followUser(comment, 'unfollow')">
										Unfollow {{ comment.user.name }} Moments etc
									</a>
								</ng-container>

							</div>
						</div>
					</div>
				</h6>
				<div class="text" *ngIf="activeEditComment">
					<read-more [text]="comment.content" [maxLength]="150"></read-more>
				</div>
				<div class="edit_comment" *ngIf="!activeEditComment">
					<app-post-comment-form [postComments]="postId" [module]="module" [editComment]="activeEditForm"
						[commentValue]="comment.content" [commentId]="comment.id" (updateComment)="updateCmnt($event)">
					</app-post-comment-form>
				</div>
			</div>
		</div>
	</div>
	<div class="likes d-flex align-items-center">
		<button type="button" class="btn btn-link" (click)="likeComment(comment.id, 'comment')"
			[ngClass]="comment.isLike ? 'text-primary' : 'text-light'">
			<i [ngClass]="comment.isLike ? 'fas fa-thumbs-up' : 'far fa-thumbs-up'"></i>
			Like <b *ngIf="comment.reactionCount > 0">({{comment.reactionCount}})</b>
		</button>
		<button type="button" class="btn btn-link text-primary" data-toggle="collapse"
			[attr.data-target]="'#commentComment-' + comment.id">
			<i class="fas fa-comments"></i> Comment <b
				*ngIf="comment.userComments.length > 0">({{comment.userComments.length}})</b>
		</button>
		<button type="button" class="btn btn-link btn-share" (click)="commentShare()"><i class="fas fa-sync-alt"></i>
			ReChat <b *ngIf="comment.shareCount > 0">({{comment.shareCount}})</b></button>
		<time>{{comment.created_at * 1000 | date:'MMM d, yy, h:mm a'}}</time>
	</div>
	<div class="comments collapse" [id]="'commentComment-' + comment.id">
		<app-post-comment-reply-form [postComments]="comment.id"></app-post-comment-reply-form>
	</div>

	<div class="reply-comments" *ngIf="comment.userComments">
		<ul class="list">
			<ng-container *ngFor="let userComt of comment.userComments">
				<li>
					<div class="bg-list">
						<div class="d-flex group">
							<div class="profile">
								<img src="{{userComt.user.avatar}}" class="img-fluid border rounded-circle" alt="">
							</div>
							<div class="caption col pr-0">
								<h6>
									<a routerLink="." (click)="viewFriend(userComt.user.username)">{{userComt.user.name}}</a>
									<div class="action">
										<div class="dropdown">
											<a class="dropdown-toggle tooltip1" data-toggle="dropdown">
												<!-- <span class="tooltiptext">Edit or delete this</span> -->
												<i class="fas fa-ellipsis-h"></i>
											</a>
											<div class="dropdown-menu comment-dropdown">
												<a class="dropdown-item" mat-button color="primary" *ngIf="(userComt.user.id === user.id)" (click)="replyEditComment(userComt)">Edit</a>
												<a class="dropdown-item" mat-button color="primary" *ngIf="(userComt.user.id === user.id)" (click)="replyDeleteComment(userComt)">Delete</a>
												<ng-container *ngIf="(owner.id === user.id) && (userComt.user.id !== owner.id)">
													<a class="dropdown-item" mat-button color="primary" (click)="replyDeleteComment(userComt)">Delete This Comment only</a>
													<!-- <a class="dropdown-item" mat-button color="primary" (click)="hideCommentThisOnPost(userComt)">Hide All Comments From This User On This Post Only</a>
													<a class="dropdown-item" mat-button color="primary" (click)="hideAllCommentThisUser(userComt)">Hide All Comments from This User</a>
													<a class="dropdown-item" mat-button color="primary" (click)="blockUserPermanently(userComt)">Block This User Permanently</a>
													<a class="dropdown-item" mat-button color="primary" (click)="userBreak(userComt)">Take a 30 day break from seeing comments from this user</a> -->
												</ng-container>
	
												<ng-container *ngIf="(userComt.user.id !== user.id)">
													<a class="dropdown-item" mat-button color="primary" (click)="reportComment(userComt.id)">Report This Comment / User</a>
												</ng-container>
											</div>
										</div>
									</div>
								</h6>
								<div class="text" *ngIf="replyEditFormContent"
									[ngClass]="(replyEditFormId2 === userComt.id) ? 'd-none' : 'd-block'">
									<read-more [text]="userComt.content" [maxLength]="150"></read-more>
								</div>
								<div class="edit_comment" *ngIf="(replyEditFormId === userComt.id)">
									<app-post-comment-reply-form [postComments]="comment.id" [editComment]="activeEditForm" [commentValue]="userComt.content" [replyId]="userComt.id" (replyUpdateComment)="replyUpdateCmnt($event, userComt)">
									</app-post-comment-reply-form>
								</div>
							</div>
						</div>
					</div>
					<div class="likes d-flex align-items-center">
						<button type="button" class="btn btn-link text-dark" (click)="likeComment(userComt.id, 'reply')"
							[ngClass]="userComt.isLike ? 'text-primary' : 'text-light'">
							<i [ngClass]="userComt.isLike ? 'fas fa-thumbs-up' : 'far fa-thumbs-up'"></i>
							Like <b *ngIf="userComt.reactionCount > 0">({{userComt.reactionCount}})</b>
						</button>
	
						<time>{{userComt.created_at * 1000 | date:'MMM d, yy, h:mm a'}}</time>
					</div>
				</li>
				<li *ngFor="let userComt of userComt.userComments">
					sdfds {{userComt.id}}
				</li>
			</ng-container>
		</ul>
	</div>
	
</div>
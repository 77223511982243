<div class="blog-list w-100 float-left py-4">
	<div class="container">
		<div class="row">
			<div class="col-md-6 col-12 mx-auto">
				<!-- <div class="card page-card">
					<div class="card-header d-flex flex-wrap align-items-center">
						<h3 class="card-title col pl-0">Email Verification</h3>
					</div>
					<div class="card-body">
						Welcome
					</div>
				</div> -->
			</div>
		</div>
	</div>
</div>
 
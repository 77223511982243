import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { GlobalService } from 'src/app/helpers/global.service';
import { TimelineService } from 'src/app/modules/timeline/services/timeline.service';
import { CommonService } from 'src/app/service/common.service';
import { ItemService } from 'src/app/service/item.service';
import { Emoji } from '@ctrl/ngx-emoji-mart/ngx-emoji';
import { Observable } from 'rxjs';
import { User } from 'src/app/models/User';
import { AuthSelector } from 'src/app/store/selector';
import { Store } from '@ngrx/store';

export class PostCommentReplyText {
    constructor(public text: string) {}
}

@Component({
	selector: 'app-post-comment-reply-form',
	templateUrl: './post-comment-reply-form.component.html',
	styleUrls: ['./post-comment-reply-form.component.scss']
})
export class PostCommentReplyFormComponent implements OnInit {
	@Input() public postComments;
	@Input() public editComment:boolean = false;
	@Input() public commentValue:string = null;
	@Input() public replyId:number = null;
	@Output() public replyUpdateComment = new EventEmitter;
	form: FormGroup;
	submitted = false;
	userObservable: Observable<User> = this.store.select(AuthSelector.User);
	user: User = null;
	@ViewChild('replyComment', { static: false }) input: ElementRef<HTMLInputElement>;

	// Emoji properties
	show: boolean = false;
	model = new PostCommentReplyText('');

	constructor(
		private fb: FormBuilder,
		public gs: GlobalService,
		public timelineService: TimelineService,
		public itemService: ItemService,
		public commonService: CommonService,
        private store: Store<{}>,
	) { }

	get f() { return this.form.controls; }

	ngOnInit() {
		//console.log(this.postComments);
		this.userObservable.subscribe(data => this.user = data);
		this.form = this.fb.group({
			CommentForm: this.fb.group({
				item_id: new FormControl(this.postComments),
				module: new FormControl('UserComment'),
				content: new FormControl('', [Validators.required]),
			})
		});

		if( this.editComment === true ) {
			this.form.get('CommentForm').patchValue({
				content: this.commentValue
			});
			this.model.text = this.commentValue;
		}
	}

	postCommentReply(form) {
		this.submitted = true;
		if (!form.valid) {
			this.submitted = false;
			return;
		}
		if( this.editComment === true ) {
			const data = {
					CommentForm :{ 
						item_id: this.postComments,
						module: "UserComment",
						content: form.value.CommentForm.content,
						//commentId: this.postComments,
						//replyId: this.replyId
					}
			  	}
			this.itemService.comment("POST", data, { reply_id: this.replyId }).subscribe(reply => {
				this.submitted = false;
				this.form.reset();
				this.replyUpdateComment.emit(reply.data);
			});

		} else {
			this.itemService.comment("POST", form.value).subscribe((data: any) => {
				this.submitted = false;
				this.form.reset();;
				//this.itemService.commentReplyId(data.data);
				this.replyUpdateComment.emit(data.data);
				this.form.get('CommentForm').patchValue({
					item_id: this.postComments,
					module: 'UserComment',
				});
			}, (error: Response) => {
				this.gs.handleErrors(error);
				this.submitted = false;
			});
		}
	}

	cancelUpdate() {
		this.replyUpdateComment.emit(null);
	}

	toggle() {
		this.show = !this.show;
	}

	addEmojiPost(selected: Emoji) {
		const emoji: string = (selected.emoji as any).native;
		const input = this.input.nativeElement;
		input.focus();
		if (document.execCommand){				
			var event = new Event('input');
			document.execCommand('insertText', false, emoji);
			return; 
		}
		const [start, end] = [input.selectionStart, input.selectionEnd]; 
		input.setRangeText(emoji, start, end, 'end');
	}

}

<form [formGroup]="form" (ngSubmit)="submit(form)">
	<div class="header bg-primary text-white">
		<h3 mat-dialog-title class="d-flex align-items-center">
			<span class="col pl-0">{{heading}}</span>
			<button mat-button type="button" (click)="closeDialog()"><i class="fas fa-times"></i></button>
		</h3>
	</div>
	<mat-dialog-content>
		<div class="row">
			<div class="col-12">
				<mat-form-field appearance="outline">
					<mat-label>Comment</mat-label>
          <textarea matInput matTextareaAutosize minRows="6" formControlName="content"></textarea>
					<mat-error *ngIf="form.get('content').hasError('required') && (submitted || form.get('content').touched)">Comment is required</mat-error>
				</mat-form-field>
			</div>
		</div>
	</mat-dialog-content>
	<mat-dialog-actions>
			<button mat-raised-button color="primary" [ladda]="submitted" type="submit" class="px-5 py-1 text-uppercase">Submit</button>
	</mat-dialog-actions>
</form>
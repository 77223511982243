import { Action } from '@ngrx/store';
import { Post } from 'src/app/models/Post';

export enum FeedTimelineTypes {
    FEED_FAILURE = '[FEED_FAILURE] failed',
    FEED_LIST_PARAMS = '[FEED_LIST_PARAMS] Feed List Params',
    FEED_LIST = '[FEED_LIST] Feed List',
    FEED_LIST_MORE = '[FEED_TIMELINE_LIST_MORE] Feed List More',
    ADD = '[FEED_TIMELINE_ADD] FEED Add',
    ADD_INITIALIZED = '[FEED_TIMELINE_ADD_INITIALIZED] Feed Add Initialized',
    UPDATE = '[FEED_TIMELINE_UPDATE] FEED Update',
    UPDATE_INITIALIZED = '[FEED_TIMELINE_UPDATE_INITIALIZED] Feed Update Initialized',
    DELETE = '[FEED_TIMELINE_UPDATE] FEED Detele',
    DELETE_INITIALIZED = '[FEED_TIMELINE_DELETE_INITIALIZED] Feed Detele Initialized',
}

export class ListParams implements Action {
    readonly type = FeedTimelineTypes.FEED_LIST_PARAMS;
    constructor(public options: { params?: any, key?: string}) { }
} 

export class List implements Action {
    readonly type = FeedTimelineTypes.FEED_LIST;
    constructor(public feedlist: any) { }
}

export class More implements Action {
    readonly type = FeedTimelineTypes.FEED_LIST_MORE;
    constructor(public feedmore: any) { }
}

export class Add implements Action {
    readonly type = FeedTimelineTypes.ADD;
    constructor(public item: Post) { }
}

export class AddInitialized implements Action {
    readonly type = FeedTimelineTypes.ADD_INITIALIZED;
    constructor(public params: any) { }
}

export class Update implements Action {
    readonly type = FeedTimelineTypes.UPDATE;
    constructor(public item: Post) { }
}

export class UpdateInitialized implements Action {
    readonly type = FeedTimelineTypes.UPDATE_INITIALIZED;
    constructor(public params: any) { }
}

export class Delete implements Action {
    readonly type = FeedTimelineTypes.DELETE;
    constructor(public item: Post) { }
}

export class DeleteInitialized implements Action {
    readonly type = FeedTimelineTypes.DELETE_INITIALIZED;
    constructor(public params: any) { }
}

export class failure implements Action {
    readonly type = FeedTimelineTypes.FEED_FAILURE;
    constructor(public error: any) { }
}


export type FeedTimelineAction = ListParams | List | More | Add | AddInitialized | Update | UpdateInitialized | Delete | DeleteInitialized | failure;
<mat-dialog-content class="like-user-list">
	<div class="model-inner" *ngIf="requestLoading">
		<button class="close-button" mat-button type="button" (click)="closeDialog()"><i class="fas fa-times"></i></button>
		<mat-tab-group [animationDuration]="'0ms'">
			<mat-tab>
				<ng-template mat-tab-label>
					All <span class="ml-2">{{allCount}}</span>
				</ng-template>
				<div class="user-list css-scroll" *ngIf="allLikes" infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50" [scrollWindow]="false" (scrolled)="onScrollAll($event)">
					<div class="media text-muted py-2 px-3 border-bottom border-gray" *ngFor="let like of allLikes">
						<img alt="" class="img-fluid rounded-circle border" height="50" width="50" [src]="like.user.avatar">
						<div class="media-body">
							<div class="d-flex justify-content-between align-items-center w-100">
								<div><strong class="text-gray-dark mx-3">{{like.user.name}}</strong>
									<p class="mx-3 p-0 mb-0">
										<small *ngIf="like.user.mutual_friends_count">{{like.user.mutual_friends_count}} Mutual friends</small>
									</p>
								</div>
								<ng-container *ngIf="(currentUser.id !== like.user_id)">
									<!-- <a mat-stroked-button class="ml-2" (click)="sendMessage(like.user)"><i class="fab fa-facebook-messenger"></i> Message</a> -->
								</ng-container>
							</div>
						</div>
					</div>
					<ng-container *ngIf="loading">
						<div class="d-flex align-items-center justify-content-center w-100">
							<mat-spinner color="primary" class="spinner-size"></mat-spinner>
						</div>
					</ng-container>
					<div *ngIf="allLikes.length === 0" class="d-flex align-items-center justify-content-center w-100">Not Found</div>
				</div>
			</mat-tab>

			<ng-container *ngFor="let select of selectReactions">
				<ng-container *ngIf="(select.reaction_id === 1) && (data.module !== 'Page')">
					<mat-tab *ngIf="likeList">
						<ng-template mat-tab-label>
							<img [src]="select.image" [alt]="select.reaction_name" [title]="select.reaction_name" width="25"><span class="ml-2">{{select.count}}</span> 
						</ng-template>
						<div class="user-list css-scroll" infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50" [scrollWindow]="false" (scrolled)="onScrollLike($event, 1)">
							<div class="media text-muted py-2 px-3 border-bottom border-gray" *ngFor="let like of likeList">
								<img alt="" class="img-fluid rounded-circle border" height="50" width="50" [src]="like.user.avatar">
								<div class="media-body">
									<div class="d-flex justify-content-between align-items-center w-100">
										<div><strong class="text-gray-dark mx-3">{{like.user.name}}</strong>
											<p class="mx-3 p-0 mb-0">
												<small *ngIf="like.user.mutual_friends_count">{{like.user.mutual_friends_count}} Mutual friends</small>
											</p>
										</div>
										<ng-container *ngIf="(currentUser.id !== like.user_id)">
											<!-- <a mat-stroked-button class="ml-2" (click)="sendMessage(like.user)"><i class="fab fa-facebook-messenger"></i> Message</a> -->
										</ng-container>
									</div>
								</div>
							</div>
							<div *ngIf="likeList.length === 0" class="d-flex align-items-center justify-content-center w-100">Not Found</div>
						</div>
					</mat-tab>
				</ng-container>
			</ng-container>

			<ng-container *ngFor="let select of selectReactions">
				<ng-container *ngIf="(select.reaction_id === 2)">
					<mat-tab *ngIf="loveList">
						<ng-template mat-tab-label>
							<img [src]="select.image" [alt]="select.reaction_name" [title]="select.reaction_name" width="25"><span class="ml-2">{{select.count}}</span>
						</ng-template>
						<div class="user-list css-scroll" infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50" [scrollWindow]="false" (scrolled)="onScrollLove($event, 2)">
							<div class="media text-muted py-2 px-3 border-bottom border-gray" *ngFor="let like of loveList">
								<img alt="" class="img-fluid rounded-circle border" height="50" width="50" [src]="like.user.avatar">
								<div class="media-body">
									<div class="d-flex justify-content-between align-items-center w-100">
										<div><strong class="text-gray-dark mx-3">{{like.user.name}}</strong>
											<p class="mx-3 p-0 mb-0">
												<small *ngIf="like.user.mutual_friends_count">{{like.user.mutual_friends_count}} Mutual friends</small>
											</p>
										</div>
										<ng-container *ngIf="(currentUser.id !== like.user_id)">
											<!-- <a mat-stroked-button class="ml-2" (click)="sendMessage(like.user)"><i class="fab fa-facebook-messenger"></i> Message</a> -->
										</ng-container>
									</div>
								</div>
							</div>
							<div *ngIf="loveList.length === 0" class="d-flex align-items-center justify-content-center w-100">Not Found</div>
						</div>
					</mat-tab>
				</ng-container>
			</ng-container>

			<ng-container *ngFor="let select of selectReactions">
				<ng-container *ngIf="(select.reaction_id === 4)">
					<mat-tab *ngIf="wowList">
						<ng-template mat-tab-label>
							<img [src]="select.image" [alt]="select.reaction_name" [title]="select.reaction_name" width="25"><span class="ml-2">{{select.count}}</span>
						</ng-template>
						<div class="user-list css-scroll" infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50" [scrollWindow]="false" (scrolled)="onScrollWow($event, 4)">
							<div class="media text-muted py-2 px-3 border-bottom border-gray" *ngFor="let like of wowList">
								<img alt="" class="img-fluid rounded-circle border" height="50" width="50" [src]="like.user.avatar">
								<div class="media-body">
									<div class="d-flex justify-content-between align-items-center w-100">
										<div><strong class="text-gray-dark mx-3">{{like.user.name}}</strong>
											<p class="mx-3 p-0 mb-0">
												<small *ngIf="like.user.mutual_friends_count">{{like.user.mutual_friends_count}} Mutual friends</small>
											</p>
										</div>
										<ng-container *ngIf="(currentUser.id !== like.user_id)">
											<!-- <a mat-stroked-button class="ml-2" (click)="sendMessage(like.user)"><i class="fab fa-facebook-messenger"></i> Message</a> -->
										</ng-container>
									</div>
								</div>
							</div>
							<div *ngIf="wowList.length === 0" class="d-flex align-items-center justify-content-center w-100">Not Found</div>
						</div>
					</mat-tab>
				</ng-container>
			</ng-container>

			<ng-container *ngFor="let select of selectReactions">
				<ng-container *ngIf="(select.reaction_id === 5)">
					<mat-tab *ngIf="sadList">
						<ng-template mat-tab-label>
							<img [src]="select.image" [alt]="select.reaction_name" [title]="select.reaction_name" width="25"><span class="ml-2">{{select.count}}</span>
						</ng-template>
						<div class="user-list css-scroll" infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50" [scrollWindow]="false" (scrolled)="onScrollSad($event, 5)">
							<div class="media text-muted py-2 px-3 border-bottom border-gray" *ngFor="let like of sadList">
								<img alt="" class="img-fluid rounded-circle border" height="50" width="50" [src]="like.user.avatar">
								<div class="media-body">
									<div class="d-flex justify-content-between align-items-center w-100">
										<div><strong class="text-gray-dark mx-3">{{like.user.name}}</strong>
											<p class="mx-3 p-0 mb-0">
												<small *ngIf="like.user.mutual_friends_count">{{like.user.mutual_friends_count}} Mutual friends</small>
											</p>
										</div>
										<ng-container *ngIf="(currentUser.id !== like.user_id)">
											<!-- <a mat-stroked-button class="ml-2" (click)="sendMessage(like.user)"><i class="fab fa-facebook-messenger"></i> Message</a> -->
										</ng-container>
									</div>
								</div>
							</div>
							<div *ngIf="sadList.length === 0" class="d-flex align-items-center justify-content-center w-100">Not Found</div>
						</div>
					</mat-tab>

				</ng-container>
			</ng-container>

			<ng-container *ngFor="let select of selectReactions">
				<ng-container *ngIf="(select.reaction_id === 6)">
					<mat-tab *ngIf="angryList">
						<ng-template mat-tab-label>
							<img [src]="select.image" [alt]="select.reaction_name" [title]="select.reaction_name" width="25"><span class="ml-2">{{select.count}}</span>
						</ng-template>
						<div class="user-list css-scroll" infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50" [scrollWindow]="false" (scrolled)="onScrollAngry($event, 6)">
							<div class="media text-muted py-2 px-3 border-bottom border-gray" *ngFor="let like of angryList">
								<img alt="" class="img-fluid rounded-circle border" height="50" width="50" [src]="like.user.avatar">
								<div class="media-body">
									<div class="d-flex justify-content-between align-items-center w-100">
										<div><strong class="text-gray-dark mx-3">{{like.user.name}}</strong>
											<p class="mx-3 p-0 mb-0">
												<small *ngIf="like.user.mutual_friends_count">{{like.user.mutual_friends_count}} Mutual friends</small>
											</p>
										</div>
										<ng-container *ngIf="(currentUser.id !== like.user_id)">
											<!-- <a mat-stroked-button class="ml-2" (click)="sendMessage(like.user)"><i class="fab fa-facebook-messenger"></i> Message</a> -->
										</ng-container>
									</div>
								</div>
							</div>
							<div *ngIf="angryList.length === 0" class="d-flex align-items-center justify-content-center w-100">Not Found</div>
						</div>
					</mat-tab>

				</ng-container>
			</ng-container>

			<ng-container *ngFor="let select of selectReactions">
				<ng-container *ngIf="(select.reaction_id === 15)">
					<mat-tab *ngIf="prayList">
						<ng-template mat-tab-label>
							<img [src]="select.image" [alt]="select.reaction_name" [title]="select.reaction_name" width="25"><span class="ml-2">{{select.count}}</span>
						</ng-template>
						<div class="user-list css-scroll" infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50" [scrollWindow]="false" (scrolled)="onScrollPray($event, 15)">
							<div class="media text-muted py-2 px-3 border-bottom border-gray" *ngFor="let like of prayList">
								<img alt="" class="img-fluid rounded-circle border" height="50" width="50" [src]="like.user.avatar">
								<div class="media-body">
									<div class="d-flex justify-content-between align-items-center w-100">
										<div><strong class="text-gray-dark mx-3">{{like.user.name}}</strong>
											<p class="mx-3 p-0 mb-0">
												<small *ngIf="like.user.mutual_friends_count">{{like.user.mutual_friends_count}} Mutual friends</small>
											</p>
										</div>
										<ng-container *ngIf="(currentUser.id !== like.user_id)">
											<!-- <a mat-stroked-button class="ml-2" (click)="sendMessage(like.user)"><i class="fab fa-facebook-messenger"></i> Message</a> -->
										</ng-container>
									</div>
								</div>
							</div>
							<div *ngIf="prayList.length === 0" class="d-flex align-items-center justify-content-center w-100">Not Found</div>
						</div>
					</mat-tab>
				</ng-container>
			</ng-container>

			<ng-container *ngFor="let select of selectReactions">
				<ng-container *ngIf="(select.reaction_id === 16)">
					<mat-tab *ngIf="laughList">
						<ng-template mat-tab-label>
							<img [src]="select.image" [alt]="select.reaction_name" [title]="select.reaction_name" width="25"><span class="ml-2">{{select.count}}</span>
						</ng-template>
						<div class="user-list css-scroll" infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50" [scrollWindow]="false" (scrolled)="onScrollLaugh($event, 16)">
							<div class="media text-muted py-2 px-3 border-bottom border-gray" *ngFor="let like of laughList">
								<img alt="" class="img-fluid rounded-circle border" height="50" width="50" [src]="like.user.avatar">
								<div class="media-body">
									<div class="d-flex justify-content-between align-items-center w-100">
										<div><strong class="text-gray-dark mx-3">{{like.user.name}}</strong>
											<p class="mx-3 p-0 mb-0">
												<small *ngIf="like.user.mutual_friends_count">{{like.user.mutual_friends_count}} Mutual friends</small>
											</p>
										</div>
										<ng-container *ngIf="(currentUser.id !== like.user_id)">
											<!-- <a mat-stroked-button class="ml-2" (click)="sendMessage(like.user)"><i class="fab fa-facebook-messenger"></i> Message</a> -->
										</ng-container>
									</div>
								</div>
							</div>
							<div *ngIf="laughList.length === 0" class="d-flex align-items-center justify-content-center w-100">Not Found</div>
						</div>
					</mat-tab>
				</ng-container>
			</ng-container>

			<ng-container *ngFor="let select of selectReactions">
				<ng-container *ngIf="(select.reaction_id === 17)">
					<mat-tab *ngIf="flagList">
						<ng-template mat-tab-label>
							<img [src]="select.image" [alt]="select.reaction_name" [title]="select.reaction_name" width="25"><span class="ml-2">{{select.count}}</span>
						</ng-template>
						<div class="user-list css-scroll" infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50" [scrollWindow]="false" (scrolled)="onScrollFlag($event, 17)">
							<div class="media text-muted py-2 px-3 border-bottom border-gray" *ngFor="let like of flagList">
								<img alt="" class="img-fluid rounded-circle border" height="50" width="50" [src]="like.user.avatar">
								<div class="media-body">
									<div class="d-flex justify-content-between align-items-center w-100">
										<div><strong class="text-gray-dark mx-3">{{like.user.name}}</strong>
											<p class="mx-3 p-0 mb-0">
												<small *ngIf="like.user.mutual_friends_count">{{like.user.mutual_friends_count}} Mutual friends</small>
											</p>
										</div>
										<ng-container *ngIf="(currentUser.id !== like.user_id)">
											<!-- <a mat-stroked-button class="ml-2" (click)="sendMessage(like.user)"><i class="fab fa-facebook-messenger"></i> Message</a> -->
										</ng-container>
									</div>
								</div>
							</div>
							<div *ngIf="flagList.length === 0" class="d-flex align-items-center justify-content-center w-100">Not Found</div>
						</div>
					</mat-tab>
				</ng-container>
			</ng-container>


		</mat-tab-group>
	</div>
</mat-dialog-content>

import { Injectable } from '@angular/core';
import { map, catchError } from 'rxjs/operators';
import { GlobalService } from 'src/app/helpers/global.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable, Subject } from 'rxjs';
import { IPost } from 'src/app/models/Post';
import { IUser } from 'src/app/models/User';

@Injectable({
	providedIn: 'root'
})
export class UserService {
	apiRoot: string = environment.apiUrl;
	page: any = 1;
	perPage: any = 20;
	params = null;
	private _view = new Subject<string>();
	slug$ = this._view.asObservable();
	private _is_birthday = new Subject<boolean>();
	isBirthday$ = this._is_birthday.asObservable();
	constructor(
		private http: HttpClient,
		private gs: GlobalService,
	) {

	}

	friendSlug(title: string) {
		this._view.next(title);
	}

	isBirthdat(title: boolean) {
		this._is_birthday.next(title);
	}

	setPage(page, perPage = null) {
		this.page = page;
		if (perPage != null) {
			this.perPage = perPage;
		}
		let params = new HttpParams()
			.set("page", this.page)
			.set("per-page", this.perPage);
		this.params = { params: params };
		return this;
	}

	userTimelineFeed(username, page, per_page): Observable<IPost> {
		const params = new HttpParams()
			.set('username', username)
			.set('page', page)
			.set('per-page', per_page);
		return this.http.get<IPost>(this.apiRoot + '/post/index', { params: params })
			.pipe(
				map((response: any) => { return response }),
				catchError(this.gs.handleHttpError)
			);
	}

	friendList(username, search?: string): Observable<IUser> {
		let q = search ? search : '';
		return this.http.get<IUser>(this.apiRoot + '/friend/index?username=' + username + '&q='+q, this.params)
			.pipe(
				//retry(3),
				map((response: any) => response),
				catchError(this.gs.handleHttpError)
			);
	}
	
	
	familyRequest(params?) {
		return this.http.get(this.apiRoot + '/family-members/pending-requests', { params: params })
			.pipe(
				map((response: any) => response),
				catchError(this.gs.handleHttpError)
			);
	}
	familyRequestAccept(data, params?) {
		return this.http.post(this.apiRoot + '/family-members/is-accept-request', data, { params: params })
			.pipe(
				map((response: any) => response),
				catchError(this.gs.handleHttpError)
			);
	}
	// User Settings Api

}

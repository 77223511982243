import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as defaultReducer from '../reducer/default.reducer';

// Lookup the 'Joke' feature state managed by NgRx
const getState = createFeatureSelector<defaultReducer.State>(
    defaultReducer.featureKey
);

export const trends = createSelector(
    getState,
    state => state.trends
);

export const trendPagination = createSelector(
    getState,
    state => state.trendPagination
);

export const trendloading = createSelector(
    getState,
    state => state.trendLoading
);

export const advertisement = createSelector(
    getState,
    state => state.advertisement
);

export const announcement = createSelector(
    getState,
    state => state.announcement
);

export const reactions = createSelector(
    getState,
    state => state.reactions
);

export const error = createSelector(
    getState,
    state => state.error
);

export const loading = createSelector(
    getState,
    state => state.loading
);
<div class="header">
    <button mat-flat-button type="button" class="dialog-close" [mat-dialog-close]="true" cdkFocusInitial><i class="fas fa-times"></i></button>
</div>
<mat-dialog-content>
    <!-- <div class="uploder">
        <input type="file" (change)="fileChangeEvent($event)" />
    </div> -->
    <div class="buttons">
        <button (click)="zoomOut()" class="btn btn-primary btn-sm">Zoom -</button> 
        <button (click)="zoomIn()" class="btn btn-primary btn-sm">Zoom +</button>
        <button (click)="rotateLeft()" class="btn btn-primary btn-sm">Rotate left</button>
        <button (click)="rotateRight()" class="btn btn-primary btn-sm">Rotate right</button>
        <button (click)="flipHorizontal()" class="btn btn-primary btn-sm">Flip horizontal</button>
        <button (click)="flipVertical()" class="btn btn-primary btn-sm">Flip vertical</button>
        <button (click)="toggleContainWithinAspectRatio()" class="btn btn-primary btn-sm">{{containWithinAspectRatio?'Fill Aspect Ratio':'Contain Within Aspect Ratio'}}</button>
        <button (click)="resetImage()" class="btn btn-primary btn-sm">Reset image</button>
    </div>   

    <image-cropper
        [class]="data?.imageName"
        [imageChangedEvent]="imageChangedEvent"
        [maintainAspectRatio]="true"
        [containWithinAspectRatio]="containWithinAspectRatio"
        [aspectRatio]="16 / 9"
        [resizeToWidth]="data?.width"
        [cropperMinWidth]="data?.width"
        [resizeToHeight]="data?.height"
        [cropperMinHeight]="data?.height"
        [onlyScaleDown]="true"
        [roundCropper]="false"
        [canvasRotation]="canvasRotation"
        [transform]="transform"
        [alignImage]="'center'"
        [style.display]="showCropper ? null : 'none'"
        format="png"
        (imageCropped)="imageCropped($event)"
        (imageLoaded)="imageLoaded()"
        (cropperReady)="cropperReady($event)"
        (loadImageFailed)="loadImageFailed()"
    ></image-cropper>
    <!-- <img [src]="croppedImage" [style.border]="croppedImage ? '1px solid black' : 'none'" /> -->
</mat-dialog-content>
<div mat-dialog-actions>
	<button mat-flat-button color="warn" [mat-dialog-close]="true" cdkFocusInitial>No</button>
	<button mat-flat-button color="primary" (click)="onUpload()">Yes <div *ngIf="loading" class="spinner-border spinner-border-sm text-white"></div></button>
</div>
<div class="blog-list w-100 float-left py-4">
	<div class="container">
		<div class="row">
			<div class="col-md-6 col-12 mx-auto">
				<div class="card page-card">
					<div class="card-header d-flex flex-wrap align-items-center">
						<h3 class="card-title col pl-0">Reset Password</h3>
					</div>
					<div class="card-body">
						<form novalidate [formGroup]="reset" (ngSubmit)="onReset(reset)">
							<div *ngIf="gs.errors.length" class="alert alert-danger" role="alert">
								<h4 class="alert-heading">Error Summary:</h4>
								<p *ngFor="let error of gs.errors;" class="mb-0">{{ error }}</p>
							</div>
							<mat-form-field appearance="outline">
								<mat-label>Password</mat-label>
								<input matInput placeholder="Password" formControlName="password" [type]="hide ? 'text' : 'password'" required>
								<mat-icon matSuffix (click)="hide = !hide">{{hide ? 'visibility' : 'visibility_off'}}</mat-icon>
								<mat-error  *ngIf="f.password.errors && f.password.touched">
									<div *ngIf="f.password.errors.required">Password is required</div>
									<div *ngIf="f.password.errors.minlength">Password must be at least 6 characters</div>
								</mat-error>
							</mat-form-field>
							
							<mat-form-field appearance="outline">
								<mat-label>Confirm Password</mat-label>
								<input matInput placeholder="Confirm Password" type="password" formControlName="password_repeat" required>
								<mat-error  *ngIf="f.password_repeat.errors && f.password_repeat.touched">
								  <div *ngIf="f.password_repeat.errors.required">Confirm Password is required</div>
								  <div *ngIf="f.password_repeat.errors.minlength">Confirm Password must be at least 6 characters</div>
								  <div *ngIf="f.password_repeat.errors.mustMatch">Confirm Password must match</div>
								</mat-error>
							</mat-form-field>
							<div class="form-group">
							  <button type="submit" class="btn btn-lg btn-block btn-primary" [disabled]="!reset.valid" [ladda]="submitted">Submit</button>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NavigationExtras, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { OwlOptions, SlidesOutputData } from 'ngx-owl-carousel-o';
import { Observable } from 'rxjs';
import { GlobalService } from 'src/app/helpers/global.service';
import { User } from 'src/app/models/User';
import { UserProfile } from 'src/app/models/UserProfile';
import { UserService } from 'src/app/service/user.service';
import { AuthActions, UserAction } from 'src/app/store/action';
import { UserSelector } from 'src/app/store/selector';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';

@Component({
	selector: 'app-people-you-may-know',
	templateUrl: './people-you-may-know.component.html',
	styleUrls: ['./people-you-may-know.component.css']
})
export class PeopleYouMayKnowComponent implements OnInit {

	loader: Observable<boolean> = this.store.select(UserSelector.loading);
	friends: Observable<User[]> = this.store.select(UserSelector.suggestions);
	user: User = null;
	page = 1;

	constructor(
		private userService: UserService,
		public gs: GlobalService,
		private router: Router,
		private store: Store<{}>,
		private dialog: MatDialog,
	) { }

	ngOnInit() {
		//this.loadFriend();
		this.user = this.gs.user;

		this.friends.subscribe(data => {
			if(data === null) {
				this.store.dispatch(new UserAction.SuggestionParams({ params: { key: 'suggested-friends', page: this.page } }));
				this.page = this.page + 1;
			}
		});
	}

	getData(event: SlidesOutputData) {
		let slides = [];
		this.friends.subscribe(data => {slides = data});
		let slideCount = slides.length - 5;
		if(event.startPosition === slideCount) {
			//console.log('sdfsdfsd', event.startPosition, slides.length, slideCount);
			this.store.dispatch(new UserAction.SuggestionParams({ params: { key: 'suggested-friends', page: this.page }, key: 'more' }));
			this.page = this.page + 1;
		}
	}

	loadFriend() {
		this.userService.friends("GET", null, { key: 'suggested-friends' }).subscribe((data: any) => {
			this.friends = data ? data : [];
		}, (error: Response) => {
			this.gs.handleErrors(error);
		});
	}

	addFriend(friend, action) {
		this.userService.friends("POST", null, { friend_id: friend.id }).subscribe((data: any) => {
			this.gs.alert('You have successfully add friend list.');
			const item = Object.assign({}, friend, { friendState: 3 });
			this.store.dispatch(new UserAction.SuggestionUpdate(item));
			if (action === 'add') {
				const item = Object.assign({}, friend, { friendState: 3 });
				this.store.dispatch(new UserAction.SuggestionUpdate(item));
			} else {
				const item = Object.assign({}, friend, { friendState: 1 });
				this.store.dispatch(new UserAction.SuggestionUpdate(item));
			}
		}, (error: Response) => {
			this.gs.handleErrors(error);
		});
	}

	declineFriend(friend) {
		this.userService.friends("DELETE", null, { friend_id: friend.id }).subscribe((data: any) => {
			this.gs.alert('Friend request decline successfully.', 'bg-danger');
			let item = Object.assign({}, friend, { friendState: 5 });
			this.store.dispatch(new UserAction.SuggestionUpdate(item));
		}, (error: Response) => {
			this.gs.handleErrors(error);
		});
	}

	followUser(friend, action) {
		const method = (action === 'follow') ? "POST" : "DELETE";
		const bg = (action === 'follow') ? "bg-success" : "bg-danger";
		this.userService.follow(method, null, { following_id: friend.id }).subscribe(data => {
			this.gs.alert(`You have successfully ${action} this user.`, bg);
			let item = Object.assign({}, friend, { is_follow: (action === 'follow') ? true : false });
			this.store.dispatch(new UserAction.SuggestionUpdate(item));
			this.viewProfile();
		}, (error: Response) => {
			this.gs.handleErrors(error);
		});
	}

	mainSearch() {
		let navigationExtras: NavigationExtras = {
			queryParams: {
				"search": ''
			}
		};
		this.router.navigate(['/search'], navigationExtras);
	}

	unFriend(friend) {
		const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
			width: '350px',
			data: "Are you sure to Unfriend your friend"
		});
		dialogRef.afterClosed().subscribe(result => {
			if (result) {
				this.userService.friends("DELETE", null, { friend_id: friend.id }).subscribe((data: any) => {
					this.gs.alert('Remove from friend list.', 'bg-danger');
					const item = Object.assign({}, friend, { friendState: 0 });
					this.store.dispatch(new UserAction.SuggestionUpdate(item));
				}, (error: Response) => {
					this.gs.handleErrors(error);
				});
			}
		});
	}

	viewProfile() {
		this.userService.profile().subscribe((response: any) => {
			this.store.dispatch(new AuthActions.loginSuccess({ user: response }));
		});
	}


	advertisementtOptions: OwlOptions = {
		loop: false,
		touchDrag: false,
		pullDrag: false,
		dots: false,
		items: 4,
		nav: true,
		margin: 20,
		navSpeed: 700,
		autoplay: false,
		autoplayTimeout: 6000,
		//animateOut: 'fade',
		//animateIn: 'fade',
		navText: ['<i class="fas fa-chevron-left"></i>', '<i class="fas fa-chevron-right"></i>'],
	}

}

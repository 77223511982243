import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as userReducer from '../reducer/user.reducer';

const getState = createFeatureSelector<userReducer.State>(
    userReducer.featureKey
);

export const suggestions = createSelector(
    getState,
    state => state.suggestions
);

export const error = createSelector(
    getState,
    state => state.error
);

export const loading = createSelector(
    getState,
    state => state.loading
);
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { JwtModule } from '@auth0/angular-jwt';
import { environment } from 'src/environments/environment';
import { LaddaModule } from 'angular2-ladda';
import { LoginComponent } from './components/login/login.component';
import { RegisterComponent } from './components/register/register.component';
import { AuthService } from './services/auth.service';
import { AuthGuard } from './guards/auth.guard';
import { MatNativeDateModule } from '@angular/material/core';
import { MaterialModule } from '../material-module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { EmailVerifyComponent } from './components/email-verify/email-verify.component';
import { ResendEmailComponent } from './components/resend-email/resend-email.component';
import { WidgetsModule } from '../widgets/widgets.module';
import { OtpVerificationComponent } from './components/otp-verification/otp-verification.component';

export function tokenGetter() {
	return localStorage.getItem('token');
}

@NgModule({
	declarations: [
		LoginComponent,
		RegisterComponent,
		ForgotPasswordComponent,
		ResetPasswordComponent,
		EmailVerifyComponent,
		ResendEmailComponent,
		OtpVerificationComponent
	],
	imports: [
		CommonModule,
		LaddaModule,
		RouterModule,
		MatNativeDateModule,
		MaterialModule,
		NgbModule,
		FormsModule,
		WidgetsModule,
		ReactiveFormsModule,
		JwtModule.forRoot({
			config: {
				tokenGetter: tokenGetter,
				allowedDomains: environment.whitelistedDomains
			}
		}),
	],
	entryComponents: [ResendEmailComponent],
	exports: [
		LoginComponent, RegisterComponent
	],
	providers: [
		AuthService,
		AuthGuard
	]
})
export class AuthModule { }

import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
	selector: 'app-post-embed',
	templateUrl: './post-embed.component.html',
	styleUrls: ['./post-embed.component.scss']
})
export class PostEmbedComponent implements OnInit {
	viewLink = null;
	constructor(
		private dialogRef: MatDialogRef<PostEmbedComponent>,
		@Inject(MAT_DIALOG_DATA) public data
	) { }

	ngOnInit() {
		//console.log(this.data);
		this.viewLink = `<iframe width="560" height="315" src="${this.data}" frameborder="0" allowfullscreen></iframe>`;
	}

	closeDialog() {
		this.dialogRef.close()
	}

}

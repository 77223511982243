export const environment = {
	production: false,
	apiUrl: 'https://api.chatdit.com',
    restStreamApi: 'https://media2.chatdit.com:5443',
    webStream: 'https://media2.chatdit.com:5443/WebRTCApp/play.html',
    appStream: 'https://media2.chatdit.com:5443/LiveApp/play.html',
	tokenName: 'token',
	hostName: 'https://chatdit.com',
	uploadPath: 'https://uploads.chatdit.com/storage/',
	whitelistedDomains: [
		'chatdit.com'
    ],
    wsEndpoint: 'wss://media2.chatdit.com:5443/WebRTCApp/websocket',
    reconnectInterval: 2000,
	recaptchaSiteKey: '6Lfz3ZsUAAAAACGAl3_nXH9dbsUAbfrF7rCGfIQD',
    firebaseConfig: {
        apiKey: "AIzaSyDs_rZATQv4m7MiKJyWH-efK-xOZY6oQKg",
        authDomain: "chatdit-1560624699443.firebaseapp.com",
        databaseURL: "https://chatdit-1560624699443.firebaseio.com",
        projectId: "chatdit-1560624699443",
        storageBucket: "chatdit-1560624699443.appspot.com",
        messagingSenderId: "44260567601",
        appId: "1:44260567601:web:aa8d50bbf4df7835ed4310"
    },
    STRIPE_PUBLIC_KEY: 'pk_test_zEJVkunjpY6E7XcOC7Fak7FE',
    STRIPE_PRIVATE_KEY: 'sk_test_a4LEzzRrbMe0VDn6OQdsQKfX',
    STRIPE_CLIENT_ID: 'ca_DGW4gUxXneNyvyJiLVixm5wVZSPXfMP8',
	STRIPE_REDIRECT_URI: 'https://api.chatdit.com/stripe-connect',
    SOCKET_URL: 'https://chat.chatdit.com/',
};

//SOCKET_URL: 'http://134.122.30.29:8888/',

/*export const environment = {
    production: false,
    apiUrl: 'https://api.chatdit.com',
    restStreamApi: 'https://media2.chatdit.com:5443',
    webStream: 'https://media2.chatdit.com:5443/WebRTCApp/play.html',
    appStream: 'https://media2.chatdit.com:5443/LiveApp/play.html',
	tokenName: 'token',
	hostName: 'https://chatdit.com',
	uploadPath: 'https://uploads.chatdit.com/storage/',
	whitelistedDomains: [
		'chatdit.com'
    ],
    wsEndpoint: 'wss://media2.chatdit.com:5443/WebRTCApp/websocket',
    reconnectInterval: 2000,
	recaptchaSiteKey: '6Lfz3ZsUAAAAACGAl3_nXH9dbsUAbfrF7rCGfIQD',
    firebaseConfig: {
        apiKey: "AIzaSyDs_rZATQv4m7MiKJyWH-efK-xOZY6oQKg",
        authDomain: "chatdit-1560624699443.firebaseapp.com",
        databaseURL: "https://chatdit-1560624699443.firebaseio.com",
        projectId: "chatdit-1560624699443",
        storageBucket: "chatdit-1560624699443.appspot.com",
        messagingSenderId: "44260567601",
        appId: "1:44260567601:web:aa8d50bbf4df7835ed4310"
    },
    STRIPE_PUBLIC_KEY: 'pk_test_zEJVkunjpY6E7XcOC7Fak7FE',
    STRIPE_PRIVATE_KEY: 'sk_test_a4LEzzRrbMe0VDn6OQdsQKfX',
    STRIPE_CLIENT_ID: 'ca_DGW4gUxXneNyvyJiLVixm5wVZSPXfMP8',
	STRIPE_REDIRECT_URI: 'https://api.chatdit.com/stripe-connect',
    SOCKET_URL: 'http://134.122.30.29:8888/',
};
*/
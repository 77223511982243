<div class="not-found my-5 float-left w-100">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="404-page text-center">
                    <h1 class="header">Coming Soon</h1>
                </div>
            </div>
        </div>
    </div>
</div>

<footer id="footer">
	<div class="container">
		<div class="row">
			<div class="col-12 d-flex flex-wrap align-items-center">
				<ul class="footer-menu d-flex flex-wrap">
					<li><a routerLink="/contact">Contact</a></li>
					<li><a routerLink="/faqs">Faq</a></li>
					<ng-container *ngIf="pages">
						<li *ngFor="let page of pages"><a routerLink="/content/{{page.slug}}">{{page.title}}</a></li>
					</ng-container>					
				</ul>
				<!-- <div class="col app">
					<a routerLink="/"><img src="assets/img/apple-app.png" alt=""></a>
					<a routerLink="/"><img src="assets/img/android-app.png" alt=""></a>
				</div> -->
				<div class="copyright">
					Copyright &copy; 2019  <a routerLink="/">{{gs.mainTitle}}</a>
				</div>
			</div>
		</div>
	</div>
</footer>
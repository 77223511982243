<!-- <mat-dialog-content>
	<div class="header">
		<button mat-button type="button" class="close" (click)="closeDialog()"><i class="fas fa-times"></i></button>
    </div>
    <div class="w-100 p-3">
        <div>
            <video id="device" controls autoplay #remote>
            </video>
            <video id="me" controls autoplay #me></video>
        </div>
        <button mat-raised-button color="primary" (click)="showRemote()" [disabled]="callActive">Connect</button>
        <button mat-raised-button color="warn" (click)="hangup()" [disabled]="!callActive">Disconnect</button>
    </div>
</mat-dialog-content> -->

<mat-spinner></mat-spinner>
import { Injectable } from '@angular/core';
import { HttpClient, HttpProgressEvent, HttpErrorResponse } from '@angular/common/http';
import { throwError, Subject } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { User } from '../models/User';
import { environment } from 'src/environments/environment';
import { GlobalService } from '../helpers/global.service';
import { Post } from '../models/Post';

@Injectable({
	providedIn: 'root'
})
export class CommonService {

	apiRoot: string = environment.apiUrl;

	private _userData = new Subject<string>();
	userData$ = this._userData.asObservable();

    private _pinPostAdd = new Subject<string>();
    pinPostAdd$ = this._pinPostAdd.asObservable();

	private _userPost = new Subject<Post>();
	userPost$ = this._userPost.asObservable();

	constructor(
		private http: HttpClient,
		private gs: GlobalService,
	) {

	}

	public handleHttpError(error: HttpErrorResponse) {
		return throwError(error || 'Server Error');
	}

	setAction(title: string) {
		this._userData.next(title);
	}

	postAction(data: Post) {
		this._userPost.next(data);
	}

    pinPostAdd(title: string) {
        this._pinPostAdd.next(title);
    }

	country() {
		return this.http.get('/assets/json/country.json')
			.pipe(map((response: Response) => {
				return response
			}));
	}

	timeZone() {
		return this.http.get('/assets/json/timezone.json')
			.pipe(map((response: Response) => {
				return response
			}));
	}

	forgotPassword(data: User) {
		return this.http.post(this.apiRoot + '/authentication/forgot-password', data)
			.pipe(map((response: Response) => response));
	}

	otpVerification(otp) {
		return this.http.post(this.apiRoot + '/authentication/otp-verification?otp=' + otp, [])
			.pipe(map((response: Response) => response));
	}

	resetPassword(data, token) {
		return this.http.post(this.apiRoot + '/authentication/reset-password?token=' + token, data)
			.pipe(map((response: Response) => response));
	}

	emailVerification(token) {
		return this.http.post(this.apiRoot + '/authentication/email-verification?token=' + token, [])
			.pipe(map((response: Response) => response));
	}

	resendEmial(user_id) {
		return this.http.post(this.apiRoot + '/authentication/resend-verification?user_id=' + user_id, [])
			.pipe(map((response: Response) => response));
	}

	logout() {
		return this.http.post(this.apiRoot + '/authentication/resend-verification', [])
			.pipe(map((response: Response) => response));
	}

	contactForm(data) {
		return this.http.post(this.apiRoot + '/default/contact', data)
			.pipe(map((response: any) => response.data));
	}

	faq(title?: string) {
		let name = ((title == '') || (title == null) || (title == undefined)) ? '' : title;
		return this.http.get(this.apiRoot + '/default/faq?title=' + name)
			.pipe(map((response: any) => response.data));
	}

	askFaq(data) {
		return this.http.post(this.apiRoot + '/default/faq', data)
			.pipe(map((response: any) => response.data));
	}

	platforms() {
		return this.http.get(this.apiRoot + '/default/platforms')
			.pipe(map((response: Response) => response));
	}

	relation() {
		return this.http.get(this.apiRoot + '/default/family-members')
			.pipe(map((response: Response) => response));
	}

	getRelationStatus() {
		return this.http.get(this.apiRoot + '/default/relationship-status')
			.pipe(map((response: Response) => response));
	}

	items(param) {
		return this.http.get(this.apiRoot + '/default/item', {params: param} )
			.pipe(
				map((response: any) => {
					return response.data;
				}),
				catchError(this.gs.handleHttpError)
			);
	} // CMS CORE items

    statistics(params) {
        return this.http.get(this.apiRoot+'/default/analytics-item', {params: params})
            .pipe(
                map((response: any) => response.data),
                catchError(this.gs.handleHttpError)
            );
	}
	
	config(method = "GET", params = null, params2 = null) {
		return this.http.request(method, `${this.apiRoot}/default/config`, { body: params, params: params2 })
			.pipe(map((response: any) => {
				return response;
			}), catchError(this.gs.handleHttpError));
	}

	// Image upload service
	cropperUpload(files: File) {
		var formData = new FormData();
		formData.append('file[]', files);
		return this.http.post(this.apiRoot + '/default/upload', formData).pipe(
			map((response: any) => {
				//console.log();
				return response.data;
			}),
			catchError(this.handleHttpError)
		);
	}

	// Image upload service
	basicUpload(files: FileList) {
		var formData = new FormData();
		Array.from(files).forEach(f => formData.append('file[]', f))
		console.log('Upppp', files);
		//return false;
		return this.http.post(this.apiRoot + '/default/upload', formData).pipe(
			map((response: any) => {
				//console.log();
				return response.data;
			}),
			catchError(this.handleHttpError)
		);
	}

	uploadAndProgress(files: FileList) {
		var formData = new FormData();
		Array.from(files).forEach(f => formData.append('file[]', f))
		return this.http.post(this.apiRoot + '/default/upload', formData, {
			reportProgress: true,
			observe: 'events'
		}).pipe(
			map((response: any) => {
				//console.log();
				return response;
			}),
			catchError(this.handleHttpError)
		)
	}

	calcProgressPercent(event: HttpProgressEvent) {
		return Math.round(100 * event.loaded / event.total);
	}

}

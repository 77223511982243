<app-header [ngClass]="(isLoggedIn$ | async) ? 'loggedIn' : ''" ></app-header>
<div class="main-wrap" [ngClass]="(isLoggedIn$ | async) ? 'login' : 'mt-0'">
    <app-landing-navbar></app-landing-navbar>
    <!-- <button (click)="send()">Send</button> -->
    <div class="w-100 float-left">
        <router-outlet></router-outlet>
    </div>
</div>
<app-footer></app-footer>

<!-- <a class="dollar-icon" target="_blank" href="https://www.paypal.me/ChatDit">
    <div class="icon"><i class="fa fa-dollar-sign"></i></div>
    <div class="text">Contribute to ChatDit</div>
    <iframe width="560" height="315" src="//157.230.30.111:5080/WebRTCApp/play.html?name=955067083916361951557753" frameborder="0" allowfullscreen></iframe>
</a> -->


<ng-container *ngIf="(isLoggedIn$ | async)">
    <div class="verticle-text" id="tech_support">
        <h3><a routerLink="/timeline/support">TECH SUPPORT</a></h3>
    </div>
</ng-container>

<div *ngIf="(isLoggedIn$ | async)" class="push-alert">
    <!-- *ngIf="(message | async)" -->
    <div class="toast" style="min-width: 280px;" *ngIf="(message | async)">
        <div class="toast-header">
            <img src="{{ message && message._value && message._value.data.icon }}" class="rounded mr-2" alt="Alt Text" width="25">
            <strong class="mr-auto">{{ message && message._value && message._value.data.title }}</strong>
            <!-- <small class="text-muted">just now</small> -->
            <button type="button" class="ml-2 mb-1 close" (click)="removePushNotification()">
                <span>&times;</span>
            </button>
        </div>
        <div class="toast-body">
            <!-- {{ message | async | json }} -->
            {{ message && message._value && message._value.data.textWithoutIcon }}
        </div>
    </div>
</div>



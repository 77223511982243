import { Action } from '@ngrx/store';
import { Post } from 'src/app/models/Post';

export enum CommunityTimelineTypes {
    FAILURE = '[COMMUNITY_TIMELINE_FAILURE] failed',
    LIST_PARAMS = '[COMMUNITY_TIMELINE_LIST_PARAMS] COMMUNITY List Params',
    LIST = '[COMMUNITY_TIMELINE_LIST] COMMUNITY List',
    LIST_MORE = '[COMMUNITY_TIMELINE_LIST_MORE] COMMUNITY List More',
    ADD = '[COMMUNITY_TIMELINE_ADD] COMMUNITY Add',
    UPDATE = '[COMMUNITY_TIMELINE_UPDATE] COMMUNITY Update',
    DELETE = '[COMMUNITY_TIMELINE_UPDATE] COMMUNITY Detele',
}

export class ListParams implements Action {
    readonly type = CommunityTimelineTypes.LIST_PARAMS;
    constructor(public options: { params?: any, key?: string}) { }
} 

export class List implements Action {
    readonly type = CommunityTimelineTypes.LIST;
    constructor(public list: any) { }
}

export class More implements Action {
    readonly type = CommunityTimelineTypes.LIST_MORE;
    constructor(public more: any) { }
}

export class Add implements Action {
    readonly type = CommunityTimelineTypes.ADD;
    constructor(public item: Post) { }
}

export class Update implements Action {
    readonly type = CommunityTimelineTypes.UPDATE;
    constructor(public item: Post) { }
}

export class Delete implements Action {
    readonly type = CommunityTimelineTypes.DELETE;
    constructor(public item: Post) { }
}

export class failure implements Action {
    readonly type = CommunityTimelineTypes.FAILURE;
    constructor(public error: any) { }
}


export type CommunityTimelineAction = ListParams | List | More | Add | Update | Delete | failure;
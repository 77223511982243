import { Component, Input, ElementRef, OnChanges, HostListener, OnInit, DoCheck } from '@angular/core';
import { Router, NavigationExtras } from '@angular/router';


@Component({
	selector: 'read-more',
	template: `
  <div [innerHTML]="currentText | parseUrl">
  </div>
      <a class="read-more" [class.hidden]="hideToggle" (click)="toggleView()">Read {{isCollapsed? 'more':'less'}}</a>
  `,
	styles: [`
    a.read-more {cursor: pointer;}
    a.hidden {display: none;}
  `]
})
export class ReadMoreComponent implements OnChanges, OnInit {


	@Input() public text;
	@Input() public maxLength: number = 100;
	@Input() public linkView: any = null;
	currentText: any;
	hideToggle: boolean = true;

	urls: any = /(\b(https?|http|Https):\/\/[A-Z0-9+&@#\/%?=~_|!:,.;-]*[-A-Z0-9+&@#\/%=~_|])/gim; // Find/Replace URL's in text

	public isCollapsed: boolean = true;
	public priviewData:any = [];

	@HostListener('click', ['$event']) onClick(e) {

		if (e.target.classList.contains('handle-link')) {
			let link: string = e.target.innerHTML;
			event.preventDefault();
			event.stopPropagation();
			alert("/handle/" + link.trim());

		} else if (e.target.classList.contains('hashtag-link')) {
			let link: string = e.target.innerHTML;
			event.preventDefault();
			event.stopPropagation();
			let srt = link.trim().substring(1);
			let navigationExtras: NavigationExtras = {
				queryParams: {"search": srt}
			};
			this.router.navigate(['/timeline/trends'], navigationExtras);
		} else if (e.target.classList.contains('targer-link')) {
			let link: String = e.target.innerHTML;
			//console.log(link);
			event.preventDefault();
			event.stopPropagation();
			alert("" + link.trim());
		} else if (e.target.classList.contains('url-link')) {
			let link: String = e.target.innerHTML;
			//console.log(link);
			//event.preventDefault();
			//event.stopPropagation();
			//alert("" + link.trim());
		}

	}

	constructor(private elementRef: ElementRef, private router: Router) {
	}

	toggleView() {
		this.isCollapsed = !this.isCollapsed;
		this.determineView();
	}
	determineView() {

		if (!this.text || this.text.length <= this.maxLength) {
			this.currentText = this.text;
			this.isCollapsed = false;
			this.hideToggle = true;
			return;
		}
		this.hideToggle = false;
		if (this.isCollapsed == true) {
			this.currentText = this.text.substring(0, this.maxLength) + "...";
		} else if (this.isCollapsed == false) {
			this.currentText = this.text;
		}
	}

 

	priview() {
		var maintxt = this.currentText;

		if (this.currentText.match(this.urls)) {

			let linkData = this.linkView;
			linkData.forEach(el => {				 
					if(el !== undefined){
						let previewData = `<div class="link-priview p-2 border">
							<div class="media">
							<img src="${el.imageUrl}" class="mr-3" /> 
								<div clas="media-body">
									<h5 class="mt-0">
										<a href="" class=targer-link" target="_blank">${el.title}</a>
									</h5>
									<p class="mb-0">${el.description}</p>
								</div>
							</div> 
						</div>`;
						this.priviewData.push({url: el.url, preview: previewData}) ;					
					}				 
			});

			/*
				console.log(this.priviewData);
				var replaceTxt = this.currentText;
				
				this.priviewData.forEach(el => {
					this.currentText = replaceTxt.replace( el.url, el.preview);
				}); 
				console.log(this.currentText);
			*/

			//console.log(replaceTxt);
			/*var replaceTxt = this.currentText.replace(this.urls, function replacer($1, $2, $3) {
				let url: any = $1;
				let stackPriview;
				linkData.forEach(el => {
					let orgUrl = el.url;
					if (orgUrl.includes(url)) {
						stackPriview = el;
					}
				}); 
			});*/
			return this.priviewData;
		}
	}

	ngOnChanges() {
		this.determineView();
		//this.priview();
	}

	ngOnInit() {
		//console.log(this.currentText);
		//this.priview();

	}


}


import { Component, OnInit, ViewChild } from '@angular/core';
import { GlobalService } from 'src/app/helpers/global.service';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { AnnouncementVideoComponent } from '../announcement-video/announcement-video.component';
import { DefaultService } from 'src/app/service/default.service';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { Item } from 'src/app/models/Item';
import { DefaultSelector } from 'src/app/store/selector';
import { DefaultAction } from 'src/app/store/action';

@Component({
	selector: 'app-announcement',
	templateUrl: './announcement.component.html',
	styleUrls: ['./announcement.component.scss']
})
export class AnnouncementComponent implements OnInit {	
	announcement: Observable<Item[]> = this.store.select(DefaultSelector.announcement);
	postDialogRef: MatDialogRef<AnnouncementVideoComponent>;
	announcementHide = true;

	constructor(
		public gs: GlobalService,
		public defaultService: DefaultService,
		private dialog: MatDialog,
		private store: Store<{}>,
	) { }

	ngOnInit() {
		const params = {
			'moduleId': 'announcement',
			//'per-page': 20,
			'announcement_status': 4
		}
		this.announcement.subscribe(data => {
			(data === null) && this.store.dispatch(new DefaultAction.AnnouncementParams({params: params}));
		});
		/*this.defaultService.item("GET", null, params).subscribe(data => {
			this.announcement = data.items;
			this.requestLoading = true;
		});*/
	}

	announcementOptions: OwlOptions = {
		loop: true,
		touchDrag: false,
		pullDrag: false,
		dots: false,
		items: 1,
		nav: true,
		margin: 20,
		navSpeed: 700,
		autoplay: true,
		autoplayTimeout: 4000,
		navText: ['<i class="fas fa-chevron-left"></i>', '<i class="fas fa-chevron-right"></i>'],
	}

	hideButton() {
		this.announcementHide = false;
	}

	openVideo(item, type) {
		this.postDialogRef = this.dialog.open(AnnouncementVideoComponent, {
            width: '340px',
			disableClose: true,
			backdropClass: 'timeline-backdrop',
            panelClass: 'announce-dialog',
            data: {
				item: item,
				type: type
			}
        });

	}

}
